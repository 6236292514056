import ReactSlider from "react-slider";
import "./Slider.css";

const Slider = (props) => {
  // const [currentValue, setCurrentValue] = useState(0);

  return (
    <ReactSlider
      className="customSlider"
      thumbClassName="customSlider-thumb"
      trackClassName="customSlider-track"
      step={props.step}
      min={props.min}
      max={props.max}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

export default Slider;

import { useState, useEffect } from "react";
import Select, { components } from "react-select";
import styles from "./MultiSelect.module.css";
import * as FiIcons from "react-icons/fi";

const MultiSelect = (props) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const handleThemeChange = (event) => {
      setIsDarkMode(event.matches);
    };

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(mediaQuery.matches);
    mediaQuery.addListener(handleThemeChange);

    return () => {
      mediaQuery.removeListener(handleThemeChange);
    };
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isDarkMode ? "#333" : "inherit",
      marginTop: "10px",
      minHeight: "35px",
      borderRadius: "5px",
      border: state.isFocused
        ? "1px solid #007aff4d"
        : isDarkMode
        ? "1px solid #555"
        : "1px solid #e0e0e0",
      boxShadow: state.isFocused ? "0 0 5px 1px #007aff4d" : "initial",
      "&:hover": {
        border: "1px solid initial",
        cursor: "pointer",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? "#555" : "lightgray",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: isDarkMode ? "#555" : "inherit",
      padding: "0px 5px",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: "3px",
      marginRight: "4px",
      color: isDarkMode ? "#555" : "inherit",
      "&:hover": {
        backgroundColor: "#FFBDAD",
        borderRadius: "5px",
        color: "#DE350B",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: "13px",
      paddingTop: "2px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? "#333" : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#e0e0e0" : "inherit",
      color: state.isFocused ? "#333" : "inherit",
      "&:hover": {
        backgroundColor: "#e0e0e0",
        color: "#333",
        cursor: "pointer",
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      fontSize: "20px",
      borderRadius: "5px",
      margin: "1px",
      marginRight: "5px",
      backgroundColor: isDarkMode ? "#555" : "#e0e0e0",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: isDarkMode ? "#aeaeae" : "inherit",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      "&:hover": {
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "5px",
      },
    }),
  };

  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <FiIcons.FiX size={18} />
      </components.MultiValueRemove>
    );
  };

  const [selectedOptions, setSelectedOptions] = useState();

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    props.onMultiChange(selectedOptions, props.name);
  };

  return (
    <div className={styles.multiContainer}>
      <label
        className={`${styles.multiLabel} ${
          props.isDisabled ? styles.disabled : ""
        }`}
      >
        <div className={styles.label}>
          <div>{props.label}</div>
          <div className={styles.note}>{props.note}</div>
        </div>
        <Select
          id={props.id}
          styles={customStyles}
          isMulti
          minMenuHeight={200}
          maxMenuHeight={160}
          menuPlacement={props.menuPlacement}
          name={props.name}
          options={props.options}
          onChange={handleChange}
          defaultValue={props.defaultValue}
          value={selectedOptions}
          components={{ MultiValueRemove }}
          isDisabled={props.isDisabled}
        />
      </label>
    </div>
  );
};
export default MultiSelect;

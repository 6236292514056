import { useContext, useEffect, useState } from "react";
import styles from "./BigFileUploadProgress.module.css";
import DnDContext from "../../context/dnd-context";

export default function LinearProgressBar({
  fileName,
  fileSize,
  chunksUploaded,
  abort,
  hasError,
}) {
  const [percent, setPercent] = useState(0);
  const dndCtx = useContext(DnDContext);

  useEffect(() => {
    const newPercent = Math.round(
      (chunksUploaded / Math.ceil(fileSize / 1024 / 1024 / 32)) * 100
    );
    setPercent(newPercent);
  }, [chunksUploaded, fileSize]);

  useEffect(() => {
    setPercent(0);
  }, [fileName]);

  const abortHandler = () => {
    const abortController = dndCtx.abortControllers.get(fileName);
    if (abortController) {
      abortController.abort();
      dndCtx.abortBigFile(fileName);
    }
    // dndCtx.removeBigFile(fileName);
  };

  let classNames = `${styles["mainProgressBarDiv"]}`;

  if (abort) {
    classNames = `${styles["mainProgressBarDiv"]} ${styles["aborted"]}`;
  } else if (hasError) {
    classNames = `${styles["mainProgressBarDiv"]} ${styles["alerted"]}`;
  }

  let fillingBarClassNames = `${styles["fillingProgressBar"]}`;

  if (abort) {
    fillingBarClassNames = `${styles["fillingProgressBarAborted"]}`;
  } else if (hasError) {
    fillingBarClassNames = `${styles["fillingProgressBarAlert"]}`;
  }

  let messageClassNames = ``;

  if (abort) {
    messageClassNames = `${styles["warning"]}`;
  } else if (hasError) {
    messageClassNames = `${styles["alert"]}`;
  }

  let message = `File size: ${Math.round(fileSize / 1024 / 1024)} MB`;
  if (abort) {
    message = `File upload canceled!`;
  } else if (hasError) {
    message = `File upload failed!`;
  }

  return (
    <div className={classNames}>
      <div className={styles["header"]}>
        <span>Big file upload in progress:</span>
        <button
          className={styles["abort-button"]}
          onClick={abortHandler}
          disabled={abort || hasError}
        >
          Cancel
        </button>
      </div>

      <div className={styles["file-details"]}>
        <span>File name: {fileName}</span>
        <span className={messageClassNames}>{message}</span>
      </div>
      <div className={styles["emptyProgressBar"]} style={{ width: "100%" }}>
        <div
          className={fillingBarClassNames}
          style={{
            left: percent - 100 + "%",
            transition: "1s",
          }}
        />
      </div>
    </div>
  );
}

import { useEffect, useContext, useState } from "react";
import styles from "./SendPushNotification.module.css";
import { Form, useLoaderData } from "react-router-dom";
import Button from "../../UI/Button/Button";
import FormDataContext from "../../context/formData-context";
import { getAuthToken } from "../../util/auth";
import MultiSelect from "../../UI/Input/MultiSelect";
import TextArea from "../../UI/Input/TextArea";
import CheckBox from "../../UI/Input/CheckBox";
import Input from "../../UI/Input/Input";
import Modal from "../../UI/Modal/Modal";
import * as Hi2Icons from "react-icons/hi2";
import ModalContext from "../../context/modal-context";

function SendPushNotification(props) {
  const modalCtx = useContext(ModalContext);
  const data = useLoaderData();
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  const dataCtx = useContext(FormDataContext);

  const [isChecked, setIsChecked] = useState(false);
  const [selectedPartnerIds, setSelectedPartnerIds] = useState([]);

  useEffect(() => {
    dataCtx.editFromData({
      title: "",
      message: "",
      recipients: [],
    });

    const requestData = {
      url: process.env.REACT_APP_API_URL + "ava/push",
      method: "POST",
      headers: headers,
    };

    dataCtx.editRequestData(requestData);
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: value,
    });
  };

  const handleMultiSelectChange = (selectedOptions, fieldName) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedPartnerIds(selectedValues);
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      [fieldName]: selectedValues,
    }));
  };

  const handleAllChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      const updatedFormData = {
        ...dataCtx.formData,
        recipients: event.target.checked ? [] : dataCtx.formData.recipients,
      };
      dataCtx.editFromData(updatedFormData);
    } else {
      const updatedFormData = {
        ...dataCtx.formData,
        recipients: selectedPartnerIds,
      };
      dataCtx.editFromData(updatedFormData);
    }
  };

  const submitHandler = async () => {
    await dataCtx.fetchData();
    props.refresh();
    modalCtx.showModal();
  };

  return (
    <Modal>
      <div className={styles.container}>
        <div className={styles.title}>
          <h2>Send push notification</h2>
          <Hi2Icons.HiOutlineXMark
            className={styles["close-button"]}
            onClick={() => {
              modalCtx.showModal();
              dataCtx.editFromData({});
            }}
          />
        </div>
        <Form>
          <div className={styles.content}>
            <Input
              label="Title"
              id="title"
              name="title"
              note="(Required)"
              value={dataCtx.formData.title || ""}
              onChange={handleInputChange}
            />
            <TextArea
              label="Message"
              id="message"
              name="message"
              rows={10}
              note="(Required)"
              value={dataCtx.formData.message || ""}
              onChange={handleInputChange}
            />
            <CheckBox
              id="toAll"
              name="toAll"
              label="Send message to everyone"
              description="If you check, the message will be sent to everyone."
              onChange={handleAllChange}
              checked={isChecked}
            />
            <MultiSelect
              id="recipients"
              name="recipients"
              label="To"
              menuPlacement="top"
              options={data}
              value={dataCtx.formData.recipients}
              onMultiChange={handleMultiSelectChange}
              isDisabled={isChecked}
            />
            <div className={styles["submit-button"]}>
              <Button
                className={styles.submit}
                type="submit"
                onClick={submitHandler}
                disabled={!isChecked && selectedPartnerIds.length === 0}
              >
                Send
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default SendPushNotification;

import { getAuthToken } from "./auth";
import { fetchRequest } from "./fetchRequest";

export const formatRowData = (rawData) =>
  rawData.map((info) => ({
    id: info.id,
    name: info.name,
    slogan: info.slogan,
    email: info.email,
    code: info.code,
    kind: info.kind,
    region: info.region,
    country: info.country,
    city: info.city,
    address: info.address,
  }));

export const getSimpleData = async (URLsuffix) => {
  const token = getAuthToken();
  const URL = process.env.REACT_APP_API_URL + URLsuffix;
  const method = "GET";
  const headers = { Authorization: `Bearer ${token}` };
  try {
    const data = await fetchRequest(URL, method, headers, undefined);
    return data;
  } catch (error) {
    throw error;
  }
};

import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import { useContext } from "react";
import ModalContext from "../../context/modal-context";
import styles from "./Confirm.module.css";
import * as Hi2Icons from "react-icons/hi2";

function Confirm(props) {
  const modalCtx = useContext(ModalContext);

  const handleDelete = () => {
    props.onDelete();
    modalCtx.showModal();
  };

  return (
    <Modal>
      <div className={styles["confirm-container"]}>
        <div className={styles["confirm-message"]}>
          <div className={styles["icon-container"]}>
            <Hi2Icons.HiTrash className={styles["delete-icon"]} />
          </div>
          <div className={styles["confirm-text"]}>
            <h3>Please confirm!</h3>
            <p>Are you sure you want to delete the selected element(s)?</p>
          </div>
        </div>
        <footer className={styles.footer}>
          <Button
            className={styles["cancel-button"]}
            onClick={() => modalCtx.showModal()}
          >
            Cancel
          </Button>
          <Button className={styles["delete-button"]} onClick={handleDelete}>
            Delete
          </Button>
        </footer>
      </div>
    </Modal>
  );
}

export default Confirm;

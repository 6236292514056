import React, { useContext, useEffect, useState } from "react";
import { Link, useLoaderData, useNavigate, useParams } from "react-router-dom";
import styles from "./PartnerDetailPage.module.css";
import Card from "../../../UI/Card/Card";
import { getAuthToken } from "../../../util/auth";
import { fetchRequest } from "../../../util/fetchRequest";
import Header from "../../../UI/Table/Header";
import Table from "../../../UI/Table/Table";
import { useSimpleDataFetching } from "../../../../hooks/use-simpledatafetching";
import ErrorPage from "../../../ErrorPage";
import Button from "../../../UI/Button/Button";
import KebabMenu from "../../../UI/KebabMenu/KebabMenu";
import * as Hi2Icons from "react-icons/hi2";
import ModalContext from "../../../context/modal-context";
import LocationDetailsPage from "../Locations/LocationDetailsPage";
import LocationEditPage from "../Locations/LocationEditPage";
import Confirm from "../../../UI/Confirm/Confirm";
import ContactDetailPage from "../Contacts/ContactDetailPage";
import ContactEditPage from "../Contacts/ContactEditPage";
import SharedPartnerData from "../../../Shared/SharedPartnerData";
import EmptyTable from "../../../UI/Table/EmptyTable";
import { Img } from "react-image";
import Tooltip from "../../../UI/Tooltip/Tooltip";

function PartnerDetailsPage(props) {
  const data = useLoaderData();
  const params = useParams();
  const modalCtx = useContext(ModalContext);
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  const navigation = useNavigate();

  const [locationsURLsuffix, setLocationsURLsuffix] = useState(
    "intranet/partners/" + params.partnerId + "/locations"
  );
  const [contactsURLsuffix, setContactsURLsuffix] = useState(
    "intranet/partners/" + params.partnerId + "/contacts"
  );

  const {
    error: locationsError,
    pageData: locationsPageData,
    getSimpleTableData: getLocationsData,
  } = useSimpleDataFetching(locationsURLsuffix);

  const {
    error: contactsError,
    pageData: contactsPageData,
    getSimpleTableData: getContactsData,
  } = useSimpleDataFetching(contactsURLsuffix);

  //* Sorting

  const sortLocation = (accessor, order) => {
    setLocationsURLsuffix(
      "intranet/partners/" +
        params.partnerId +
        `/locations?page=1&size=20&sort=${accessor}&order=${order}`
    );
  };

  useEffect(() => {
    getLocationsData();
  }, [locationsURLsuffix]);

  const locationsColumns = [
    {
      Header: (
        <Header sortHandler={sortLocation} title="Country" id="country" />
      ),
      accessor: "country",
      width: "33%",

      Cell: ({ value, row }) => (
        <Link
          className={styles["location-link"]}
          onClick={() => detailsLocationHandler(row.original.id)}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: <Header sortHandler={sortLocation} title="City" id="city" />,
      accessor: "city",
      width: "33%",

      Cell: ({ value, row }) => (
        <Link
          className={styles["location-link"]}
          onClick={() => detailsLocationHandler(row.original.id)}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: (
        <Header sortHandler={sortLocation} title="Address" id="address" />
      ),
      accessor: "address",
      width: "33%",

      Cell: ({ value, row }) => (
        <Link
          className={styles["location-link"]}
          onClick={() => detailsLocationHandler(row.original.id)}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: "Actions",
      accessor: "id",
      id: "actions",
      width: "100px",

      Cell: ({ row }) => (
        <>
          <Tooltip text={"Open details"}>
            <Hi2Icons.HiOutlineInformationCircle
              className={styles["details-icon"]}
              onClick={() => detailsLocationHandler(row.original.id)}
            />
          </Tooltip>
          <Tooltip text={"Edit location"}>
            <Hi2Icons.HiOutlinePencil
              className={styles["edit-icon"]}
              onClick={() => editLocationHandler(row.original.id)}
            />
          </Tooltip>
          <Tooltip text={"Delete location"}>
            <Hi2Icons.HiOutlineTrash
              className={styles["delete-icon"]}
              onClick={() => deleteLocationHandler(row.original.id)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  //*Sorting

  const sortContact = (accessor, order) => {
    setContactsURLsuffix(
      "intranet/partners/" +
        params.partnerId +
        `/contacts?page=1&size=20&sort=${accessor}&order=${order}`
    );
  };

  useEffect(() => {
    getContactsData();
  }, [contactsURLsuffix]);

  const contactsColumns = [
    {
      Header: <Header sortHandler={sortContact} title="Name" id="name" />,
      accessor: "name",
      width: "50%",

      Cell: ({ value, row }) => (
        <Link
          className={styles["location-link"]}
          onClick={() => detailsContactHandler(row.original.id)}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: (
        <Header sortHandler={sortContact} title="Position" id="position" />
      ),
      accessor: "position",
      width: "50%",

      Cell: ({ value, row }) => (
        <Link
          className={styles["location-link"]}
          onClick={() => detailsContactHandler(row.original.id)}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: "Actions",
      accessor: "id",
      id: "actions",
      width: "100px",

      Cell: ({ row, value }) => (
        <>
          <Tooltip text={"Open details"}>
            <Hi2Icons.HiOutlineInformationCircle
              className={styles["details-icon"]}
              onClick={() => detailsContactHandler(row.original.id)}
            />
          </Tooltip>
          <Tooltip text={"Edit contact"}>
            <Hi2Icons.HiOutlinePencil
              className={styles["edit-icon"]}
              onClick={() => editContactHandler(row.original.id)}
            />
          </Tooltip>
          <Tooltip text={"Delete contact"}>
            <Hi2Icons.HiOutlineTrash
              className={styles["delete-icon"]}
              onClick={() => deleteContactHandler(row.original.id)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const [modalType, setModalType] = useState("");

  const [locationId, setLocationId] = useState("");
  const [contactId, setContactId] = useState("");

  const [deleteAction, setDeleteAction] = useState(null);

  const newLocationHandler = () => {
    setModalType("newLocation");

    modalCtx.showModal();
  };

  const newContactHandler = () => {
    setModalType("newContact");

    modalCtx.showModal();
  };

  const editLocationHandler = (id) => {
    setModalType("editLocation");

    setLocationId(id);
    modalCtx.showModal();
  };

  const editContactHandler = (id) => {
    setModalType("editContact");

    setContactId(id);
    modalCtx.showModal();
  };

  const deleteLocationHandler = async (id) => {
    setModalType("delete");

    modalCtx.showModal();
    setDeleteAction(() => () => locationDelete(id));
  };

  const locationDelete = async (id) => {
    const URL =
      process.env.REACT_APP_API_URL +
      `intranet/partners/${params.partnerId}/locations/` +
      id;
    await fetchRequest(URL, "DELETE", headers, undefined);

    getLocationsData();
  };

  const deleteContactHandler = async (id) => {
    setModalType("delete");

    modalCtx.showModal();
    setDeleteAction(() => () => contactDelete(id));
  };

  const contactDelete = async (id) => {
    const URL =
      process.env.REACT_APP_API_URL +
      `intranet/partners/${params.partnerId}/contacts/` +
      id;
    await fetchRequest(URL, "DELETE", headers, undefined);

    getContactsData();
  };

  const detailsLocationHandler = (id) => {
    setModalType("locationDetails");

    setLocationId(id);
    modalCtx.showModal();
  };

  const detailsContactHandler = (id) => {
    setModalType("contactDetails");

    setContactId(id);
    modalCtx.showModal();
  };

  const deleteHandler = () => {
    setModalType("delete");

    modalCtx.showModal();
    setDeleteAction(() => () => deletePartnerHandler());
  };

  const deletePartnerHandler = async () => {
    const URL =
      process.env.REACT_APP_API_URL + "ava/partners/" + params.partnerId;

    await fetchRequest(URL, "DELETE", headers, undefined);
    navigation("/av-alliance/intranet/partners", {
      options: { replace: true },
    });
  };

  let kebabOptions = [
    {
      id: "edit-info",
      label: "Edit info",
      onClick: () => navigation("update"),
    },
  ];

  if (!props.intranet) {
    kebabOptions = [
      {
        id: "edit",
        label: "Edit partner",
        onClick: () => navigation("update"),
      },
      {
        id: "edit-info",
        label: "Edit info",
        onClick: () => navigation("details/update"),
      },
      { id: "delete", label: "Delete", onClick: () => deleteHandler() },
    ];
  }

  return (
    <>
      {modalCtx.isModal && modalType === "locationDetails" && (
        <LocationDetailsPage locationId={locationId} />
      )}
      {modalCtx.isModal && modalType === "contactDetails" && (
        <ContactDetailPage contactId={contactId} />
      )}
      {modalCtx.isModal && modalType === "editLocation" && (
        <LocationEditPage
          locationId={locationId}
          method="PUT"
          refresh={getLocationsData}
        />
      )}
      {modalCtx.isModal && modalType === "editContact" && (
        <ContactEditPage
          contactId={contactId}
          method="PUT"
          refresh={getContactsData}
        />
      )}
      {modalCtx.isModal && modalType === "newLocation" && (
        <LocationEditPage method="POST" refresh={getLocationsData} />
      )}
      {modalCtx.isModal && modalType === "newContact" && (
        <ContactEditPage method="POST" refresh={getContactsData} />
      )}
      {modalCtx.isModal && modalType === "delete" && (
        <Confirm onDelete={deleteAction} />
      )}

      <Card className={styles.container}>
        <Img
          className={styles["company-logo"]}
          src={
            process.env.REACT_APP_API_URL +
            `file/download?key=${data.partnerDetails.imageKey}`
          }
          alt="Patner logo"
          height={150}
          loader={<img src="/spinner.gif" alt="loading..."></img>}
        />
        <div className={styles["title-container"]}>
          <div className={styles.title}>
            <h1>{data.partnerDetails.name}</h1>
          </div>
          <KebabMenu
            className={styles.kebab}
            label="actions"
            options={kebabOptions}
          />
        </div>

        <div className={styles.content}>
          {locationsError ? (
            <ErrorPage
              error={locationsError}
              title={locationsError.title}
              message={locationsError.message}
            />
          ) : (
            <>
              <SharedPartnerData
                data={data.partnerDetails}
                services={data.servicesOptions}
              />
              <div className={styles["partner-locations"]}>
                <h2>Locations</h2>
                <Button
                  className={styles["create-location"]}
                  onClick={newLocationHandler}
                >
                  Add new
                </Button>
                <Table
                  columns={locationsColumns}
                  data={locationsPageData.rowData}
                  isLoading={locationsPageData.isLoading}
                />
                {locationsPageData.rowData.length === 0 && <EmptyTable />}
              </div>
            </>
          )}
          {contactsError ? (
            <ErrorPage
              error={contactsError}
              title={contactsError.title}
              message={contactsError.message}
            />
          ) : (
            <div className={styles["partner-contacts"]}>
              <h2>Contacts</h2>
              <Button
                className={styles["create-contact"]}
                onClick={newContactHandler}
              >
                Add new
              </Button>
              <Table
                columns={contactsColumns}
                data={contactsPageData.rowData}
                isLoading={contactsPageData.isLoading}
              />
              {contactsPageData.rowData.length === 0 && <EmptyTable />}
            </div>
          )}
        </div>
      </Card>
    </>
  );
}

export default PartnerDetailsPage;

import React, { useMemo } from "react";
import { useTable } from "react-table";
import Loader from "../Loader/Loader";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const Table = ({ columns, data, isLoading, manualPagination = false }) => {
  const columnData = useMemo(() => columns, [columns]);
  const rowData = useMemo(() => data, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columnData,
      data: rowData,
      manualPagination,
    });

  return (
    <>
      <table {...getTableProps()}>
        <TableHeader headerGroups={headerGroups} />
        <TableBody
          rows={rows}
          prepareRow={prepareRow}
          getTableBodyProps={getTableBodyProps}
        />
      </table>
      {isLoading ? <Loader /> : null}
    </>
  );
};

export default Table;

import React, { useContext, useEffect, useState } from "react";
import styles from "./UploadProgress.module.css";
import Progressbar from "./UI/CircularProgressbar/CircularProgressbar";
import DnDContext from "./context/dnd-context";
import LinearProgressBar from "./UI/BigFileUploadProgress/BigFileUploadProgress";

function UploadProgress() {
  const dndCtx = useContext(DnDContext);

  const [percentage, setPercentage] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    if (dndCtx.upload === true) {
      setIsOpen(true);
    }
  }, [dndCtx.upload]);

  useEffect(() => {
    if (isDone) {
      const timer = setTimeout(() => {
        setIsOpen(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isDone]);

  useEffect(() => {
    if (percentage === 100) {
      setIsDone(true);
    } else {
      setIsDone(false);
    }
  }, [percentage]);

  useEffect(() => {
    if (dndCtx.files.length >= 0 && dndCtx.uploadedCount === 0) {
      setPercentage(0);
    } else if (dndCtx.uploadedCount === dndCtx.fileCounter) {
      setPercentage(100);
    } else {
      const calculatedPercentage =
        (dndCtx.uploadedCount / dndCtx.fileCounter) * 100;
      setPercentage(Math.floor(calculatedPercentage));
    }
  }, [dndCtx.files, dndCtx.uploadedCount, dndCtx.fileCounter]);

  const closeHandler = () => {
    if (!dndCtx.upload) {
      setIsOpen(!isOpen);
      setIsDone(false);
      dndCtx.setUploadedCount(0);
      dndCtx.resetBigFiles();
    }
  };

  let message;
  if (dndCtx.isError === 409) {
    message = (
      <>
        <span className={styles.warning}>
          One or more files have already uploaded.
        </span>
        <br />
        <span>Please check the document library</span>
      </>
    );
  } else if (dndCtx.isError) {
    message = (
      <>
        <span className={styles.alert}>
          Upload failed, something went wrong!
        </span>
        <br />
        <span>Please check the document library</span>
      </>
    );
  } else if (isDone && dndCtx.uploadedCount === 1) {
    message = (
      <>
        <span>You have successfully uploaded {dndCtx.uploadedCount} file.</span>
        <br />
        <span>Click to close!</span>
      </>
    );
  } else if (isDone && dndCtx.uploadedCount !== 1) {
    message = (
      <>
        <span>
          You have successfully uploaded {dndCtx.uploadedCount} files.
        </span>
        <br />
        <span>Click to close!</span>
      </>
    );
  } else {
    message = (
      <>
        <span>Upload in progress!</span>
        <br />
        <span>
          {dndCtx.uploadedCount} of {dndCtx.fileCounter} files have been
          uploaded
        </span>
      </>
    );
  }

  return (
    <nav
      onClick={closeHandler}
      className={`${styles["progress-container"]} ${isOpen ? styles.open : ""}`}
    >
      <div className={styles["big-file-progress"]}>
        {dndCtx.bigFiles.length > 0 &&
          dndCtx.bigFiles.map((file) => {
            return (
              <LinearProgressBar
                key={file.name}
                fileName={file.name}
                fileSize={file.size}
                chunksUploaded={file.chunkCounter}
                abort={file.abort}
                hasError={file.hasError}
              />
            );
          })}
      </div>
      <div className={styles["all-file-progress"]}>
        <Progressbar
          percentage={percentage}
          circleWidth={50}
          done={isDone}
          failed={dndCtx.isError}
        />
        <div className={styles["progress-text"]}>{message}</div>
      </div>
    </nav>
  );
}

export default UploadProgress;

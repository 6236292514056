import { useContext, useState } from "react";
import styles from "./UploadLogo.module.css";
import { Form } from "react-router-dom";
import Input from "../../../UI/Input/Input";
import Button from "../../../UI/Button/Button";
import FormDataContext from "../../../context/formData-context";
import Modal from "../../../UI/Modal/Modal";
import ModalContext from "../../../context/modal-context";
import * as Hi2Icons from "react-icons/hi2";

function UploadLogo(props) {
  const modalCtx = useContext(ModalContext);
  const dataCtx = useContext(FormDataContext);

  const [file, setFile] = useState(null);

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const submitHandler = async () => {
    const fileName = file.name;
    props.upload(file, fileName);
  };

  return (
    <Modal>
      <div className={styles.card}>
        <div className={styles.title}>
          <h2>Upload logo</h2>
          <Hi2Icons.HiOutlineXMark
            className={styles["close-button"]}
            onClick={() => {
              modalCtx.showModal();
              dataCtx.editFromData({});
            }}
          />
        </div>
        <Form>
          <div className={styles.content}>
            <Input
              accept="image/*"
              type="file"
              label="Image"
              id="image"
              name="image"
              onChange={handleChange}
            />
            <div className={styles["buttons"]}>
              <Button
                type="submit"
                className={styles["submit-button"]}
                onClick={submitHandler}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default UploadLogo;

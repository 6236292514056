import React, { useEffect, useState } from "react";
import Modal from "../../../UI/Modal/Modal";
import { useContext } from "react";
import FormDataContext from "../../../context/formData-context";
import styles from "./RequestEditPage.module.css";
import Button from "../../../UI/Button/Button";
import ModalContext from "../../../context/modal-context";
import { getAuthToken } from "../../../util/auth";
import { fetchRequest } from "../../../util/fetchRequest";
import { Form } from "react-router-dom";
import Input from "../../../UI/Input/Input";
import * as Hi2Icons from "react-icons/hi2";
import SingleSelectNew from "../../../UI/Input/SingleSelectNew";
import TextArea from "../../../UI/Input/TextArea";
import moment from "moment";
import PhoneInputField from "../../../UI/Input/PhoneInputField";

function RequestEditPage(props) {
  const dataCtx = useContext(FormDataContext);
  const modalCtx = useContext(ModalContext);

  const method = props.method;
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  let URL =
    process.env.REACT_APP_API_URL + `ava/crm/requests/` + props.requestId;

  if (props.type === "partner") {
    URL =
      process.env.REACT_APP_API_URL +
      `crm/${props.partnerId}/requests/` +
      props.requestId;
  }

  useEffect(() => {
    dataCtx.editFromData({});
  }, []);

  const [data, setData] = useState({});

  const [defaultStatusValue, setDefaultStatusValue] = useState({
    value: "new",
    label: "New",
  });
  const [defaultChannelValue, setDefaultChannelValue] = useState("");
  const [defaultPriorityValue, setDefaultPriorityValue] = useState({
    value: "normal",
    label: "Normal",
  });
  const [defaultLeadValue, setDefaultLeadValue] = useState("");
  const [defaultMemberValue, setDefaultMemberValue] = useState("");
  const [formatedDate, setformatedDate] = useState("");
  const options = props.options;

  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    const response = await fetchRequest(URL, "GET", headers, undefined);
    setData(response);
    if (method === "PUT") {
      const statusValue = statusOptions.find(
        (option) => option.value === response.status
      );
      setDefaultStatusValue(statusValue);

      const channelValue = channelOptions.find(
        (option) => option.value === response.channel
      );
      setDefaultChannelValue(channelValue);

      const priorityValue = priorityOptions.find(
        (option) => option.value === response.priority
      );
      setDefaultPriorityValue(priorityValue);

      const leadValue = props.options.find(
        (option) => option.value === response.leadId
      );
      setDefaultLeadValue(leadValue);

      const memberValue = props.options.find(
        (option) => option.value === response.partnerId
      );
      setDefaultMemberValue(memberValue);

      setformatedDate(response.date.split("T")[0]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (method === "PUT") {
      fetchData();
    }
  }, []);

  const [partnerId, setPartnerId] = useState(props.partnerId);

  let requestData = {
    url: process.env.REACT_APP_API_URL + `ava/crm/requests`,
    method: method,
    headers: headers,
  };
  if (method === "PUT") {
    requestData = {
      url:
        process.env.REACT_APP_API_URL + `ava/crm/requests/${props.requestId}`,
      method: method,
      headers: headers,
    };
  }
  if (props.type === "partner" && method === "POST") {
    requestData = {
      url: process.env.REACT_APP_API_URL + `crm/${partnerId}/requests`,
      method: method,
      headers: headers,
    };
  }
  if (props.type === "partner" && method === "PUT") {
    requestData = {
      url:
        process.env.REACT_APP_API_URL +
        `crm/${partnerId}/requests/${props.requestId}`,
      method: method,
      headers: headers,
    };
  }

  useEffect(() => {
    let initialValues = {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      date: "",
      channel: "",
      source: "",
      priority: defaultPriorityValue.value,
      status: defaultStatusValue.value,
      leadId: null,
      partnerId: null,
    };

    if (method === "PUT") {
      initialValues = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        company: data.company,
        message: data.message,
        date: data.date,
        channel: data.channel,
        source: data.source,
        priority: data.priority,
        status: data.status,
        leadId: data.leadId,
        partnerId: data.partnerId,
      };
    }

    dataCtx.editFromData(initialValues);
  }, [data, method]);

  useEffect(() => {
    dataCtx.editRequestData(requestData);
  }, [partnerId]);

  const statusOptions = [
    { value: "new", label: "New" },
    { value: "handled", label: "Handled" },
    { value: "declined", label: "Declined" },
  ];

  const priorityOptions = [
    { value: "important", label: "Important" },
    { value: "strong", label: "Strong" },
    { value: "normal", label: "Normal" },
    { value: "weak", label: "Weak" },
    { value: "spam", label: "Spam" },
    { value: "unrelated", label: "Unrelated" },
  ];

  const channelOptions = [
    { value: "AV Alliance", label: "AV Alliance" },
    { value: "web", label: "Web" },
    { value: "email", label: "Email" },
    { value: "phone", label: "Phone" },
    { value: "social", label: "Social" },
    { value: "live", label: "Live" },
    { value: "other", label: "Other" },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let formattedDate = value;

    if (name === "date") {
      setformatedDate(value);
      const dateObj = moment(value, "YYYY-MM-DD HH:mm:ss Z").add(2, "hours");
      formattedDate = dateObj.format();
    } else if (name === "email") {
      if (isTouched) {
        setIsTouched(false);
      }
    }
    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: formattedDate,
    });
  };

  const validateEmail = (email) => {
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    return regex.test(email);
  };

  const [isTouched, setIsTouched] = useState(false);

  const handleEmailBlur = () => {
    setIsTouched(true);
    handleInputChange({
      target: {
        name: "email",
        value: dataCtx.formData.email,
      },
    });
  };

  const isEmailValid =
    !isTouched ||
    dataCtx.formData.email.trim() === "" ||
    validateEmail(dataCtx.formData.email);

  const handlePhoneChange = (value) => {
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      phone: value,
    }));
  };

  const handleSingleSelectChange = (selectedOption, fieldName) => {
    const selectedValue = selectedOption.value;
    if (fieldName === "partnerId") {
      setPartnerId(selectedValue);
    }
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      [fieldName]: selectedOption.value,
    }));
  };

  const submitHandler = async () => {
    await dataCtx.fetchData();

    props.refresh();
    modalCtx.showModal();
  };

  const closeHandler = () => {
    dataCtx.editFromData({});
    modalCtx.showModal();
  };

  const title = method === "PUT" ? "Edit" : method === "POST" ? "Create" : "";

  return (
    <Modal>
      {(!isLoading || method !== "PUT") && (
        <div className={styles.container}>
          <Form>
            <div className={styles.title}>
              <h2>{title} request</h2>
              <div className={styles["close-container"]}>
                <Hi2Icons.HiOutlineXMark
                  className={styles["close-button"]}
                  onClick={closeHandler}
                />
              </div>
            </div>
            <div className={styles.content}>
              <h3 className={styles["sub-title"]}>Customer details</h3>
              <Input
                label="Name"
                id="name"
                name="name"
                value={dataCtx.formData.name || ""}
                onChange={handleInputChange}
              />
              <Input
                label="Email"
                type="email"
                id="email"
                name="email"
                value={dataCtx.formData.email || ""}
                onChange={handleInputChange}
                onBlur={handleEmailBlur}
              />
              {!isEmailValid && (
                <p className={styles.error}>Please enter a valid email.</p>
              )}
              <PhoneInputField
                id="phone"
                name="phone"
                label="Phone number"
                title="phone"
                startValue={data.phone}
                value={dataCtx.formData.phone}
                onChange={handlePhoneChange}
              />
              <Input
                label="Company"
                id="company"
                name="company"
                value={dataCtx.formData.company || ""}
                onChange={handleInputChange}
              />
              <TextArea
                label="Message"
                id="message"
                name="message"
                rows={10}
                value={dataCtx.formData.message}
                onChange={handleInputChange}
              />
              <h3 className={styles["sub-title"]}>Acquisition details</h3>
              <Input
                label="Date"
                type="date"
                id="date"
                name="date"
                value={formatedDate || ""}
                onChange={handleInputChange}
              />
              <SingleSelectNew
                id="channel"
                name="channel"
                label="Channel"
                options={channelOptions}
                defaultValue={defaultChannelValue}
                value={dataCtx.formData.channel}
                onSingleChange={handleSingleSelectChange}
              />
              <Input
                label="Source"
                id="source"
                name="source"
                value={dataCtx.formData.source || ""}
                onChange={handleInputChange}
              />
              <h3 className={styles["sub-title"]}>Request details</h3>
              <SingleSelectNew
                id="priority"
                name="priority"
                label="Priority"
                options={priorityOptions}
                defaultValue={defaultPriorityValue}
                value={dataCtx.formData.priority}
                onSingleChange={handleSingleSelectChange}
              />
              <SingleSelectNew
                id="status"
                name="status"
                label="Status"
                options={statusOptions}
                defaultValue={defaultStatusValue}
                value={dataCtx.formData.status}
                onSingleChange={handleSingleSelectChange}
              />
              <h3 className={styles["sub-title"]}>Other details</h3>

              {props.type === "partner" && (
                <SingleSelectNew
                  id="leadId"
                  name="leadId"
                  label="Lead"
                  menuPlacement="top"
                  options={options}
                  defaultValue={defaultLeadValue}
                  value={dataCtx.formData.leadId}
                  onSingleChange={handleSingleSelectChange}
                />
              )}
              {props.type === "AVA" && (
                <SingleSelectNew
                  id="partnerId"
                  name="partnerId"
                  label="Member"
                  menuPlacement="top"
                  options={options}
                  defaultValue={defaultMemberValue}
                  value={dataCtx.formData.partnerId}
                  onSingleChange={handleSingleSelectChange}
                />
              )}
              <div className={styles["buttons"]}>
                <Button
                  type="submit"
                  className={styles["submit-button"]}
                  onClick={submitHandler}
                  disabled={!isEmailValid}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Modal>
  );
}

export default RequestEditPage;

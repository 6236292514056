import React, { useContext, useEffect, useState } from "react";
import styles from "./LeadDetailsPage.module.css";
import { getAuthToken } from "../../../util/auth";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import Card from "../../../UI/Card/Card";
import KebabMenu from "../../../UI/KebabMenu/KebabMenu";
import LeadComments from "./LeadComments";
import ModalContext from "../../../context/modal-context";
import LeadEditPage from "./LeadEditPage";
import Confirm from "../../../UI/Confirm/Confirm";
import { fetchRequest } from "../../../util/fetchRequest";
import FormDataContext from "../../../context/formData-context";

function LeadDetailsPage() {
  const params = useParams();
  const navigate = useNavigate();
  const modalCtx = useContext(ModalContext);
  const dataCtx = useContext(FormDataContext);

  const token = getAuthToken();
  const URL =
    process.env.REACT_APP_API_URL +
    `crm/${params.partnerId}/leads/${params.leadId}`;
  const headers = { Authorization: `Bearer ${token}` };

  const loaderData = useLoaderData();

  const comments = loaderData.comments.items;
  const users = loaderData.userOptions;

  const [modalType, setModalType] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [data, setData] = useState(loaderData.leadDetails);

  useEffect(() => {
    if (data.status === "active") {
      setStatusValue("Active");
    } else if (data.status === "inactive") {
      setStatusValue("Inactive");
    }
  }, [data]);

  const deleteHandler = () => {
    setModalType("delete");
    modalCtx.showModal();
  };

  const deleteAction = async () => {
    await fetchRequest(URL, "DELETE", headers, undefined);
    navigate(-1);
  };

  const editHandler = () => {
    setModalType("edit");
    modalCtx.showModal();
  };

  const refreshHandler = () => {
    setData(dataCtx.formData);
  };

  const kebabOptions = [
    {
      id: "edit",
      label: "Edit",
      onClick: () => editHandler(),
    },
    { id: "delete", label: "Delete", onClick: () => deleteHandler() },
  ];

  return (
    <>
      {modalCtx.isModal && modalType === "delete" && (
        <Confirm onDelete={deleteAction} />
      )}
      {modalCtx.isModal && modalType === "edit" && (
        <LeadEditPage
          refresh={refreshHandler}
          partnerId={params.partnerId}
          leadId={params.leadId}
          method="PUT"
        />
      )}
      <Card className={styles.container}>
        <div className={styles["title-container"]}>
          <div className={styles.title}>
            <h2>Lead details</h2>
          </div>
          <KebabMenu
            className={styles.kebab}
            label="actions"
            options={kebabOptions}
          />
        </div>
        <div className={styles.content}>
          <div className={styles["details"]}>
            <p>Name</p>
            <span>{data.name || "-"}</span>
          </div>
          <div className={styles["details"]}>
            <p>E-mail</p>
            <span>
              <a href={`mailto:${data.email}`}>{data.email || "-"}</a>
            </span>
          </div>
          {data.phone && (
            <div className={styles["details"]}>
              <p>Phone</p>
              <span>
                <a href={`tel:${data.phone}`}>{data.phone}</a>
              </span>
            </div>
          )}
          <div className={styles["details"]}>
            <p>Company</p>
            <span>{data.company || "-"}</span>
          </div>
          <div className={styles["details"]}>
            <p>Notes</p>
            <span>{data.notes || "-"}</span>
          </div>
          <div className={styles["details"]}>
            <p>Status</p>
            <span>{statusValue || "-"}</span>
          </div>
        </div>
      </Card>
      <Card className={styles.container}>
        <div className={styles.content}>
          <h3 className={styles["sub-title"]}>Comments</h3>
          <LeadComments comments={comments} users={users} />
        </div>
      </Card>
    </>
  );
}

export default LeadDetailsPage;

import React, { useEffect, useState } from "react";
import Modal from "../../../UI/Modal/Modal";
import { useContext } from "react";
import styles from "./LocationDetailsPage.module.css";
import ModalContext from "../../../context/modal-context";
import { getAuthToken } from "../../../util/auth";
import { useParams } from "react-router-dom";
import { fetchRequest } from "../../../util/fetchRequest";
import * as Hi2Icons from "react-icons/hi2";
import Map from "../../../UI/Input/GoogleMap";

function LocationDetailsPage(props) {
  const modalCtx = useContext(ModalContext);
  const token = getAuthToken();
  const param = useParams();
  const partnerId = param.partnerId || props.partnerId;
  const URL =
    process.env.REACT_APP_API_URL +
    `intranet/partners/` +
    partnerId +
    `/locations/${props.locationId}`;
  const headers = { Authorization: `Bearer ${token}` };

  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchRequest(URL, "GET", headers, undefined);
      setData(response);
    };
    fetchData();
  }, []);

  const generateCoordinates = () => {
    if (data.latitude && data.longitude) {
      return { lat: data.latitude, lng: data.longitude };
    } else {
      return null;
    }
  };

  return (
    <Modal>
      <div className={styles.container}>
        <div className={styles.title}>
          <h2>Location details</h2>

          <Hi2Icons.HiOutlineXMark
            className={styles["close-button"]}
            onClick={() => modalCtx.showModal()}
          />
        </div>
        <div className={styles.content}>
          <h3 className={styles["sub-title"]}>Address Details</h3>
          {data.region && (
            <div className={styles["details"]}>
              <p>Region</p>
              <span>{data.region}</span>
            </div>
          )}
          {data.country && (
            <div className={styles["details"]}>
              <p>Country</p>
              <span>{data.country}</span>
            </div>
          )}
          {data.zip && (
            <div className={styles["details"]}>
              <p>Zip</p>
              <span>{data.zip}</span>
            </div>
          )}
          {data.city && (
            <div className={styles["details"]}>
              <p>City</p>
              <span>{data.city}</span>
            </div>
          )}
          {data.county && (
            <div className={styles["details"]}>
              <p>County</p>
              <span>{data.county || "-"}</span>
            </div>
          )}
          {data.address && (
            <div className={styles["details"]}>
              <p>Address</p>
              <span>{data.address}</span>
            </div>
          )}
          {data.phone && (
            <div className={styles["details"]}>
              <p>Phone</p>
              <span>{data.phone || "-"}</span>
            </div>
          )}
          {data.longitude && data.longitude && (
            <>
              <h3 className={styles["coordinates-sub-title"]}>Coordinates</h3>
              <div className={styles["coordinate-container"]}>
                <div className={styles["details"]}>
                  <p>Latitude</p>
                  <span>{data.latitude}</span>
                </div>
                <div className={styles["details"]}>
                  <p>Longitude</p>
                  <span>{data.longitude}</span>
                </div>
              </div>
              <Map defaultLocation={generateCoordinates()} />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default LocationDetailsPage;

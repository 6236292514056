import { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as Hi2Icons from "react-icons/hi";
import styles from "./DropZone.module.css";
import { Img } from "react-image";
import Button from "../Button/Button";
import DnDContext from "../../context/dnd-context";

const Dropzone = ({ className, directoryId, refresh, uploadURL }) => {
  const {
    files,
    setFiles,
    upload,
    addBigFile,
    removeBigFile,
    resetBigFiles,
    uploadFiles,
    CHUNK_SIZE,
  } = useContext(DnDContext);
  const [rejected, setRejected] = useState([]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles?.length) {
      acceptedFiles.forEach((file) => {
        if (file.size > CHUNK_SIZE) {
          addBigFile({
            name: file.name,
            size: file.size,
            chunkCounter: 0,
          });
        }
      });
      setFiles((previousFiles) => [
        ...previousFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        ),
      ]);
    }

    if (rejectedFiles?.length) {
      setRejected((previousFiles) => [...previousFiles, ...rejectedFiles]);
    }
  }, []);

  const MAX_FILE_SIZE = 4 * 1024 * 1024 * 1024;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [".jpg", ".jpeg", ".png"],
      "video/*": [".avi", ".mov", ".mp4", ".mkv"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/zip": [".zip"],
    },
    maxSize: MAX_FILE_SIZE,
    onDrop,
  });

  useEffect(() => {
    //* Revoke the data uris to avoid memory leaks
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const removeFile = (name) => {
    setFiles((files) => files.filter((file) => file.name !== name));
    removeBigFile(name);
  };

  const removeAll = () => {
    setFiles([]);
    setRejected([]);
    resetBigFiles();
  };

  const removeRejected = (name) => {
    setRejected((files) => files.filter(({ file }) => file.name !== name));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRejected([]);
    uploadFiles(directoryId, refresh, uploadURL);
  };

  //* Filename shorten

  const ShortenedFileName = ({ fileName }) => {
    const MAX_LENGTH = 15;
    const extension = fileName.split(".").pop();
    const fileNameWithoutExtension = fileName.replace(`.${extension}`, "");

    if (fileNameWithoutExtension.length > MAX_LENGTH) {
      const shortenedFileName =
        fileNameWithoutExtension.substring(0, 5) +
        "..." +
        fileNameWithoutExtension.slice(-5) +
        `.${extension}`;
      return <p>{shortenedFileName}</p>;
    }

    return <p>{fileName}</p>;
  };

  //* Custom error message

  const CustomError = ({ errorCode }) => {
    if (errorCode === "file-invalid-type") {
      const message =
        "File type must be .avi, .doc, .docx, .jpeg, .jpg, .mkv, .mov, .mp4, .pdf, .png, .xls, .xlsx, .zip ";
      return message;
    } else if (errorCode === "file-too-large") {
      const message = `File is larger than ${MAX_FILE_SIZE / 1024 / 1024} MB.`;
      return message;
    }
  };

  const previewSrc = (file) => {
    if (
      file.name.endsWith(".jpeg") ||
      file.name.endsWith(".jpg") ||
      file.name.endsWith(".png")
    ) {
      const src = [file.preview, "/photo.png"];
      return src;
    } else if (
      file.name.endsWith(".avi") ||
      file.name.endsWith(".mov") ||
      file.name.endsWith(".mp4") ||
      file.name.endsWith(".mkv")
    ) {
      return ["/movie.png"];
    } else {
      return ["/document.png"];
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Upload Files</h2>
      <div
        {...getRootProps({
          className: className,
        })}
      >
        <input {...getInputProps()} />
        <div className={styles.dropzone}>
          <div className={styles.dropzoneInner}>
            <Hi2Icons.HiOutlineUpload className={styles.dropIcon} />
            {isDragActive ? (
              <p className={styles["upload-title"]}>Drop the files here ...</p>
            ) : (
              <>
                <p className={styles["upload-title"]}>
                  Drag & drop files here, or click to select files
                </p>
                <p className={styles["upload-note"]}>
                  Acceptable file types: .avi, .doc, .docx, .jpeg, .jpg, .mkv,
                  .mov, .mp4, .pdf, .png, .xls, .xlsx, .zip
                </p>
                <p className={styles["upload-note"]}>
                  {`Maximum file size is ${MAX_FILE_SIZE / 1024 / 1024} MB`}
                </p>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Preview */}
      {files?.length > 0 || rejected?.length > 0 ? (
        <section>
          <div className={styles.buttons}>
            <h3>Preview</h3>
            <Button
              type="button"
              onClick={removeAll}
              className={styles.removeButton}
              disabled={upload}
            >
              Remove all files
            </Button>
            <Button
              type="submit"
              className={styles.uploadButton}
              disabled={upload || files.length === 0}
            >
              Upload
            </Button>
          </div>
          {/* Accepted files */}
          <h3>Accepted Files</h3>
          <hr className={styles.uploadhr} />
          <ul className={styles.list}>
            {files.map((file) => (
              <li key={file.name} className={styles.listItem}>
                <div className={styles["list-card"]}>
                  <div className={styles.img}>
                    <Img
                      src={previewSrc(file)}
                      alt={file.name}
                      width={180}
                      onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                      }}
                    />
                  </div>
                  <Button
                    className={styles.deleteIcon}
                    onClick={() => removeFile(file.name)}
                    disabled={upload}
                  >
                    <Hi2Icons.HiOutlineX size={20} />
                  </Button>
                  <div className={styles["file-name"]}>
                    <ShortenedFileName fileName={file.name} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {/* Rejected Files */}
          <h3>Rejected Files</h3>
          <hr className={styles.uploadhr} />
          {rejected.length === 0 ? (
            <p>All files were accepted.</p>
          ) : (
            <>
              <ul className={styles.rejectedList}>
                {rejected.map(({ file, errors }) => (
                  <li key={file.name} className={styles.rejectedListItem}>
                    <div className={styles.rejectedItem}>
                      <p className={styles.rejectedFiles}>{file.name}</p>
                      <ul className={styles.rejectedList}>
                        {errors.map((error) => (
                          <li key={error.code} className={styles.errors}>
                            <CustomError errorCode={error.code} />
                          </li>
                        ))}
                      </ul>
                    </div>
                    <Button
                      className={styles.removeButton}
                      type="button"
                      onClick={() => removeRejected(file.name)}
                      disabled={upload}
                    >
                      Remove
                      {/* <Hi2Icons.HiOutlineTrash className={styles.rejectedIcon} /> */}
                    </Button>
                  </li>
                ))}
              </ul>
            </>
          )}
        </section>
      ) : null}
    </form>
  );
};

export default Dropzone;

import React, { useEffect, useState } from "react";
import SharedDocumentsPage from "../../components/Shared/SharedDocumentsPage";
import AVAInspectDocumentsPage from "../../components/Shared/AVAInspectDocumentsPage";
import { getMyRoles } from "../../components/util/auth";

function SharedDocuments() {
  const myRoles = getMyRoles();

  const [isAVA, setIsAVA] = useState(false);

  useEffect(() => {
    if (myRoles.includes("ava-manager") || myRoles.includes("root")) {
      setIsAVA(true);
    }
  }, []);

  return <>{isAVA ? <AVAInspectDocumentsPage /> : <SharedDocumentsPage />}</>;
}

export default SharedDocuments;

import { Link } from "react-router-dom";
import * as FiIcons from "react-icons/fi";
import styles from "./ErrorPage.module.css";

function ErrorPage(props) {
  return (
    <div className={styles.error}>
      <img src="/logo.png" alt="feather logo" height="80" />
      <p className={styles.link}>{props.error.status}</p>
      <h1>{props.title}</h1>
      <span>{props.message}</span>
      <div className={styles["home-link"]}>
        <Link to="/">
          <FiIcons.FiArrowLeft className={styles.icon} /> Back to home
        </Link>
      </div>
    </div>
  );
}

export default ErrorPage;

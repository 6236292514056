import { useEffect, useContext, useState } from "react";
import styles from "./EditProfilePage.module.css";
import { Form } from "react-router-dom";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import FormDataContext from "../context/formData-context";
import { getAuthToken } from "../util/auth";
import Modal from "../UI/Modal/Modal";
import ModalContext from "../context/modal-context";
import * as Hi2Icons from "react-icons/hi2";
import PhoneInputField from "../UI/Input/PhoneInputField";
import LocationFinder from "../UI/Input/LocationFinder";

function EditProfilePage(props) {
  const method = props.method;
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  const dataCtx = useContext(FormDataContext);

  const modalCtx = useContext(ModalContext);

  useEffect(() => {
    dataCtx.editFromData({});
  }, []);

  const data = props.data;

  useEffect(() => {
    const initialValues = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      publicEmail: data.publicEmail,
      phone: data.phone,
      position: data.position,
      web: data.web,
      lat: data.lat,
      lon: data.lon,
    };
    const URLsuffix = data.id;

    dataCtx.editFromData(initialValues);

    const requestData = {
      url: process.env.REACT_APP_API_URL + "user/accounts/" + URLsuffix,
      method: "PUT",
      headers: headers,
    };

    dataCtx.editRequestData(requestData);
  }, [data, method]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      if (emailIsTouched) {
        setEmailIsTouched(false);
      }
    }
    if (name === "publicEmail") {
      if (publicEmailIsTouched) {
        setPublicEmailIsTouched(false);
      }
    }

    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    return regex.test(email);
  };

  const [emailIsTouched, setEmailIsTouched] = useState(false);

  const handleEmailBlur = () => {
    setEmailIsTouched(true);
    handleInputChange({
      target: {
        name: "email",
        value: dataCtx.formData.email,
      },
    });
  };

  const isEmailValid =
    !emailIsTouched ||
    dataCtx.formData.email.trim() === "" ||
    validateEmail(dataCtx.formData.email);

  const [publicEmailIsTouched, setPublicEmailIsTouched] = useState(false);

  const handlePublicEmailBlur = () => {
    setPublicEmailIsTouched(true);
    handleInputChange({
      target: {
        name: "publicEmail",
        value: dataCtx.formData.publicEmail,
      },
    });
  };

  const isPublicEmailValid =
    !publicEmailIsTouched ||
    dataCtx.formData.email.trim() === "" ||
    validateEmail(dataCtx.formData.email);

  const handlePhoneChange = (value) => {
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      phone: value,
    }));
  };

  const handleGoogleMapChange = (coordinates) => {
    const lat = coordinates.lat;
    const lon = coordinates.lng;
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      lat: lat,
      lon: lon,
    }));
  };

  const locationDelete = () => {
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      lat: null,
      lon: null,
    }));
  };

  const [latitude, setLatitude] = useState(data.lat);
  const [longitude, setLongitude] = useState(data.lon);

  const handleNumberInputChange = (event) => {
    const { name, value } = event.target;
    const parsedValue = value !== "" ? parseFloat(value) : null;

    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: parsedValue,
    });
    if (name === "lat") {
      setLatitude(parsedValue);
    }
    if (name === "lon") {
      setLongitude(parsedValue);
    }
  };

  const generateCoordinates = () => {
    if (data.lat && data.lon) {
      return { lat: data.lat, lng: data.lon };
    } else {
      return null;
    }
  };

  const handleMarkerClick = (markerPosition) => {
    handleGoogleMapChange(markerPosition);
  };

  const submitHandler = async () => {
    await dataCtx.fetchData();

    props.refresh();
    modalCtx.showModal();
  };

  return (
    <Modal>
      <div className={styles.container}>
        <div className={styles.title}>
          <h2>Edit profile</h2>
          <Hi2Icons.HiOutlineXMark
            className={styles["close-button"]}
            onClick={() => {
              modalCtx.showModal();
              dataCtx.editFromData({});
            }}
          />
        </div>
        {/* <Form> */}
        <div className={styles.content}>
          <h3 className={styles.subtitle}>Profile details</h3>

          <Input
            label="First name"
            id="firstName"
            name="firstName"
            value={dataCtx.formData.firstName || ""}
            onChange={handleInputChange}
            autoFocus={true}
          />
          <Input
            label="Last name"
            id="lastName"
            name="lastName"
            value={dataCtx.formData.lastName || ""}
            onChange={handleInputChange}
          />
          <Input
            label="Position"
            id="position"
            name="position"
            value={dataCtx.formData.position || ""}
            onChange={handleInputChange}
          />
          <Input
            type="email"
            label="Email address"
            id="email"
            name="email"
            className={styles.emailInput}
            value={dataCtx.formData.email || ""}
            onChange={handleInputChange}
            onBlur={handleEmailBlur}
          />
          {!isEmailValid && (
            <p className={styles.error}>Please enter a valid email.</p>
          )}
          <Input
            type="email"
            label="Public email address"
            id="publicEmail"
            name="publicEmail"
            className={styles.emailInput}
            value={dataCtx.formData.publicEmail || ""}
            onChange={handleInputChange}
            onBlur={handlePublicEmailBlur}
          />
          {!isPublicEmailValid && (
            <p className={styles.error}>Please enter a valid email.</p>
          )}
          <PhoneInputField
            id="phone"
            name="phone"
            label="Phone number"
            title="phone"
            startValue={data.phone}
            value={dataCtx.formData.phone}
            onChange={handlePhoneChange}
          />
          <Input
            label="Web"
            id="web"
            name="web"
            value={dataCtx.formData.web || ""}
            onChange={handleInputChange}
          />
          <h3 className={styles["location-subtitle"]}>Location details</h3>
          <div className={styles.coordinates}>
            <div className={styles["details"]}>
              <Input
                label="Latitude"
                id="lat"
                name="lat"
                type="number"
                min="-90"
                max="90"
                value={dataCtx.formData.lat || ""}
                onChange={handleNumberInputChange}
              />
            </div>
            <div className={styles["details"]}>
              <Input
                label="Longitude"
                id="lon"
                name="lon"
                type="number"
                min="-180"
                max="180"
                value={dataCtx.formData.lon || ""}
                onChange={handleNumberInputChange}
              />
            </div>
          </div>
          <LocationFinder
            onMarkerClick={handleMarkerClick}
            onLocationFind={handleGoogleMapChange}
            defaultLocation={generateCoordinates()}
            onDelete={locationDelete}
            latitude={latitude}
            longitude={longitude}
          />

          <div className={styles["buttons"]}>
            <Button
              className={styles["submit-button"]}
              onClick={submitHandler}
              disabled={!isEmailValid}
            >
              Save
            </Button>
          </div>
        </div>
        {/* </Form> */}
      </div>
    </Modal>
  );
}

export default EditProfilePage;

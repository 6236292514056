import styles from "./Loader.module.css";

const Loader = () => {
  return (
    <div className={styles.loader}>
      <img src="/spinner.gif" alt="Loader" height="40" />
    </div>
  );
};

export default Loader;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./KebabMenu.module.css";
import Button from "../Button/Button";
import * as Hi2Icons from "react-icons/hi2";

function KebabMenu({ label, options, className }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest("#kebab-menu") &&
        !event.target.closest("#kebab-button") &&
        isOpen === true
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen === true) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={`${styles.kebabMenu} ${className}`}>
      <Button
        id="kebab-button"
        className={styles["menu-button"]}
        onClick={toggleMenu}
      >
        <Hi2Icons.HiEllipsisHorizontal />
      </Button>
      {isOpen && (
        <ul id="kebab-menu" className={styles["options-list"]}>
          {options.map((option) => (
            <li
              key={option.id}
              className={`${styles.option} ${
                option.id === "delete" ? styles["delete-option"] : ""
              }`}
              onClick={option.onClick}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

KebabMenu.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default KebabMenu;

import styles from "./TableBody.module.css";

function TableBody({ rows, prepareRow, getTableBodyProps }) {
  return (
    <tbody {...getTableBodyProps()} className={styles.tableBody}>
      {rows.map((row, i) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()} className={styles.row}>
            {row.cells.map((cell) => {
              return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
            })}
          </tr>
        );
      })}
    </tbody>
  );
}

export default TableBody;

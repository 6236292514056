import { useEffect, useState } from "react";
import Card from "../UI/Card/Card";
import styles from "./ProfilePage.module.css";
import { Img } from "react-image";
import { getAuthToken } from "../util/auth";
import Button from "../UI/Button/Button";
import Crop from "../UI/Crop/Crop";
import { useContext } from "react";
import ModalContext from "../context/modal-context";
import { useLoaderData } from "react-router-dom";
import Confirm from "../UI/Confirm/Confirm";
import EditProfilePage from "./EditProfilePage";
import Map from "../UI/Input/GoogleMap";
import UserContext from "../context/user-context";

function ProfilePage() {
  const data = useLoaderData();
  const token = getAuthToken();
  const userCtx = useContext(UserContext);

  const [file, setFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [currentPhotoURL, setCurrentPhotoURL] = useState(null);
  const [key, setKey] = useState(new Date().getTime());

  const [modalType, setModalType] = useState("");

  const ctx = useContext(ModalContext);

  const localPhotoURL = "/dummy-profile.jpg";

  useEffect(() => {
    if (data.imageKey) {
      setCurrentPhotoURL(
        process.env.REACT_APP_API_URL + `file/download?key=${data.imageKey}`
      );
    }
  }, [data.imageKey]);

  const handleChange = (event) => {
    const file = event.target.files[0];
    const timestamp = new Date().getTime();
    const modifiedFileName = `${timestamp}_${file.name}`;
    setModalType("crop");
    if (file) {
      const newFile = new File([file], modifiedFileName);
      setFile(newFile);
      setPhotoURL(URL.createObjectURL(newFile));
      ctx.showModal();
      setKey(new Date().getTime());
    }
  };

  const uploadHandler = async (event) => {
    const URL =
      process.env.REACT_APP_API_URL +
      `user/accounts/${data.id}/image?fileName=${file.name}`;
    fetch(URL, {
      method: "POST",
      body: file,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCurrentPhotoURL(
          process.env.REACT_APP_API_URL + `file/download?key=${data.imageKey}`
        );
        setPhotoURL(null);
        setFile(null);
        localStorage.setItem("imageKey", data.imageKey);
        userCtx.getNewImageKey();
      })
      .catch((err) => console.error(err));
  };

  const cancelHandler = () => {
    setFile(null);
    setPhotoURL(null);
  };

  const deleteImage = async () => {
    const URL =
      process.env.REACT_APP_API_URL + `user/accounts/${data.id}/image`;
    fetch(URL, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setCurrentPhotoURL(null);
    setFile(null);
    setPhotoURL(null);
    userCtx.setImageKey(null);
  };

  const editHandler = () => {
    setModalType("edit");
    ctx.showModal();
  };

  const deleteHandler = () => {
    setModalType("delete");
    ctx.showModal();
  };

  const refresh = () => {
    window.location.reload();
  };

  const generateCoordinates = () => {
    if (data.lat && data.lon) {
      return { lat: data.lat, lng: data.lon };
    } else {
      return null;
    }
  };

  return (
    <>
      {modalType === "crop" && ctx.isModal && (
        <Crop {...{ photoURL, setPhotoURL, setFile }} />
      )}
      {modalType === "delete" && ctx.isModal && (
        <Confirm onDelete={deleteImage} />
      )}
      {modalType === "edit" && ctx.isModal && (
        <EditProfilePage data={data} refresh={refresh} />
      )}
      <Card className={styles.card}>
        <div className={styles["title-container"]}>
          <div className={styles.title}>
            <h1>Profile</h1>
          </div>
          <Button className={styles["edit-button"]} onClick={editHandler}>
            Edit
          </Button>
        </div>
        <div className={styles.content}>
          <h3>Profile picture</h3>
          <label htmlFor="profilePhoto" key={key}>
            <p className={styles.note}>
              To change profile picture click on the current picture! Max upload
              size: 5 MB
            </p>
            <input
              accept="image/*"
              id="profilePhoto"
              type="file"
              size="5242880"
              style={{ display: "none" }}
              onChange={handleChange}
            />
            <Img
              className={styles["profile-photo"]}
              src={[photoURL, currentPhotoURL, localPhotoURL]}
              alt="profilePhoto"
              height={150}
            />
          </label>
          <div className={styles.buttons}>
            <Button
              className={styles["upload-button"]}
              onClick={uploadHandler}
              disabled={!file}
            >
              Upload
            </Button>
            <Button
              className={styles["upload-button"]}
              onClick={cancelHandler}
              disabled={!file}
            >
              Cancel
            </Button>
            <Button
              className={styles["delete-button"]}
              onClick={deleteHandler}
              disabled={!currentPhotoURL}
            >
              Delete
            </Button>
          </div>
          <h3>Profile details</h3>
          {(data.firstName || data.lastName) && (
            <div className={styles["details"]}>
              <p>Name:</p>
              <span>{`${data.firstName} ${data.lastName}`}</span>{" "}
            </div>
          )}
          <div className={styles["details"]}>
            <p>Email:</p>
            <span>{`${data.email}`}</span>
          </div>
          {data.position && (
            <div className={styles["details"]}>
              <p>Position:</p>
              <span>{`${data.position}`}</span>
            </div>
          )}
          {data.publicEmail && (
            <div className={styles["details"]}>
              <p>Public email:</p>
              <span>{`${data.publicEmail}`}</span>
            </div>
          )}
          {data.phone && (
            <div className={styles["details"]}>
              <p>Phone:</p>
              <span>{`${data.phone}`}</span>
            </div>
          )}
          {data.web && (
            <div className={styles["details"]}>
              <p>Web:</p>
              <span>{`${data.web}`}</span>
            </div>
          )}
          {data.lat && data.lon && (
            <>
              <h3 className={styles["locations-subtitle"]}>Location details</h3>
              <div className={styles.locations}>
                <div className={styles["details"]}>
                  <p>Latitude:</p>
                  <span>{`${data.lat}`}</span>
                </div>
                <div className={styles["details"]}>
                  <p>Longitude:</p>
                  <span>{`${data.lon}`}</span>
                </div>
              </div>
              <Map defaultLocation={generateCoordinates()} />
            </>
          )}
        </div>
      </Card>
    </>
  );
}

export default ProfilePage;

import styles from "./CheckBoxSimple.module.css";

function CheckBoxSimple({ label, checked, onChange, name, disabled, note }) {
  return (
    <div className={styles.checkboxContainer}>
      <input
        name={name}
        className={styles.checkbox}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      ></input>
      <label>
        <div className={styles.checkboxLabel}>
          <div>{label}</div>
          <div className={styles.note}>{note}</div>
        </div>
      </label>
    </div>
  );
}

export default CheckBoxSimple;

import React, { useState, useEffect, useRef } from "react";
import styles from "./SharedJobApplicants.module.css";
import { useContext } from "react";
import Table from "../UI/Table/Table";
import Header from "../UI/Table/Header";
import Pagination from "../UI/Pagination/Pagination";
import Card from "../UI/Card/Card";
import { Link } from "react-router-dom";
import SearchBar from "../UI/SearchBar/SearchBar";
import ErrorPage from "../ErrorPage";
import { useDataFetching } from "../../hooks/use-datafetching";
import ModalContext from "../context/modal-context";
import * as Hi2Icons from "react-icons/hi2";
import JobApplicantsDetailPage from "../AVA/Intranet/JobApplicants/JopApplicantsDetailPage";

function SharedJobApplicantsPage() {
  const modalCtx = useContext(ModalContext);
  const URLsuffix = "ava/jobapplicants";

  //* Fetch with sort and search params

  const [currentPage, setCurrentPage] = useState(1);
  const [sortParam, setSortParam] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const kindParam = "&status=published";

  const { error, pageData, getTableData } = useDataFetching(
    currentPage,
    URLsuffix,
    sortParam,
    searchParam,
    kindParam
  );

  const [isDetails, setIsDetails] = useState(false);

  const [applicantId, setApplicantId] = useState(null);

  //* Search

  const searchRef = useRef(null);

  const handleSearch = (event) => {
    event.preventDefault();
    const searchValue = searchRef.current.value;
    setSearchParam(`&search=${searchValue}`);
  };

  const clearSearchHandler = () => {
    setSearchParam("");
  };

  useEffect(() => {
    setCurrentPage(1);
    getTableData();
  }, [searchParam]);

  //* Sorting

  const sortApplicants = (accessor, order) => {
    setSortParam(`&sort=${accessor}&order=${order}`);
  };

  useEffect(() => {
    getTableData();
  }, [sortParam]);

  //* Handlers

  const detailsHandler = (id) => {
    setApplicantId(id);
    setIsDetails(true);
    modalCtx.showModal();
  };

  //* Table header

  const columns = [
    {
      Header: (
        <Header
          sortHandler={sortApplicants}
          title="First name"
          id="firstName"
        />
      ),
      accessor: "firstName",
      width: "33%",

      Cell: ({ value, row }) => (
        <Link
          className={styles.name}
          onClick={() => detailsHandler(row.original.id)}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: (
        <Header sortHandler={sortApplicants} title="Last name" id="lastName" />
      ),
      accessor: "lastName",
      width: "33%",

      Cell: ({ value, row }) => (
        <Link
          className={styles.name}
          onClick={() => detailsHandler(row.original.id)}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: <Header sortHandler={sortApplicants} title="Role" id="role" />,
      accessor: "role",
      width: "33%",
    },
    {
      Header: "Info",
      accessor: "id",
      id: "info",
      width: "50px",

      Cell: ({ row, value }) => (
        <Hi2Icons.HiOutlineInformationCircle
          className={styles["details-icon"]}
          onClick={() => detailsHandler(row.original.id)}
        />
      ),
    },
  ];

  return (
    <>
      {modalCtx.isModal && isDetails && (
        <JobApplicantsDetailPage hide="true" applicantId={applicantId} />
      )}
      <div className={styles.title}>
        <h1>Job Applicants</h1>
      </div>
      <div className={styles.control}>
        <p>You'll find the list of the available job applicants here.</p>

        <SearchBar
          searchRef={searchRef}
          onSubmit={handleSearch}
          clearSearch={clearSearchHandler}
        />
      </div>
      <Card className={styles.card}>
        {error ? (
          <ErrorPage
            error={error}
            title={error.title}
            message={error.message}
          />
        ) : (
          <>
            <Table
              columns={columns}
              data={pageData.rowData}
              isLoading={pageData.isLoading}
            />
            <Pagination
              totalRows={pageData.totalRecords}
              pageChangeHandler={setCurrentPage}
              rowsPerPage={20}
              currentPage={currentPage}
            />
          </>
        )}
      </Card>
    </>
  );
}

export default SharedJobApplicantsPage;

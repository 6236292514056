import React, { useState, useEffect } from "react";
import styles from "./PushNotificationListPage.module.css";
import { useContext } from "react";
import Table from "../../UI/Table/Table";
import Header from "../../UI/Table/Header";
import Pagination from "../../UI/Pagination/Pagination";
import Card from "../../UI/Card/Card";
import Button from "../../UI/Button/Button";
import { useDataFetching } from "../../../hooks/use-datafetching";
import ErrorPage from "../../ErrorPage";
import ModalContext from "../../context/modal-context";
import EmptyTable from "../../UI/Table/EmptyTable";
import moment from "moment";
import SendPushNotification from "./SendPushNotification";

function PushNotificationListPage() {
  const URLsuffix = "ava/push";
  const modalCtx = useContext(ModalContext);

  //* Fetch with sort and search params

  const [currentPage, setCurrentPage] = useState(1);
  const [sortParam, setSortParam] = useState("");
  const [searchParam, setSearchParam] = useState("");

  const { error, pageData, getTableData } = useDataFetching(
    currentPage,
    URLsuffix,
    sortParam,
    searchParam
  );

  const [modalType, setModalType] = useState("");

  //* Handlers

  const createHandler = () => {
    setModalType("new");
    modalCtx.showModal();
  };

  //* Sorting

  const sortCurrencies = (accessor, order) => {
    setSortParam(`&sort=${accessor}&order=${order}`);
  };

  useEffect(() => {
    getTableData();
  }, [sortParam]);

  //* Table header

  const columns = [
    {
      Header: <Header sortHandler={sortCurrencies} title="Title" id="title" />,
      accessor: "title",
      width: "50%",
    },
    {
      Header: <Header sortHandler={sortCurrencies} title="Date" id="date" />,
      accessor: "date",
      width: "50%",
      Cell: ({ value }) => {
        return moment(value).format("MM/DD/YYYY HH:mm");
      },
    },
  ];

  return (
    <>
      {modalCtx.isModal && modalType === "new" && (
        <SendPushNotification refresh={getTableData} />
      )}

      <div className={styles.title}>
        <h1>Sent notifications</h1>
        <p>List of all sent push notifications</p>
      </div>
      <div className={styles.control}>
        <div className={styles["control-buttons"]}>
          <Button className={styles["create-button"]} onClick={createHandler}>
            Send new
          </Button>
        </div>
      </div>
      <Card className={styles.card}>
        {error ? (
          <ErrorPage
            error={error}
            title={error.title}
            message={error.message}
          />
        ) : (
          <>
            <Table
              columns={columns}
              data={pageData.rowData}
              isLoading={pageData.isLoading}
            />
            {pageData.rowData.length === 0 && <EmptyTable />}

            <div className={styles.pagination}>
              <Pagination
                totalRows={pageData.totalRecords}
                pageChangeHandler={setCurrentPage}
                rowsPerPage={20}
                currentPage={currentPage}
              />
            </div>
          </>
        )}
      </Card>
    </>
  );
}

export default PushNotificationListPage;

import React, { useState, useEffect, useRef } from "react";
import styles from "./SharedPartnersPage.module.css";
import Table from "../../components/UI/Table/Table";
import Header from "../../components/UI/Table/Header";
import Pagination from "../../components/UI/Pagination/Pagination";
import Card from "../../components/UI/Card/Card";
import { Link, useNavigate } from "react-router-dom";
import SearchBar from "../../components/UI/SearchBar/SearchBar";
import ErrorPage from "../../components/ErrorPage";
import { useDataFetching } from "../../hooks/use-datafetching";
import * as Hi2Icons from "react-icons/hi2";
import Tooltip from "../UI/Tooltip/Tooltip";

function SharedPartnersPage(props) {
  const URLsuffix = "intranet/partners";
  const kindParam = `&kind=${props.kind}`;

  const navigate = useNavigate();

  //* Fetch with sort and search params

  const [currentPage, setCurrentPage] = useState(1);
  const [sortParam, setSortParam] = useState("");
  const [searchParam, setSearchParam] = useState("");

  const { error, pageData, getTableData } = useDataFetching(
    currentPage,
    URLsuffix,
    sortParam,
    searchParam,
    kindParam
  );

  //* Search

  const searchRef = useRef(null);

  const handleSearch = (event) => {
    event.preventDefault();
    const searchValue = searchRef.current.value;
    setSearchParam(`&search=${searchValue}`);
  };

  const clearSearchHandler = () => {
    setSearchParam("");
  };

  useEffect(() => {
    setCurrentPage(1);
    getTableData();
  }, [searchParam]);

  //* Sorting

  const sortPartner = (accessor, order) => {
    setSortParam(`&sort=${accessor}&order=${order}`);
  };

  useEffect(() => {
    getTableData();
  }, [sortParam]);

  //* Handlers

  const detailsHandler = (id) => {
    navigate(`${id}`);
  };

  //* Table header
  const columns = [
    {
      Header: <Header sortHandler={sortPartner} title="Name" id="name" />,
      accessor: "name",
      width: "100%",

      Cell: ({ value, row }) => (
        <Link className={styles.name} to={`${row.original.id}`}>
          {value}
        </Link>
      ),
    },
    {
      Header: "Info",
      accessor: "id",
      id: "info",
      width: "50px",

      Cell: ({ row, value }) => (
        <Tooltip text="Open details">
          <Hi2Icons.HiOutlineInformationCircle
            className={styles["details-icon"]}
            onClick={() => detailsHandler(row.original.id)}
          />
        </Tooltip>
      ),
    },
  ];

  const title = props.kind.charAt(0).toUpperCase() + props.kind.slice(1) + "s";

  return (
    <>
      <div className={styles.title}>
        <h1>{title}</h1>
      </div>
      <div className={styles.control}>
        <p>The complete list of the AV Alliance {props.kind}s.</p>
        <SearchBar
          searchRef={searchRef}
          onSubmit={handleSearch}
          clearSearch={clearSearchHandler}
        />
      </div>
      <Card className={styles.card}>
        {error ? (
          <ErrorPage
            error={error}
            title={error.title}
            message={error.message}
          />
        ) : (
          <>
            <Table
              columns={columns}
              data={pageData.rowData}
              isLoading={pageData.isLoading}
            />
            <Pagination
              totalRows={pageData.totalRecords}
              pageChangeHandler={setCurrentPage}
              rowsPerPage={20}
              currentPage={currentPage}
            />
          </>
        )}
      </Card>
    </>
  );
}

export default SharedPartnersPage;

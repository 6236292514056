import React, { useEffect, useState } from "react";
import Modal from "../../UI/Modal/Modal";
import { useContext } from "react";
import FormDataContext from "../../context/formData-context";
import styles from "./InviteUser.module.css";
import Button from "../../UI/Button/Button";
import ModalContext from "../../context/modal-context";
import { getAuthToken } from "../../util/auth";
import { Form } from "react-router-dom";
import Input from "../../UI/Input/Input";
import * as Hi2Icons from "react-icons/hi2";
import MultiSelect from "../../UI/Input/MultiSelect";

function InviteUser(props) {
  const dataCtx = useContext(FormDataContext);
  const modalCtx = useContext(ModalContext);

  const method = props.method;
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  const initialValues = {
    email: "",
    partnerIds: null,
  };

  useEffect(() => {
    dataCtx.editFromData(initialValues);
  }, []);

  const options = props.options;

  const [error, setError] = useState(null);

  //   const [isLoading, setIsLoading] = useState(true);

  let requestData = {
    url: process.env.REACT_APP_API_URL + `ava/invitations`,
    method: method,
    headers: headers,
  };

  if (props.type === "partner") {
    requestData = {
      url:
        process.env.REACT_APP_API_URL +
        `intranet/${props.partnerId}/invitations`,
      method: method,
      headers: headers,
    };
  }

  useEffect(() => {
    dataCtx.editRequestData(requestData);
  }, [props.partnerId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      if (isTouched) {
        setIsTouched(false);
      }
    }
    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    return regex.test(email);
  };

  const [isTouched, setIsTouched] = useState(false);

  const handleEmailBlur = () => {
    setIsTouched(true);
    handleInputChange({
      target: {
        name: "email",
        value: dataCtx.formData.email,
      },
    });
  };

  const isEmailValid =
    !isTouched ||
    dataCtx.formData.email.trim() === "" ||
    validateEmail(dataCtx.formData.email);

  const handleMultiSelectChange = (selectedOptions, fieldName) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      [fieldName]: selectedValues,
    }));
  };

  const submitHandler = async () => {
    try {
      await dataCtx.fetchData();
      props.refresh();
      modalCtx.showModal();
    } catch (error) {
      setError(error.message);
    }
  };

  const closeHandler = () => {
    dataCtx.editFromData({});
    modalCtx.showModal();
  };

  return (
    <Modal>
      <div className={styles.container}>
        <Form>
          <div className={styles.title}>
            <h2>Invite user</h2>
            <div className={styles["close-container"]}>
              <Hi2Icons.HiOutlineXMark
                className={styles["close-button"]}
                onClick={closeHandler}
              />
            </div>
          </div>
          <div className={styles.content}>
            <Input
              label="Email"
              type="email"
              id="email"
              name="email"
              value={dataCtx.formData.email || ""}
              onChange={handleInputChange}
              onBlur={handleEmailBlur}
            />
            {!isEmailValid && (
              <p className={styles.error}>Please enter a valid email.</p>
            )}
            {error === "409" && (
              <p className={styles.error}>This email is already in use!</p>
            )}

            {props.type === "AVA" && (
              <MultiSelect
                id="partnerIds"
                name="partnerIds"
                label="Partners"
                menuPlacement="bottom"
                options={options}
                value={dataCtx.formData.partnerIds}
                onMultiChange={handleMultiSelectChange}
              />
            )}
            <div className={styles["buttons"]}>
              <Button
                type="submit"
                className={styles["submit-button"]}
                onClick={submitHandler}
                disabled={!isEmailValid}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default InviteUser;

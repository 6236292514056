import styles from "./SharedPartnerData.module.css";
import * as FiIcons from "react-icons/fi";
import * as TfiIcons from "react-icons/tfi";
import { Link } from "react-router-dom";
import { getMyRoles } from "../util/auth";

function SharedPartnerData(props) {
  const myRoles = getMyRoles();

  const Services = () => {
    const servicesNames = props.data.services.map((id) => {
      const dataItem = props.services.find((item) => item.value === id);
      return dataItem ? dataItem.label : "";
    });

    if (servicesNames.length === 0) {
      return null;
    }

    return (
      <div className={styles.services}>
        <h2>Services</h2>
        <ul>
          {servicesNames.map((name, index) => (
            <li key={index}>{name}</li>
          ))}
        </ul>
      </div>
    );
  };

  const socialIconsData = [
    props.data.facebook,
    props.data.twitter,
    props.data.linkedIn,
    props.data.instagram,
    props.data.youTube,
    props.data.vimeo,
  ];

  const socialMediaLinks = socialIconsData.filter((item) => item !== undefined);

  const sourceLink = (link) => {
    if (!link) {
      return null;
    }
    if (!link.startsWith("http://") && !link.startsWith("https://")) {
      link = "http://" + link;
    }

    return link;
  };

  return (
    <div className={styles.container}>
      <p>{props.data.slogan}</p>
      <p className={styles.aboutUs}>{props.data.aboutUs}</p>
      {props.data.phone && (
        <p>
          Phone: <a href={`tel:${props.data.phone}`}>{props.data.phone}</a>
        </p>
      )}
      {props.data.email && (
        <p>
          Email: <a href={`mailto:${props.data.email}`}>{props.data.email}</a>
        </p>
      )}
      {props.data.web && (
        <p>
          Web:{" "}
          <a
            href={sourceLink(props.data.web)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.data.web}
          </a>
        </p>
      )}
      <Services />
      {socialMediaLinks.length > 0 && (
        <div>
          <h2 className={styles["social-title"]}>Social media links</h2>
          <div className={styles["social-icons"]}>
            {props.data.facebook && (
              <Link
                className={styles["social-icon"]}
                to={sourceLink(props.data.facebook)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiIcons.FiFacebook />
              </Link>
            )}
            {props.data.twitter && (
              <Link
                className={styles["social-icon"]}
                to={sourceLink(props.data.twitter)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiIcons.FiTwitter />
              </Link>
            )}
            {props.data.linkedIn && (
              <Link
                className={styles["social-icon"]}
                to={sourceLink(props.data.linkedIn)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiIcons.FiLinkedin />
              </Link>
            )}
            {props.data.instagram && (
              <Link
                className={styles["social-icon"]}
                to={sourceLink(props.data.instagram)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiIcons.FiInstagram />
              </Link>
            )}
            {props.data.youTube && (
              <Link
                className={styles["social-icon"]}
                to={sourceLink(props.data.youTube)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiIcons.FiYoutube />
              </Link>
            )}
            {props.data.vimeo && (
              <Link
                className={styles["social-icon"]}
                to={sourceLink(props.data.vimeo)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TfiIcons.TfiVimeo />
              </Link>
            )}
          </div>
          {props.data.managerInfo &&
            myRoles &&
            (myRoles.includes("root") || myRoles.includes("ava-manager")) && (
              <div>
                <h2>Info for managers</h2>
                <p>{props.data.managerInfo}</p>
              </div>
            )}
        </div>
      )}
    </div>
  );
}

export default SharedPartnerData;

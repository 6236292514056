import { useState, useEffect, useContext } from "react";
import styles from "./PartnerDetailUpdate.module.css";
import Card from "../../../UI/Card/Card";
import { Form, useLoaderData, useNavigate } from "react-router-dom";
import TextArea from "../../../UI/Input/TextArea";
import PhoneInputField from "../../../UI/Input/PhoneInputField";
import Input from "../../../UI/Input/Input";
import MultiSelect from "../../../UI/Input/MultiSelect";
import Button from "../../../UI/Button/Button";
import FormDataContext from "../../../context/formData-context";
import { getAuthToken, getMyRoles } from "../../../util/auth";

function PartnerDetailUpdatePage() {
  const data = useLoaderData();
  const token = getAuthToken();
  const myRoles = getMyRoles();

  const navigation = useNavigate();

  const [showManagerInfo, setShowManagerInfo] = useState(false);

  const dataCtx = useContext(FormDataContext);

  useEffect(() => {
    dataCtx.editFromData({});
  }, []);

  useEffect(() => {
    const URLsuffix = data.partnerDetails.id;

    const requestData = {
      url:
        process.env.REACT_APP_API_URL +
        "intranet/partners/" +
        URLsuffix +
        "/info",
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
    };
    const initialValues = {
      slogan: data.partnerDetails.slogan,
      aboutUs: data.partnerDetails.aboutUs,
      managerInfo: data.partnerDetails.managerInfo,
      phone: data.partnerDetails.phone,
      email: data.partnerDetails.email,
      facebook: data.partnerDetails.facebook,
      twitter: data.partnerDetails.twitter,
      instagram: data.partnerDetails.instagram,
      linkedIn: data.partnerDetails.linkedIn,
      youTube: data.partnerDetails.youTube,
      vimeo: data.partnerDetails.vimeo,
      services: data.partnerDetails.services,
      web: data.partnerDetails.web,
    };

    dataCtx.editFromData(initialValues);
    dataCtx.editRequestData(requestData);
  }, [data.partnerDetails]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      if (isTouched) {
        setIsTouched(false);
      }
    }

    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: value,
    });
  };

  const handleMultiSelectChange = (selectedOptions, fieldName) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      [fieldName]: selectedValues,
    }));
  };

  const defaultServicesValues = data.partnerDetails.services.map((value) => {
    return data.servicesOptions.find((option) => option.value === value);
  });

  const handlePhoneChange = (value) => {
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      phone: value,
    }));
  };

  const validateEmail = (email) => {
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    return regex.test(email);
  };

  const [isTouched, setIsTouched] = useState(false);

  const handleEmailBlur = () => {
    setIsTouched(true);
    handleInputChange({
      target: {
        name: "email",
        value: dataCtx.formData.email,
      },
    });
  };

  const isEmailValid =
    !isTouched ||
    !dataCtx.formData ||
    dataCtx.formData.email.trim() === "" ||
    validateEmail(dataCtx.formData.email);

  const submitHandler = async (event) => {
    event.preventDefault();
    await dataCtx.fetchData();

    navigation(-1);
  };

  useEffect(() => {
    if (myRoles.includes("root") || myRoles.includes("ava-manager")) {
      setShowManagerInfo(true);
    }
  }, [myRoles]);

  return (
    <Card className={styles.card}>
      <h1 className={styles.title}>Edit member</h1>
      <h3 className={styles.h3}>{data.partnerDetails.name} info</h3>
      <Form>
        <TextArea
          label="Slogan"
          id="slogan"
          name="slogan"
          rows={2}
          value={dataCtx.formData.slogan}
          onChange={handleInputChange}
        />
        <TextArea
          label="About Us"
          id="aboutUs"
          name="aboutUs"
          rows={15}
          value={dataCtx.formData.aboutUs}
          onChange={handleInputChange}
        />
        {showManagerInfo && (
          <TextArea
            label="Info"
            id="managerInfo"
            name="managerInfo"
            note="(Only visible to AV Alliance managers)"
            rows={5}
            value={dataCtx.formData.managerInfo}
            onChange={handleInputChange}
          />
        )}
        <h3 className={styles.h3}>Business details</h3>
        <PhoneInputField
          id="phone"
          name="phone"
          label="Phone number"
          title="phone"
          startValue={data.partnerDetails.phone}
          value={dataCtx.formData.phone}
          onChange={handlePhoneChange}
        />
        <Input
          type="email"
          label="Email address"
          id="email"
          name="email"
          className={styles.emailInput}
          value={dataCtx.formData.email || ""}
          onChange={handleInputChange}
          onBlur={handleEmailBlur}
        />
        {!isEmailValid && (
          <p className={styles.error}>Please enter a valid email.</p>
        )}
        <Input
          label="Web"
          id="web"
          name="web"
          value={dataCtx.formData.web || ""}
          onChange={handleInputChange}
        />
        <h3 className={styles.h3}>Social media</h3>
        <Input
          label="Facebook"
          id="facebook"
          name="facebook"
          value={dataCtx.formData.facebook || ""}
          onChange={handleInputChange}
        />
        <Input
          label="Twitter"
          id="twitter"
          name="twitter"
          value={dataCtx.formData.twitter || ""}
          onChange={handleInputChange}
        />
        <Input
          label="Instagram"
          id="instagram"
          name="instagram"
          value={dataCtx.formData.instagram || ""}
          onChange={handleInputChange}
        />
        <Input
          label="LinkedIn"
          id="linkedIn"
          name="linkedIn"
          value={dataCtx.formData.linkedIn || ""}
          onChange={handleInputChange}
        />
        <Input
          label="YouTube"
          id="youTube"
          name="youTube"
          value={dataCtx.formData.youTube || ""}
          onChange={handleInputChange}
        />
        <Input
          label="Vimeo"
          id="vimeo"
          name="vimeo"
          value={dataCtx.formData.vimeo || ""}
          onChange={handleInputChange}
        />
        <h3 className={styles.h3}>Other details</h3>
        <MultiSelect
          id="services"
          label="Services"
          name="services"
          menuPlacement="auto"
          options={data.servicesOptions}
          defaultValue={defaultServicesValues}
          value={dataCtx.formData.services}
          onMultiChange={handleMultiSelectChange}
        />
        <div className={styles["submit-button"]}>
          <Button type="submit" onClick={submitHandler}>
            Save
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export default PartnerDetailUpdatePage;

import { useState } from "react";
import styles from "./SearchBar.module.css";
import * as Hi2Icons from "react-icons/hi2";

function SearchBar(props) {
  const [searchValue, setSearchValue] = useState("");

  const handleClear = () => {
    props.searchRef.current.value = "";
    setSearchValue("");
    props.clearSearch();
  };

  const handleOptions = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <form
        className={`${styles.container}  ${props.className}`}
        onSubmit={props.onSubmit}
      >
        <button className={styles["search-button"]}>
          <Hi2Icons.HiOutlineMagnifyingGlass />
        </button>
        <input
          name="serach"
          id="search"
          className={styles.searchbar}
          type="text"
          placeholder="Search..."
          ref={props.searchRef}
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        />
        {searchValue && (
          <button className={styles["clear-button"]} onClick={handleClear}>
            <Hi2Icons.HiOutlineXMark />
          </button>
        )}
        <button className={styles["options-button"]} onClick={handleOptions}>
          <Hi2Icons.HiOutlineFunnel />
        </button>
      </form>
    </>
  );
}

export default SearchBar;

import { useState } from "react";
import styles from "./Password.module.css";
import * as Hi2Icons from "react-icons/hi2";

function Password(props) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const classes = `${styles.input} ${props.className}`;
  return (
    <div className={classes}>
      <label className={`${styles.label} ${props.id}`} htmlFor={props.id}>
        <div>{props.label}</div>
        <div className={styles.note}>{props.note}</div>
      </label>
      <div className={styles["input-wrapper"]}>
        <input
          className={styles.field}
          type={showPassword ? "text" : "password"}
          id={props.id}
          name={props.name}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          autoFocus={props.autoFocus}
          required={props.required}
        />
        <div
          className={styles["password-icon"]}
          onClick={togglePasswordVisibility}
        >
          {showPassword ? (
            <Hi2Icons.HiOutlineEyeSlash />
          ) : (
            <Hi2Icons.HiOutlineEye />
          )}
        </div>
      </div>
    </div>
  );
}

export default Password;

import styles from "./Footer.module.css";
import * as Hi2Icons from "react-icons/hi2";

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.link}>
        <a href="mailto:support@binarybirds.com">
          <div>
            <Hi2Icons.HiOutlineEnvelope className={styles.icon} size={20} />
          </div>
          <div>Technical support</div>
        </a>
      </div>
      <a
        href="https://feathercms.com"
        rel="noreferrer noopener"
        target="_blank"
      >
        <img src="/login-logo.png" alt="Feather CMS logo" height="25"></img>
      </a>
    </div>
  );
}

export default Footer;

import GridItem from "./SharedGridItem";
import styles from "./SharedGridBrowser.module.css";

function SharedGridBrowser(props) {
  return (
    <>
      <div className={styles.gridContainer}>
        {props.data.map((item) => (
          <GridItem
            key={item.id}
            item={item}
            clickHandler={props.clickHandler}
          />
        ))}
      </div>
    </>
  );
}
export default SharedGridBrowser;

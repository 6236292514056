import React, { useState, useEffect } from "react";
import Table from "../UI/Table/Table";
import Card from "../UI/Card/Card";
import { useBrowserFetching } from "../../hooks/use-browserfetching";
import ErrorPage from "../ErrorPage";
import * as Hi2Icons from "react-icons/hi2";
import * as LiaIcons from "react-icons/lia";
import styles from "./AVAInspectBrowser.module.css";
import { Link } from "react-router-dom";
import Lightbox from "../UI/LightBox";
import EmptyTable from "../UI/Table/EmptyTable";
import SingleSelectNew from "../UI/Input/SingleSelectNew";
import SegmentedSwitch from "../UI/Switch/SegmentedSwitch";
import SharedGridBrowser from "./SharedGridBrowser";

const AVAInspectBrowser = (props) => {
  const partnerOptions = props.partners;
  const [images, setImages] = useState([]);
  const [currentPartnerId, setCurrentPartnerId] = useState("");

  //* Fetch with sort and search params
  const [URLsuffix, setURLsuffix] = useState(null);

  const { error, setError, pageData, getTableData } =
    useBrowserFetching(URLsuffix);

  const [pictureRowData, setPictureRowData] = useState([]);

  const [isGridView, setIsGridView] = useState(false);

  const [path, setPath] = useState([{ id: "", name: "Home" }]);

  //* Handlers

  const downloadFile = (fileURL) => {
    const link = document.createElement("a");
    link.href = fileURL;
    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
  };

  const clickHandler = ({ dirId, dirName, kind, ext, index }) => {
    if (kind === "directory") {
      setURLsuffix(`ava/documents/` + dirId);
      setPath((prevPath) => [...prevPath, { id: dirId, name: dirName }]);
    } else if (kind === "back") {
      if (path.length <= 2) {
        setURLsuffix(`ava/documents/shared-documents/${currentPartnerId}`);
        setPath([{ id: "", name: "Home" }]);
      } else {
        const prevDirId = path[path.length - 2].id;
        setURLsuffix(`ava/documents/` + prevDirId);
        setPath((prevPath) => prevPath.slice(0, prevPath.length - 1));
      }
    } else if (
      kind === "document" &&
      ext !== "png" &&
      ext !== "jpg" &&
      ext !== "jpeg" &&
      ext !== "PNG" &&
      ext !== "JPG" &&
      ext !== "JPEG"
    ) {
      const fileURL =
        process.env.REACT_APP_API_URL + "file/documents/download/" + dirId;
      downloadFile(fileURL);
    } else if (
      ext === "png" ||
      ext === "jpg" ||
      ext === "jpeg" ||
      ext === "PNG" ||
      ext === "JPG" ||
      ext === "JPEG"
    ) {
      setCurrentIndex(index);
      setOpen(true);
    }
  };

  useEffect(() => {
    setError(null);
    getTableData();
  }, [URLsuffix]);

  const downloadPicture = () => {
    const pictureURL = images[currentImageIndex].src;
    downloadFile(pictureURL);
  };

  const handleSingleSelectChange = (selectedOption, fieldName) => {
    const selectedValue = selectedOption.value;
    setCurrentPartnerId(selectedValue);
    setURLsuffix(`ava/documents/shared-documents/${selectedValue}`);
  };

  //* Icons

  const MyFileIcon = ({ kind, ext }) => {
    if (kind === "back") {
      return (
        <LiaIcons.LiaLevelUpAltSolid className={styles["browser-up-icon"]} />
      );
    } else if (kind === "directory") {
      return <Hi2Icons.HiOutlineFolder className={styles["browser-icons"]} />;
    } else if (ext === "jpg" || ext === "png" || ext === "JPG") {
      return <Hi2Icons.HiOutlinePhoto className={styles["browser-icons"]} />;
    } else if (
      ext === "avi" ||
      ext === "mov" ||
      ext === "mp4" ||
      ext === "mkv"
    ) {
      return <Hi2Icons.HiOutlineFilm className={styles["browser-icons"]} />;
    } else {
      return <Hi2Icons.HiOutlineDocument className={styles["browser-icons"]} />;
    }
  };

  useEffect(() => {
    let updatedRowData = pageData.rowData.slice();

    if (URLsuffix !== `ava/documents/shared-documents/${currentPartnerId}`) {
      updatedRowData = [
        {
          id: "",
          name: "..",
          kind: "back",
        },
        ...pageData.rowData,
      ];
    }

    let currentIndex = 0;
    const indexedData = updatedRowData.map((data) => {
      if (["jpg", "jpeg", "png"].includes(data.ext?.toLowerCase())) {
        return { ...data, index: currentIndex++ };
      }
      return { ...data, index: undefined };
    });

    setPictureRowData(indexedData);
  }, [URLsuffix, pageData.rowData]);

  //* Lightbox

  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const currentImages = [];
    pageData.rowData.forEach((data) => {
      if (["jpg", "jpeg", "png"].includes(data.ext?.toLowerCase())) {
        currentImages.push({
          src:
            process.env.REACT_APP_API_URL +
            "file/documents/download/" +
            data.id,
          loading: "lazy",
          alt: data.name,
        });
      }
    });
    setImages(currentImages);
  }, [pageData.rowData]);

  //* Navigation

  const Navigation = () => {
    const navigationClickHandler = (dirId) => {
      setURLsuffix(
        `ava/documents/shared-documents/${currentPartnerId}` + dirId
      );
      setPath((prevPath) =>
        prevPath.slice(0, prevPath.findIndex((dir) => dir.id === dirId) + 1)
      );
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 800);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    console.log(isMobile);

    return (
      <Card className={styles["navigation-container"]}>
        <div className={styles.head}>
          <div className={styles.navbreadcrumbs}>
            {path.map((dir, index) => (
              <li
                key={dir.id}
                className={styles.crumb}
                onClick={() => navigationClickHandler(dir.id)}
              >
                <Hi2Icons.HiOutlineFolderOpen
                  className={styles["navigation-icons"]}
                />
                {isMobile && index !== path.length - 1 ? "..." : dir.name}
              </li>
            ))}
          </div>
        </div>
      </Card>
    );
  };

  //* Table header

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      width: "80%",
      Cell: ({ value, row }) => (
        <Link
          className={styles.name}
          onClick={() =>
            clickHandler({
              dirId: row.original.id,
              dirName: row.original.name,
              kind: row.original.kind,
              ext: row.original.ext,
              index: row.original.index,
            })
          }
        >
          <MyFileIcon kind={row.original.kind} ext={row.original.ext} />
          {value}
        </Link>
      ),
    },
    {
      Header: "Shared by",
      accessor: "partnerName",
      width: "20%",
      Cell: ({ value }) => {
        if (!value) {
          return "AV Alliance";
        } else {
          return value;
        }
      },
    },
  ];

  const handleToggle = (isOn) => {
    if (isOn) {
      setIsGridView(true);
    } else {
      setIsGridView(false);
    }
  };

  return (
    <>
      {images.length > 0 && (
        <Lightbox
          currentImageIndex={currentImageIndex}
          setCurrentIndex={setCurrentIndex}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          images={images}
          galleryTitle={path[path.length - 1]?.name}
          download={downloadPicture}
          pageTransitionConfig={{
            from: { transform: "scale(0.75)", opacity: 0 },
            enter: { transform: "scale(1)", opacity: 1 },
            leave: { transform: "scale(0.75)", opacity: 0 },
            config: { mass: 1, tension: 320, friction: 32 },
          }}
        />
      )}
      <div className={styles.title}>
        <h1>Documents</h1>
        <p>Documents shared with you by other members.</p>
        <div className={styles.navigation}>
          <Navigation />
          <div className={styles.gridControl}>
            <SegmentedSwitch onToggle={handleToggle} />
          </div>
        </div>
        <SingleSelectNew
          className={styles.select}
          id="partner"
          name="paertner"
          options={partnerOptions}
          // value={dataCtx.formData.kind}
          onSingleChange={handleSingleSelectChange}
        />
      </div>
      {currentPartnerId ? (
        <Card className={styles.card}>
          {error ? (
            <ErrorPage
              error={error}
              title={error.title}
              message={error.message}
            />
          ) : (
            <>
              {isGridView ? (
                <SharedGridBrowser
                  data={pictureRowData}
                  clickHandler={clickHandler}
                />
              ) : (
                <Table
                  columns={columns}
                  data={pictureRowData}
                  isLoading={pageData.isLoading}
                />
              )}
              {pictureRowData.length === 0 && <EmptyTable />}
            </>
          )}
        </Card>
      ) : (
        <Card>
          <p className={styles.message}>First, select a partner above.</p>
        </Card>
      )}
    </>
  );
};

export default AVAInspectBrowser;

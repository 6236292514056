import styles from "./Input.module.css";

function Input(props) {
  const classes = `${styles.input} ${props.className}`;
  return (
    <div className={classes}>
      <label className={`${styles.label} ${props.id}`} htmlFor={props.id}>
        <div>{props.label}</div>
        <div className={styles.note}>{props.note}</div>
      </label>
      <input
        className={styles.field}
        type={props.type}
        min={props.min}
        max={props.max}
        id={props.id}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        autoFocus={props.autoFocus}
        required={props.required}
        placeholder={props.placeholder}
        {...(props.type !== "email" && { "data-1p-ignore": true })}
      />
    </div>
  );
}

export default Input;

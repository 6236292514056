import { getAuthToken } from "./auth";
import { fetchRequest } from "./fetchRequest";

export const formatRowData = (rawData) =>
  rawData.map((info) => {
    const formattedData = {
      id: info.id,
      name: info.name,
      kind: info.kind,
      ext: info.ext,
      parentId: info.parentId,
    };
    if (info.partner) {
      formattedData.partnerName = info.partner.name;
    }
    return formattedData;
  });

export const getData = async (URLsuffix) => {
  const token = getAuthToken();
  const URL = process.env.REACT_APP_API_URL + URLsuffix + `?page=1&size=1000`;

  const method = "GET";
  const headers = { Authorization: `Bearer ${token}` };
  try {
    const data = await fetchRequest(URL, method, headers, undefined);
    return data;
  } catch (error) {
    throw error;
  }
};

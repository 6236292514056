import ServicesPage from "../../../components/AVA/Intranet/Services/ServicesPage";

function Services() {
  return (
    <>
      <ServicesPage />
    </>
  );
}

export default Services;

import { redirect, useActionData } from "react-router-dom";
import { fetchDataRequest } from "../components/util/fetchDataRequest";
import RegisterNewUser from "../components/RegisterNewUser";

function Register() {
  const errors = useActionData();

  return <RegisterNewUser error={errors} />;
}

export default Register;

export async function action({ request, params }) {
  try {
    const data = await request.formData();
    const authData = {
      invitation: params.token,
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      email: data.get("email"),
      password: data.get("password"),
    };

    const URL = process.env.REACT_APP_API_URL + "user/auth/register";

    const method = "POST";
    const headers = {
      "Content-type": "application/json",
      Accept: "application/json",
    };

    await fetchDataRequest(URL, method, headers, authData);

    return redirect("/login");
  } catch (error) {
    const errorMessage = "Something went wrong. Please contact support.";
    return errorMessage;
  }
}

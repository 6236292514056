import { Form, useNavigation, Link } from "react-router-dom";

import Button from "./UI/Button/Button";
import Card from "./UI/Card/Card";
import Footer from "./UI/Footer/Footer";
import Input from "./UI/Input/Input";
import styles from "./LoginForm.module.css";
import useInput from "../hooks/use-input";
import Password from "./UI/Input/Password";

function LoginFrom(props) {
  const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

  const loginError = props.error;

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput((value) => regex.test(value));

  const {
    value: enteredPassword,
    isValid: enteredPasswordIsValid,
    valueChangeHandler: passwordChangedHandler,
    inputBlurHandler: passwordBlurHandler,
  } = useInput((value) => value.trim().length > 0);

  let formIsValid = false;

  if (enteredPasswordIsValid && enteredEmailIsValid) {
    formIsValid = true;
  }

  const emailInputClasses = emailInputHasError ? styles.invalid : null;

  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  return (
    <>
      <picture>
        <source
          srcSet="/site-logo_dark.png"
          media="(prefers-color-scheme:dark)"
        />
        <img
          className={styles.img}
          src={`/site-logo_light.png`}
          alt="AV Alliance logo"
          height="25"
        />
      </picture>
      <h2 className={styles.h2}>Intranet</h2>
      <Card className={styles.login}>
        <Form method="post" action="/login">
          <Input
            type="email"
            id="emailaddress"
            label="Email address"
            name="email"
            class={emailInputClasses}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            value={enteredEmail}
            required
          />
          {emailInputHasError && (
            <p className={styles.error}>Please enter a valid email.</p>
          )}
          <Password
            className={styles["login-password"]}
            id="password"
            label="Password"
            name="password"
            onChange={passwordChangedHandler}
            onBlur={passwordBlurHandler}
            value={enteredPassword}
            required
          />

          {loginError && (
            <p className={`${styles.error} ${styles.warning}`}>{props.error}</p>
          )}
          <Button type="submit" disabled={!formIsValid || isSubmitting}>
            {isSubmitting ? "Logging in..." : "Sign in"}
          </Button>
          <div className={styles.link}>
            <Link to="/reset-password">Forgot your password?</Link>
          </div>
        </Form>
      </Card>
      <Footer />
    </>
  );
}
export default LoginFrom;

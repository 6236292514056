import React, { useEffect, useState } from "react";
import Modal from "../../../UI/Modal/Modal";
import { useContext } from "react";
import styles from "./RequestDetailsPage.module.css";
import ModalContext from "../../../context/modal-context";
import { getAuthToken } from "../../../util/auth";
import { fetchRequest } from "../../../util/fetchRequest";
import * as Hi2Icons from "react-icons/hi2";
import moment from "moment";

function RequestDetailsPage(props) {
  const modalCtx = useContext(ModalContext);
  const token = getAuthToken();

  let URL =
    process.env.REACT_APP_API_URL + `ava/crm/requests/${props.requestId}`;
  if (props.type === "partner") {
    URL =
      process.env.REACT_APP_API_URL +
      `crm/${props.partnerId}/requests/${props.requestId}`;
  }
  const headers = { Authorization: `Bearer ${token}` };

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const leadOptions = props.options;
  const memberOptions = props.options;

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchRequest(URL, "GET", headers, undefined);
      setData(response);
      setDate(moment(response.date).format("DD/MM/YYYY HH:mm"));
    };
    fetchData();
  }, []);

  const [channelValue, setChannelValue] = useState("");
  const [priorityValue, setPriorityValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [leadValue, setLeadValue] = useState("");
  const [memberValue, setMemberValue] = useState("");
  const [date, setDate] = useState();

  useEffect(() => {
    if (data.channel === "AV Alliance") {
      setChannelValue("AV Alliance");
    } else if (data.channel === "web") {
      setChannelValue("Web");
    } else if (data.channel === "email") {
      setChannelValue("Email");
    } else if (data.channel === "phone") {
      setChannelValue("Phone");
    } else if (data.channel === "social") {
      setChannelValue("Social");
    } else if (data.channel === "live") {
      setChannelValue("Live");
    } else if (data.channel === "other") {
      setChannelValue("Other");
    }

    if (data.priority === "important") {
      setPriorityValue("Important");
    } else if (data.priority === "strong") {
      setPriorityValue("Strong");
    } else if (data.priority === "normal") {
      setPriorityValue("Normal");
    } else if (data.priority === "weak") {
      setPriorityValue("Weak");
    } else if (data.priority === "spam") {
      setPriorityValue("Spam");
    } else if (data.priority === "unrelated") {
      setPriorityValue("Unrelated");
    }

    if (data.status === "new") {
      setStatusValue("New");
    } else if (data.status === "handled") {
      setStatusValue("Handled");
    } else if (data.status === "declined") {
      setStatusValue("Declined");
    }
    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    const foundLead = leadOptions.find(
      (option) => option.value === data.leadId
    );

    if (foundLead) {
      setLeadValue(foundLead.label);
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (memberOptions) {
      const foundMember = memberOptions.find(
        (option) => option.value === data.partnerId
      );

      if (foundMember) {
        setMemberValue(foundMember.label);
        setIsLoading(false);
      }
    }
  }, [data]);

  const closeHandler = () => {
    modalCtx.showModal();
  };

  return (
    <Modal>
      {!isLoading && (
        <div className={styles.container}>
          <div className={styles.title}>
            <h2>Request details</h2>
            <Hi2Icons.HiOutlineXMark
              className={styles["close-button"]}
              onClick={closeHandler}
            />
          </div>
          <div className={styles.content}>
            <h3 className={styles["sub-title"]}>Customer details</h3>

            <div className={styles["details"]}>
              <p>Name</p>
              <span>{data.name || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>E-mail</p>
              <span>
                <a href={`mailto:${data.email}`}>{data.email || "-"}</a>
              </span>
            </div>
            {data.phone && (
              <div className={styles["details"]}>
                <p>Phone</p>
                <span>
                  <a href={`tel:${data.phone}`}>{data.phone}</a>
                </span>
              </div>
            )}
            <div className={styles["details"]}>
              <p>Company</p>
              <span>{data.company || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>Message</p>
              <span>{data.message || "-"}</span>
            </div>

            <h3 className={styles["sub-title"]}>Acquisition details</h3>

            <div className={styles["details"]}>
              <p>Date</p>
              <span>{date || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>Channel</p>
              <span>{channelValue || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>Source</p>
              <span>{data.source || "-"}</span>
            </div>
            <h3 className={styles["sub-title"]}>Request details</h3>

            <div className={styles["details"]}>
              <p>Priority</p>
              <span>{priorityValue || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>Status</p>
              <span>{statusValue || "-"}</span>
            </div>
            <h3 className={styles["sub-title"]}>Other details</h3>
            {props.type === "partner" && (
              <div className={styles["details"]}>
                <p>Lead</p>
                <span>{leadValue || "-"}</span>
              </div>
            )}
            {props.type === "AVA" && (
              <div className={styles["details"]}>
                <p>Member</p>
                <span>{memberValue || "-"}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}

export default RequestDetailsPage;

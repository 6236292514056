import React, { useState, useEffect } from "react";
import styles from "./Pagination.module.css";
import * as Hi2Icons from "react-icons/hi2";

const Pagination = ({
  pageChangeHandler,
  totalRows,
  rowsPerPage,
  currentPage,
}) => {
  // Calculating max number of pages
  const noOfPages = Math.ceil(totalRows / rowsPerPage);

  // Creating an array with length equal to no.of pages
  //   const pagesArr = [...new Array(noOfPages)];

  // State variable to hold the current page. This value is
  // passed to the callback provided by the parent
  // const [currentPage, setCurrentPage] = useState(1);

  // Navigation arrows enable/disable state
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  // These variables give the first and last record/row number
  // with respect to the current page
  const [pageFirstRecord, setPageFirstRecord] = useState(0);
  const [pageLastRecord, setPageLastRecord] = useState(rowsPerPage);

  // Onclick handlers for the butons
  const onNextPage = (e) => {
    e.preventDefault();
    pageChangeHandler(currentPage + 1);
  };
  const onPrevPage = (e) => {
    e.preventDefault();
    pageChangeHandler(currentPage - 1);
  };
  const onFirstPage = (e) => {
    e.preventDefault();
    pageChangeHandler(1);
  };
  const onLastPage = (e) => {
    e.preventDefault();
    pageChangeHandler(noOfPages);
  };
  //   const onPageSelect = (pageNo) => pageChangeHandler(pageNo);

  // Disable previous and next buttons in the first and last page
  // respectively
  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);

  // To set the starting index of the page
  useEffect(() => {
    const skipFactor = (currentPage - 1) * rowsPerPage;
    // Some APIs require skip for paginaiton. If needed use that instead
    // pageChangeHandler(skipFactor);
    // pageChangeHandler(currentPage)
    setPageFirstRecord(skipFactor + 1);
  }, [currentPage, rowsPerPage]);

  // To set the last index of the page
  useEffect(() => {
    const count = pageFirstRecord + rowsPerPage;
    setPageLastRecord(count > totalRows ? totalRows : count - 1);
  }, [pageFirstRecord, rowsPerPage, totalRows]);

  return (
    <>
      {noOfPages > 0 ? (
        <div className={styles.pagination}>
          <div className={styles.pageInfo}>
            Showing {pageFirstRecord} - {pageLastRecord} of {totalRows} results
          </div>
          <div className={styles["pagination-grid"]}>
            {/* <div className={styles.pagebuttons}> */}
            <button
              disabled={!canGoBack}
              className={`${styles["first-button"]} ${
                !canGoBack ? styles.inactiveButton : ""
              }`}
              onClick={onFirstPage}
            >
              <Hi2Icons.HiOutlineChevronDoubleLeft />
            </button>
            <button
              disabled={!canGoBack}
              className={`${!canGoBack ? styles.inactiveButton : ""}`}
              onClick={onPrevPage}
            >
              <Hi2Icons.HiOutlineChevronLeft />
            </button>
            <span className={styles.pagecounter}>
              Page {currentPage} of {noOfPages}
            </span>
            <button
              disabled={!canGoNext}
              className={`${!canGoNext ? styles.inactiveButton : ""}`}
              onClick={onNextPage}
            >
              <Hi2Icons.HiOutlineChevronRight />
            </button>
            <button
              disabled={!canGoNext}
              className={`${styles["last-button"]} ${
                !canGoNext ? styles.inactiveButton : ""
              }`}
              onClick={onLastPage}
            >
              <Hi2Icons.HiOutlineChevronDoubleRight />
            </button>
            {/* </div> */}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Pagination;

import { useEffect, useContext, useState } from "react";
import styles from "./ServiceEditPage.module.css";
import { Form } from "react-router-dom";
import Input from "../../../UI/Input/Input";
import Button from "../../../UI/Button/Button";
import FormDataContext from "../../../context/formData-context";
import { getAuthToken } from "../../../util/auth";
import { fetchRequest } from "../../../util/fetchRequest";
import Modal from "../../../UI/Modal/Modal";
import ModalContext from "../../../context/modal-context";
import * as Hi2Icons from "react-icons/hi2";

function ServiceEditPage(props) {
  const method = props.method;
  const token = getAuthToken();
  const URL = process.env.REACT_APP_API_URL + `ava/services/` + props.partnerId;
  const headers = { Authorization: `Bearer ${token}` };

  const modalCtx = useContext(ModalContext);
  const dataCtx = useContext(FormDataContext);

  useEffect(() => {
    dataCtx.editFromData({});
  }, []);

  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchRequest(URL, "GET", headers, undefined);
      setData(response);
    };
    if (method === "PUT") {
      fetchData();
    }
  }, []);

  useEffect(() => {
    let initialValues = { name: "" };
    let URLsuffix = "";

    if (method === "PUT") {
      initialValues = {
        name: data.name,
      };
      URLsuffix = data.id;
    }

    dataCtx.editFromData(initialValues);

    const requestData = {
      url: process.env.REACT_APP_API_URL + "ava/services/" + URLsuffix,
      method: method,
      headers: { Authorization: `Bearer ${token}` },
    };

    dataCtx.editRequestData(requestData);
  }, [data, method]);

  const [isEmpty, setIsEmpty] = useState(true);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: value,
    });

    setIsEmpty(value === "");
  };

  const submitHandler = async (event) => {
    await dataCtx.fetchData();

    props.refresh();
    modalCtx.showModal();
  };

  const title = method === "PUT" ? "Edit" : method === "POST" ? "Create" : "";

  return (
    <Modal>
      <div className={styles.card}>
        <div className={styles.title}>
          <h2>{title} service</h2>
          <Hi2Icons.HiOutlineXMark
            className={styles["close-button"]}
            onClick={() => {
              modalCtx.showModal();
              dataCtx.editFromData({});
            }}
          />
        </div>
        <Form>
          <div className={styles.content}>
            <Input
              autoFocus={true}
              label="Name"
              id="name"
              name="name"
              value={dataCtx.formData.name || ""}
              onChange={handleInputChange}
            />
            <div className={styles["buttons"]}>
              <Button
                type="submit"
                className={styles["submit-button"]}
                onClick={submitHandler}
                disabled={isEmpty}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default ServiceEditPage;

import React, { useEffect, useState } from "react";
import Modal from "../../UI/Modal/Modal";
import { useContext } from "react";
import styles from "./UserDetailsPage.module.css";
import ModalContext from "../../context/modal-context";
import { getAuthToken } from "../../util/auth";
import { fetchRequest } from "../../util/fetchRequest";
import * as Hi2Icons from "react-icons/hi2";
import { Img } from "react-image";

function UserDetailsPage(props) {
  const modalCtx = useContext(ModalContext);
  const token = getAuthToken();
  const URL = process.env.REACT_APP_API_URL + `user/accounts/${props.userId}`;
  const headers = { Authorization: `Bearer ${token}` };

  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchRequest(URL, "GET", headers, undefined);
      setData(response);
    };
    fetchData();
  }, []);

  return (
    <Modal>
      <div className={styles.container}>
        <div className={styles.title}>
          <h2>User details</h2>

          <Hi2Icons.HiOutlineXMark
            className={styles["close-button"]}
            onClick={() => modalCtx.showModal()}
          />
        </div>
        <div className={styles.content}>
          <div className={styles["details"]}>
            <p>Profile picture</p>
            <Img src="/dummy-profile.jpg" height={100} />
          </div>
          <div className={styles["details"]}>
            <p>Name</p>
            <span>{`${data.firstName} ${data.lastName}`}</span>
          </div>
          <div className={styles["details"]}>
            <p>Email</p>
            <span>{data.email}</span>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default UserDetailsPage;

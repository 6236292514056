import { json } from "react-router-dom";

export async function fetchRequest(URL, method, headers, data) {
  try {
    const response = await fetch(URL, {
      method: method,
      headers: headers,
      body: data
        ? JSON.stringify(data, (key, value) => {
            if (typeof value === "number") {
              return value;
            }
            return value;
          })
        : null,
    });
    if (!response.ok && response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }

    if (!response.ok) {
      throw new Error(response.status);
    }

    if (response.status === 204) {
      return null;
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    if (error.message === "400") {
      throw json(
        {
          message: "Something went wrong with the request.",
        },
        { status: 400 }
      );
    }

    if (error.message === "401 Unauthorized") {
      throw json(
        {
          message: "Unauthorized, please log in and try again.",
        },
        { status: 401 }
      );
    }

    if (error.message === "403 Forbidden") {
      throw json(
        {
          message: "Forbidden",
        },
        { status: 403 }
      );
    }

    if (error.message === "404") {
      throw json(
        {
          message: "The page you want to communicate with does not exist",
        },
        { status: 404 }
      );
    }

    if (error.message === "Failed to fetch") {
      throw json(
        {
          message:
            "Sorry, the requested server is unavailable. Please contact your support and describe your issue.",
        },
        { status: 503 }
      );
    }

    throw error;
  }
}

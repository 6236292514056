import GridItem from "./GridItem";
import styles from "./GridBrowser.module.css";

function GridBrowser(props) {
  return (
    <>
      <div className={styles.gridContainer}>
        {props.data.map((item) => (
          <GridItem
            key={item.id}
            item={item}
            clickHandler={props.clickHandler}
            noIcons={props.noIcons}
            onDelete={props.onDelete}
            onShare={props.onShare}
          />
        ))}
      </div>
    </>
  );
}
export default GridBrowser;

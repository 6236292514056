import { Link, useLocation } from "react-router-dom";
import styles from "./Breadcrumbs.module.css";
import * as AiIcons from "react-icons/ai";
import { useMatches } from "react-router-dom";

export default function Breadcrumbs() {
  const location = useLocation().pathname;

  let matches = useMatches();
  let crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.data));

  return (
    <div className={styles.head}>
      {location !== "/" && (
        <div className={styles.breadcrumbs}>
          <li className={styles.crumb}>
            <Link to="/" className={styles.crumb}>
              <AiIcons.AiFillHome />
            </Link>
          </li>
          {crumbs.map((crumb, index) => (
            <li key={index} className={styles.crumb}>
              {crumb}
            </li>
          ))}
        </div>
      )}
    </div>
  );
}

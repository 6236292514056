import React, { useState, useEffect, useRef } from "react";
import styles from "./RequestsPage.module.css";
import { useContext } from "react";
import Table from "../../../UI/Table/Table";
import Header from "../../../UI/Table/Header";
import Pagination from "../../../UI/Pagination/Pagination";
import Card from "../../../UI/Card/Card";
import { Link, useLoaderData, useParams } from "react-router-dom";
import CheckBoxSimple from "../../../UI/Input/CheckBoxSimple";
import SearchBar from "../../../UI/SearchBar/SearchBar";
import Button from "../../../UI/Button/Button";
import ErrorPage from "../../../ErrorPage";
import { useDataFetching } from "../../../../hooks/use-datafetching";
import { getAuthToken } from "../../../util/auth";
import ModalContext from "../../../context/modal-context";
import { fetchRequest } from "../../../util/fetchRequest";
import * as Hi2Icons from "react-icons/hi2";
import Confirm from "../../../UI/Confirm/Confirm";
import SelectCounter from "../../../UI/SelectCounter/SelectCounter";
import moment from "moment";
import EmptyTable from "../../../UI/Table/EmptyTable";
import RequestDetailsPage from "./RequestDetailsPage";
import RequestEditPage from "./RequestEditPage";
import Tooltip from "../../../UI/Tooltip/Tooltip";

function RequestsPage(props) {
  const modalCtx = useContext(ModalContext);
  const params = useParams();

  const options = useLoaderData();

  const [partnerId, setPartnerId] = useState(params.partnerId);

  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  //* Fetch with sort and search params
  const [currentPage, setCurrentPage] = useState(1);
  const [sortParam, setSortParam] = useState("");
  const [searchParam, setSearchParam] = useState("");

  let URLsuffix = `ava/crm/requests`;

  if (props.type === "partner") {
    URLsuffix = `crm/${params.partnerId}/requests`;
  }

  const { error, pageData, getTableData } = useDataFetching(
    currentPage,
    URLsuffix,
    sortParam,
    searchParam
  );

  const [selectedIds, setSelectedIds] = useState([]);

  const [deleteAction, setDeleteAction] = useState(null);

  const [requestId, setRequestId] = useState("");

  const [modalType, setModalType] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    getTableData();
    setSortParam("");
  }, [params.partnerId]);

  //* Search

  const searchRef = useRef(null);

  const handleSearch = (event) => {
    event.preventDefault();
    const searchValue = searchRef.current.value;
    setSearchParam(`&search=${searchValue}`);
  };

  const clearSearchHandler = () => {
    setSearchParam("");
  };

  useEffect(() => {
    setCurrentPage(1);
    getTableData();
  }, [searchParam]);

  //* Sorting

  const sortPartner = (accessor, order) => {
    setSortParam(`&sort=${accessor}&order=${order}`);
  };

  useEffect(() => {
    getTableData();
  }, [sortParam]);

  //* Handlers
  const [allSelectedIds, setAllSelectedIds] = useState([]);

  const prevSelectedIdsRef = useRef([]);

  useEffect(() => {
    setSelectedIds(prevSelectedIdsRef.current);
  }, []);

  const handleSelectAll = (event) => {
    const checked = event.target.checked;

    if (checked) {
      const currentPageIds = pageData.rowData.map((item) => item.id);
      setSelectedIds(currentPageIds);
      setAllSelectedIds((prevAllSelectedIds) => {
        const newSelectedIds = [
          ...new Set([...prevAllSelectedIds, ...currentPageIds]),
        ];
        return newSelectedIds;
      });
    } else {
      setSelectedIds([]);
      setAllSelectedIds((prevAllSelectedIds) => {
        const currentPageIds = pageData.rowData.map((item) => item.id);
        const updatedSelectedIds = prevAllSelectedIds.filter(
          (selectedId) => !currentPageIds.includes(selectedId)
        );
        return updatedSelectedIds;
      });
    }
  };

  useEffect(() => {
    if (pageData.rowData.length && allSelectedIds.length) {
      const currentPageIds = pageData.rowData.map((item) => item.id);
      const updatedSelectedIds = allSelectedIds.filter((selectedId) =>
        currentPageIds.includes(selectedId)
      );
      setSelectedIds(updatedSelectedIds);
    } else {
      setSelectedIds([]);
    }
  }, [currentPage, pageData.rowData, allSelectedIds]);

  const handleSelectSingle = (event, id) => {
    const checked = event.target.checked;
    let updatedSelectedIds = [...selectedIds];
    let updatedAllSelectedIds = [...allSelectedIds];

    if (checked) {
      updatedSelectedIds.push(id);
      updatedAllSelectedIds.push(id);
    } else {
      updatedSelectedIds = updatedSelectedIds.filter(
        (selectedId) => selectedId !== id
      );
      updatedAllSelectedIds = updatedAllSelectedIds.filter(
        (selectedId) => selectedId !== id
      );
    }

    setSelectedIds(updatedSelectedIds);
    setAllSelectedIds(updatedAllSelectedIds);
  };

  const clearSelectionHandler = () => {
    setAllSelectedIds([]);
  };

  const deleteSelectedHandler = async () => {
    let deleteURL = "";
    if (props.type === "partner") {
      deleteURL =
        process.env.REACT_APP_API_URL + `/crm/${params.partnerId}/requests`;
    } else if (props.type === "AVA") {
      deleteURL = process.env.REACT_APP_API_URL + `ava/crm/requests`;
    }
    await fetchRequest(deleteURL, "DELETE", headers, allSelectedIds);
    setSelectedIds([]);
    setAllSelectedIds([]);
    getTableData();
  };

  const deleteHandler = (id, partnerId) => {
    setModalType("delete");
    setDeleteAction(() => () => deleteFunction(id, partnerId));
    modalCtx.showModal();
  };

  const multiDeleteHandler = () => {
    setModalType("delete");
    setDeleteAction(() => deleteSelectedHandler);
    modalCtx.showModal();
  };

  const deleteFunction = async (id, partnerId) => {
    const URL =
      process.env.REACT_APP_API_URL +
      `crm/${params.partnerId || partnerId}/requests/` +
      id;
    await fetchRequest(URL, "DELETE", headers, undefined);
    let updatedSelectedIds = [...selectedIds];
    updatedSelectedIds = updatedSelectedIds.filter(
      (selectedId) => selectedId !== id
    );
    setSelectedIds(updatedSelectedIds);
    setAllSelectedIds((prevAllSelectedIds) =>
      prevAllSelectedIds.filter((selectedId) => selectedId !== id)
    );
    getTableData();
  };

  const createHandler = () => {
    setModalType("new");
    setType(props.type);
    modalCtx.showModal();
  };

  const editHandler = (id, pId) => {
    setRequestId(id);
    if (pId) {
      setPartnerId(pId);
    }
    setModalType("edit");
    modalCtx.showModal();
  };

  const detailsHandler = async (id, pId) => {
    setRequestId(id);
    setType(props.type);
    if (pId) {
      setPartnerId(pId);
    }
    setModalType("details");
    modalCtx.showModal();
  };

  //* Table header
  const columns = [];
  if (props.type === "partner") {
    columns.push(
      {
        Header: (
          <div className={styles["checkbox-header"]}>
            <CheckBoxSimple
              id="name-column"
              name="name-column"
              disabled={pageData.rowData.length === 0}
              checked={
                selectedIds.length === pageData.rowData.length &&
                pageData.rowData.length !== 0
              }
              onChange={handleSelectAll}
            />
            <Header sortHandler={sortPartner} title="Name" id="name" />
          </div>
        ),
        accessor: "name",
        width: "20%",

        Cell: ({ value, row }) => (
          <CheckBoxSimple
            key={row.original.id}
            label={
              <Link
                className={styles.name}
                onClick={() => {
                  detailsHandler(row.original.id);
                  setType(props.type);
                }}
              >
                {value}
              </Link>
            }
            id={row.original.id}
            name={row.original.name}
            checked={selectedIds.includes(row.original.id)}
            onChange={(event) => handleSelectSingle(event, row.original.id)}
          />
        ),
      },
      {
        Header: <Header sortHandler={sortPartner} title="Date" id="date" />,
        accessor: "date",
        width: "20%",

        Cell: ({ value, row }) => (
          <Link
            className={styles.name}
            onClick={() => {
              detailsHandler(row.original.id);
              setType(props.type);
            }}
          >
            {moment(value).format("MM/DD/YYYY HH:mm")}
          </Link>
        ),
      },
      {
        Header: (
          <Header sortHandler={sortPartner} title="Channel" id="channel" />
        ),
        accessor: "channel",
        width: "20%",

        Cell: ({ value, row }) => (
          <Link
            className={styles.name}
            onClick={() => {
              detailsHandler(row.original.id);
              setType(props.type);
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: (
          <Header sortHandler={sortPartner} title="Priority" id="priority" />
        ),
        accessor: "priority",
        width: "20%",

        Cell: ({ value, row }) => (
          <Link
            className={styles.name}
            onClick={() => {
              detailsHandler(row.original.id);
              setType(props.type);
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: <Header sortHandler={sortPartner} title="Status" id="status" />,
        accessor: "status",
        width: "20%",

        Cell: ({ value, row }) => (
          <Link
            className={styles.name}
            onClick={() => {
              detailsHandler(row.original.id);
              setType(props.type);
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Actions",
        accessor: "id",
        id: "actions",
        width: "100px",

        Cell: ({ row, value }) => (
          <>
            <Tooltip text={"Open details"}>
              <Hi2Icons.HiOutlineInformationCircle
                className={styles["details-icon"]}
                onClick={() => {
                  detailsHandler(row.original.id);
                  setType(props.type);
                }}
              />
            </Tooltip>
            <Tooltip text={"Edit request"}>
              <Hi2Icons.HiOutlinePencil
                className={styles["edit-icon"]}
                onClick={() => {
                  editHandler(row.original.id);
                  setType(props.type);
                }}
              />
            </Tooltip>
            <Tooltip text={"Delete request"}>
              <Hi2Icons.HiOutlineTrash
                className={styles["delete-icon"]}
                onClick={() => deleteHandler(row.original.id)}
              />
            </Tooltip>
          </>
        ),
      }
    );
  }

  if (props.type === "AVA") {
    columns.push(
      {
        Header: (
          <div className={styles["checkbox-header"]}>
            <CheckBoxSimple
              id="member-column"
              name="member-column"
              disabled={pageData.rowData.length === 0}
              checked={
                selectedIds.length === pageData.rowData.length &&
                pageData.rowData.length !== 0
              }
              onChange={handleSelectAll}
            />
            <Header
              sortHandler={sortPartner}
              title="Member"
              id="partner.name"
            />
          </div>
        ),
        accessor: "member",
        width: "25%",

        Cell: ({ value, row }) => (
          <CheckBoxSimple
            key={row.original.id}
            label={
              <Link
                className={styles.name}
                onClick={() => {
                  detailsHandler(row.original.id, row.original.partnerId);
                  setType(props.type);
                }}
              >
                {value}
              </Link>
            }
            id={row.original.id}
            name={row.original.name}
            checked={selectedIds.includes(row.original.id)}
            onChange={(event) => handleSelectSingle(event, row.original.id)}
          />
        ),
      },
      {
        Header: <Header sortHandler={sortPartner} title="Name" id="name" />,
        accessor: "name",
        width: "25%",

        Cell: ({ value, row }) => (
          <Link
            className={styles.name}
            onClick={() => {
              detailsHandler(row.original.id, row.original.partnerId);
              setType(props.type);
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: (
          <div className={styles["checkbox-header"]}>
            <Header sortHandler={sortPartner} title="Date" id="date" />
          </div>
        ),
        accessor: "date",
        width: "25%",

        Cell: ({ value, row }) => (
          <Link
            className={styles.name}
            onClick={() => {
              detailsHandler(row.original.id, row.original.partnerId);
              setType(props.type);
            }}
          >
            {moment(value).format("MM/DD/YYYY HH:mm")}
          </Link>
        ),
      },

      {
        Header: <Header sortHandler={sortPartner} title="Status" id="status" />,
        accessor: "status",
        width: "25%",

        Cell: ({ value, row }) => (
          <Link
            className={styles.name}
            onClick={() => {
              detailsHandler(row.original.id, row.original.partnerId);
              setType(props.type);
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Actions",
        accessor: "id",
        id: "actions",
        width: "100px",

        Cell: ({ row, value }) => (
          <>
            <Tooltip text={"Open details"}>
              <Hi2Icons.HiOutlineInformationCircle
                className={styles["details-icon"]}
                onClick={() => {
                  detailsHandler(row.original.id, row.original.partnerId);
                  setType(props.type);
                }}
              />
            </Tooltip>
            <Tooltip text={"Edit request"}>
              <Hi2Icons.HiOutlinePencil
                className={styles["edit-icon"]}
                onClick={() => {
                  editHandler(row.original.id, row.original.partnerId);
                  setType(props.type);
                }}
              />
            </Tooltip>
            <Tooltip text={"Delete request"}>
              <Hi2Icons.HiOutlineTrash
                className={styles["delete-icon"]}
                onClick={() =>
                  deleteHandler(row.original.id, row.original.partnerId)
                }
              />
            </Tooltip>
          </>
        ),
      }
    );
  }

  return (
    <>
      {modalCtx.isModal && modalType === "delete" && (
        <Confirm onDelete={deleteAction} />
      )}
      {modalCtx.isModal && modalType === "details" && (
        <RequestDetailsPage
          type={type}
          options={options}
          partnerId={partnerId}
          requestId={requestId}
        />
      )}
      {modalCtx.isModal && modalType === "edit" && (
        <RequestEditPage
          refresh={getTableData}
          type={type}
          options={options}
          partnerId={partnerId}
          requestId={requestId}
          method="PUT"
        />
      )}
      {modalCtx.isModal && modalType === "new" && (
        <RequestEditPage
          refresh={getTableData}
          type={type}
          options={options}
          partnerId={partnerId}
          requestId={requestId}
          method="POST"
        />
      )}
      <div className={styles.title}>
        <h1>Requests</h1>
        <p>List of all client requests from various sources.</p>
      </div>
      <div className={styles.control}>
        <div className={styles["control-buttons"]}>
          <Button className={styles["create-button"]} onClick={createHandler}>
            New request
          </Button>
          {allSelectedIds.length > 0 && (
            <>
              <Button
                className={styles["delete-button"]}
                onClick={() => multiDeleteHandler()}
              >
                Delete selected
              </Button>
              <Button
                className={styles["clear-button"]}
                onClick={() => clearSelectionHandler()}
              >
                Clear selection
              </Button>
              <SelectCounter counter={allSelectedIds.length} />
            </>
          )}
        </div>
        <SearchBar
          searchRef={searchRef}
          onSubmit={handleSearch}
          clearSearch={clearSearchHandler}
        />
      </div>
      <Card className={styles.card}>
        {error ? (
          <ErrorPage
            error={error}
            title={error.title}
            message={error.message}
          />
        ) : (
          <>
            <Table
              columns={columns}
              data={pageData.rowData}
              isLoading={pageData.isLoading}
            />
            {pageData.rowData.length === 0 && <EmptyTable />}

            <Pagination
              totalRows={pageData.totalRecords}
              pageChangeHandler={setCurrentPage}
              rowsPerPage={20}
              currentPage={currentPage}
            />
          </>
        )}
      </Card>
    </>
  );
}

export default RequestsPage;

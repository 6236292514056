import React, { useContext, useEffect } from "react";
import styles from "./Header.module.css";
import * as Hi2Icons from "react-icons/hi2";
import SortContext from "../../context/tablesort-context";

const Header = (props) => {
  const { ascending, setAscending } = useContext(SortContext);
  const { activeColumn, setActiveColumn } = useContext(SortContext);

  useEffect(() => {
    const sortOrder = ascending ? "ASC" : "DESC";
    if (activeColumn === props.id) {
      props.sortHandler(props.id, sortOrder);
    }
  }, [ascending, activeColumn, props.id]);

  const handleButtonClick = () => {
    if (props.id === activeColumn) {
      setAscending((prevAscending) => !prevAscending);
    } else {
      setActiveColumn(props.id);
      setAscending(true);
    }
  };

  const renderSortIcon = () => {
    if (activeColumn === props.id) {
      return ascending ? (
        <Hi2Icons.HiOutlineChevronUp className={styles.upIcon} />
      ) : (
        <Hi2Icons.HiOutlineChevronDown className={styles.downIcon} />
      );
    } else {
      return <Hi2Icons.HiOutlineChevronUp className={styles.defaultIcon} />;
    }
  };

  return (
    <div className={styles.button}>
      <button onClick={handleButtonClick}>
        {props.title}
        {renderSortIcon()}
      </button>
    </div>
  );
};

export default Header;

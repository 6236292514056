import React, { useEffect, useState } from "react";
import Modal from "../../../UI/Modal/Modal";
import { useContext } from "react";
import FormDataContext from "../../../context/formData-context";
import styles from "./JobApplicantsEditPage.module.css";
import Button from "../../../UI/Button/Button";
import ModalContext from "../../../context/modal-context";
import { getAuthToken } from "../../../util/auth";
import { fetchRequest } from "../../../util/fetchRequest";
import { Form } from "react-router-dom";
import Input from "../../../UI/Input/Input";
import * as Hi2Icons from "react-icons/hi2";
import SingleSelectNew from "../../../UI/Input/SingleSelectNew";
import TextArea from "../../../UI/Input/TextArea";
import moment from "moment";
import CheckBox from "../../../UI/Input/CheckBox";

function JobApplicantsEditPage(props) {
  const dataCtx = useContext(FormDataContext);
  const modalCtx = useContext(ModalContext);

  const method = props.method;
  const token = getAuthToken();

  const URL =
    process.env.REACT_APP_API_URL + `ava/jobapplicants/` + props.applicantId;
  const headers = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    dataCtx.editFromData({});
  }, []);

  const [data, setData] = useState({});

  const [defaultStatusValue, setDefaultStatusValue] = useState("");
  const [defaultFieldValue, setDefaultFieldValue] = useState("");
  const [defaultEmploymentValue, setDefaultEmploymentValue] = useState("");
  const [defaultExpiryValue, setDefaultExpiryValue] = useState("");
  const [formatedDate, setformatedDate] = useState("");

  const [file, setFile] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchRequest(URL, "GET", headers, undefined);
      setData(response);
      if (method === "PUT") {
        const statusValue = statusOptions.find(
          (option) => option.value === response.status
        );
        setDefaultStatusValue(statusValue);

        const fieldValue = fieldOptions.find(
          (option) => option.value === response.field
        );
        setDefaultFieldValue(fieldValue);

        const employmentValue = employmentOptions.find(
          (option) => option.value === response.employment
        );
        setDefaultEmploymentValue(employmentValue);

        const expiryValue = expiryOptions.find(
          (option) => option.value === response.expiry
        );
        setDefaultExpiryValue(expiryValue);

        setformatedDate(response.date.split("T")[0]);
      }
      setIsLoading(false);
    };
    if (method === "PUT") {
      fetchData();
    }
  }, []);

  useEffect(() => {
    let initialValues = {
      status: "",
      firstName: "",
      lastName: "",
      email: "",
      country: "",
      languages: "",
      info: "",
      field: "",
      role: "",
      location: "",
      employment: "",
      date: "",
      expiry: "",
    };
    let URLsuffix = "";

    if (method === "PUT") {
      initialValues = {
        status: data.status,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        country: data.country,
        languages: data.languages,
        info: data.info,
        field: data.field,
        role: data.role,
        location: data.location,
        employment: data.employment,
        date: data.date,
        expiry: data.expiry,
      };
      URLsuffix = "/" + data.id;
    }

    const requestData = {
      url: process.env.REACT_APP_API_URL + "ava/jobapplicants/" + URLsuffix,
      method: method,
      headers: { Authorization: `Bearer ${token}` },
    };

    dataCtx.editFromData(initialValues);
    dataCtx.editRequestData(requestData);
  }, [data, method]);

  const statusOptions = [
    { value: "new", label: "New" },
    { value: "published", label: "Published" },
    { value: "discarded", label: "Discarded" },
  ];

  const fieldOptions = [
    { value: "av", label: "AV" },
    { value: "pm", label: "Project manager" },
    { value: "marketing", label: "Marketing" },
    { value: "other", label: "Other" },
  ];

  const employmentOptions = [
    { value: "fullTime", label: "Full-time" },
    { value: "partTime", label: "Part-time" },
    { value: "freelancer", label: "Freelancer" },
  ];

  const expiryOptions = [
    { value: "sixMonths", label: "6 months" },
    { value: "oneYear", label: "1 year" },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let formattedDate = value;

    if (name === "date") {
      setformatedDate(value);
      const dateObj = moment(value, "YYYY-MM-DD HH:mm:ss Z").add(2, "hours");
      formattedDate = dateObj.format();
    } else if (name === "email") {
      if (isTouched) {
        setIsTouched(false);
      }
    }

    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: formattedDate,
    });
  };

  const validateEmail = (email) => {
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    return regex.test(email);
  };

  const [isTouched, setIsTouched] = useState(false);

  const handleEmailBlur = () => {
    setIsTouched(true);
    handleInputChange({
      target: {
        name: "email",
        value: dataCtx.formData.email,
      },
    });
  };

  const isEmailValid =
    !isTouched ||
    dataCtx.formData.email.trim() === "" ||
    validateEmail(dataCtx.formData.email);

  const handleSingleSelectChange = (selectedOption, fieldName) => {
    const selectedValue = selectedOption.value;
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      [fieldName]: selectedValue,
    }));
  };

  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsDisabled(true);
      setFile(file);
    }
  };

  const submitHandler = async () => {
    await dataCtx.fetchData();
    await CVAction();

    props.refresh();
    modalCtx.showModal();
  };

  const CVAction = async () => {
    if (file) {
      const URL =
        process.env.REACT_APP_API_URL +
        `ava/jobapplicants/${data.id}/upload?fileName=${file.name}`;
      fetch(URL, {
        method: "POST",
        body: file,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).catch((err) => {
        console.error(err);
      });
    } else if (data.cv && !file && isChecked) {
      const URL =
        process.env.REACT_APP_API_URL +
        `ava/jobapplicants/${data.id}/upload?key=${data.cv}`;
      fetchRequest(URL, "DELETE", headers, undefined)
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleCVDeleteChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (data.cv) {
      const shortFileName = data.cv.substring(data.cv.lastIndexOf("/") + 1);
      setFileName(shortFileName);
    }
  }, [data.cv]);

  const title = method === "PUT" ? "Edit" : method === "POST" ? "Create" : "";

  return (
    <Modal>
      {(!isLoading || method !== "PUT") && (
        <div className={styles.container}>
          <Form>
            <div className={styles.title}>
              <h2>{title} Job applicant</h2>
              <div className={styles["close-container"]}>
                <Hi2Icons.HiOutlineXMark
                  className={styles["close-button"]}
                  onClick={() => {
                    modalCtx.showModal();
                    dataCtx.editFromData({});
                  }}
                />
              </div>
            </div>

            <div className={styles.content}>
              <SingleSelectNew
                id="status"
                name="status"
                label="Status"
                options={statusOptions}
                defaultValue={defaultStatusValue}
                value={dataCtx.formData.status}
                onSingleChange={handleSingleSelectChange}
              />
              <h3 className={styles["sub-title"]}>Personal details</h3>
              <div className={styles.basic}>
                <div className={styles["basic-details"]}>
                  <Input
                    label="First name"
                    id="firstName"
                    name="firstName"
                    value={dataCtx.formData.firstName || ""}
                    onChange={handleInputChange}
                  />
                  <Input
                    label="Last name"
                    id="lastName"
                    name="lastName"
                    value={dataCtx.formData.lastName || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <Input
                  label="Email"
                  type="email"
                  id="email"
                  name="email"
                  value={dataCtx.formData.email || ""}
                  onChange={handleInputChange}
                  onBlur={handleEmailBlur}
                />
                {!isEmailValid && (
                  <p className={styles.error}>Please enter a valid email.</p>
                )}

                <Input
                  label="Country of residence"
                  id="country"
                  name="country"
                  value={dataCtx.formData.country || ""}
                  onChange={handleInputChange}
                />
                <Input
                  label="Languages spoken"
                  id="languages"
                  name="languages"
                  value={dataCtx.formData.languages || ""}
                  onChange={handleInputChange}
                />
                <TextArea
                  label="Info"
                  id="info"
                  name="info"
                  rows={10}
                  value={dataCtx.formData.info}
                  onChange={handleInputChange}
                />
                <div className={styles["CV-contanier"]}>
                  Curricullum vitae
                  {data.cv ? (
                    <div className={styles["CV-file"]}>
                      <Hi2Icons.HiOutlineDocumentText size={22} />
                      {fileName}
                    </div>
                  ) : (
                    <div className={styles["CV-file"]}>
                      <span>-</span>
                    </div>
                  )}
                </div>
                <Input
                  //   accept="image/*"
                  type="file"
                  label="Upload CV"
                  id="cv"
                  name="cv"
                  onChange={handleFileChange}
                />
                <CheckBox
                  id="deleteCV"
                  label="Delete CV"
                  name="Delete CV"
                  description="If you checked and cilck Save, the uploaded CV will be deleted"
                  onChange={handleCVDeleteChange}
                  checked={isChecked}
                  disabled={isDisabled}
                />
              </div>
              <h3 className={styles["sub-title"]}>Job details</h3>
              <SingleSelectNew
                id="field"
                name="field"
                label="Field"
                options={fieldOptions}
                defaultValue={defaultFieldValue}
                value={dataCtx.formData.field}
                onSingleChange={handleSingleSelectChange}
              />
              <div className={styles.contact}>
                <div>
                  <Input
                    label="Preferred role"
                    id="role"
                    name="role"
                    value={dataCtx.formData.role || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <Input
                    label="Preferred location"
                    id="location"
                    name="location"
                    value={dataCtx.formData.location || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <SingleSelectNew
                    id="employment"
                    name="employment"
                    label="Employment"
                    options={employmentOptions}
                    defaultValue={defaultEmploymentValue}
                    value={dataCtx.formData.employment}
                    onSingleChange={handleSingleSelectChange}
                  />
                </div>
              </div>
              <h3 className={styles["sub-title"]}>Submission details</h3>
              <div>
                <Input
                  label="Date"
                  type="date"
                  id="date"
                  name="date"
                  value={formatedDate || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <SingleSelectNew
                  id="expiry"
                  name="expiry"
                  label="Expiry"
                  options={expiryOptions}
                  defaultValue={defaultExpiryValue}
                  value={dataCtx.formData.expiry}
                  onSingleChange={handleSingleSelectChange}
                />
              </div>
              <div className={styles["submit-container"]}>
                <Button
                  type="submit"
                  className={styles["submit-button"]}
                  onClick={submitHandler}
                  disabled={!isEmailValid}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Modal>
  );
}

export default JobApplicantsEditPage;

import React, { useContext } from "react";
import ReactDOM from "react-dom";
import Card from "../Card/Card";
import classes from "./Modal.module.css";
import ModalContext from "../../context/modal-context";

const Backdrop = (props) => {
  const modalCtx = useContext(ModalContext);

  const handleclick = () => {
    modalCtx.showModal();
  };

  return <div className={classes.backdrop} onClick={handleclick} />;
};

const ModalOverlay = (props) => {
  return (
    <Card className={`${classes.modal} ${props.className}`}>
      {props.children}
    </Card>
  );
};

const Modal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay className={props.className}>
          {props.children}
        </ModalOverlay>,
        document.getElementById("overlay-root")
      )}
    </React.Fragment>
  );
};

export default Modal;

import React, { useEffect, useState } from "react";
import { registerLocale, getNames } from "i18n-iso-countries";
import SingleSelectNew from "../../../UI/Input/SingleSelectNew";
import Modal from "../../../UI/Modal/Modal";
import { useContext } from "react";
import FormDataContext from "../../../context/formData-context";
import styles from "./LocationEditPage.module.css";
import Button from "../../../UI/Button/Button";
import ModalContext from "../../../context/modal-context";
import { getAuthToken } from "../../../util/auth";
import { fetchRequest } from "../../../util/fetchRequest";
import { Form, useParams } from "react-router-dom";
import Input from "../../../UI/Input/Input";
import PhoneInputField from "../../../UI/Input/PhoneInputField";
import * as Hi2Icons from "react-icons/hi2";
import LocationFinder from "../../../UI/Input/LocationFinder";

function LocationEditPage(props) {
  const dataCtx = useContext(FormDataContext);
  const modalCtx = useContext(ModalContext);
  const param = useParams();
  const method = props.method;
  const token = getAuthToken();

  const URL =
    process.env.REACT_APP_API_URL +
    `intranet/partners/` +
    param.partnerId +
    `/locations/${props.locationId}`;
  const headers = { Authorization: `Bearer ${token}` };

  registerLocale(require("i18n-iso-countries/langs/en.json"));
  const countryNames = getNames("en");
  const countryOptions = Object.entries(countryNames).map(([key, name]) => ({
    value: name,
    label: name,
  }));

  const regionOptions = [
    { value: "NAFTA", label: "NAFTA" },
    { value: "LATAM", label: "LATAM" },
    { value: "EMEA", label: "EMEA" },
    { value: "APAC", label: "APAC" },
  ];

  const [defaultRegionOption, setDefaultRegionOption] = useState("");
  const [defaultCountryOption, setDefaultCountryOption] = useState("");

  useEffect(() => {
    dataCtx.editFromData({});
  }, []);

  const [data, setData] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchRequest(URL, "GET", headers, undefined);
        setData(response);
        if (method === "PUT") {
          const regionOption = regionOptions.find(
            (option) => option.value === response.region
          );
          setDefaultRegionOption(regionOption);

          const countryOption = countryOptions.find(
            (option) => option.value === response.country
          );
          setDefaultCountryOption(countryOption);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    if (method === "PUT") {
      fetchData();
    }
  }, []);

  useEffect(() => {
    let initialValues = {
      region: "",
      country: "",
      city: "",
      county: "",
      zip: "",
      address: "",
      phone: "",
      latitude: null,
      longitude: null,
    };
    let URLsuffix = "";

    if (method === "PUT") {
      initialValues = {
        region: data.region,
        country: data.country,
        city: data.city,
        county: data.county,
        zip: data.zip,
        address: data.address,
        phone: data.phone,
        latitude: data.latitude,
        longitude: data.longitude,
      };
      URLsuffix = "/" + data.id;
    }

    const requestData = {
      url:
        process.env.REACT_APP_API_URL +
        "intranet/partners/" +
        param.partnerId +
        "/locations" +
        URLsuffix,
      method: method,
      headers: { Authorization: `Bearer ${token}` },
    };

    dataCtx.editFromData(initialValues);
    dataCtx.editRequestData(requestData);
  }, [data]);

  useEffect(() => {}, [data]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: value,
    });
  };

  const [latitude, setLatitude] = useState(data.latitude);
  const [longitude, setLongitude] = useState(data.longitude);

  const handleNumberInputChange = (event) => {
    const { name, value } = event.target;
    const parsedValue = value !== "" ? parseFloat(value) : null;

    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: parsedValue,
    });
    if (name === "latitude") {
      setLatitude(parsedValue);
    }
    if (name === "longitude") {
      setLongitude(parsedValue);
    }
  };

  const handleGoogleMapChange = (coordinates) => {
    const lat = coordinates.lat;
    const lon = coordinates.lng;
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      latitude: lat,
      longitude: lon,
    }));
  };

  const locationDelete = () => {
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      latitude: null,
      longitude: null,
    }));
  };

  const generateCoordinates = () => {
    if (data.latitude && data.longitude) {
      return { lat: data.latitude, lng: data.longitude };
    } else {
      return null;
    }
  };

  const handleMarkerClick = (markerPosition) => {
    handleGoogleMapChange(markerPosition);
  };

  const handleSingleSelectChange = (selectedOption, fieldName) => {
    const selectedValue = selectedOption.value;
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      [fieldName]: selectedValue,
    }));
  };

  const handlePhoneChange = (value) => {
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      phone: value,
    }));
  };

  const submitHandler = async () => {
    await dataCtx.fetchData();

    props.refresh();
    modalCtx.showModal();
  };

  const title = method === "PUT" ? "Edit" : method === "POST" ? "Create" : "";

  return (
    <Modal>
      {(!isLoading || method !== "PUT") && (
        <div className={styles.container}>
          <div className={styles.title}>
            <h2>{title} location</h2>

            <Hi2Icons.HiOutlineXMark
              className={styles["close-button"]}
              onClick={() => {
                modalCtx.showModal();
                dataCtx.editFromData({});
              }}
            />
          </div>
          <form>
            <div className={styles.content}>
              <h3 className={styles["sub-title"]}>Address Details</h3>
              <div className={styles.country}>
                <div className={styles["details"]}>
                  <SingleSelectNew
                    className={styles["region-select"]}
                    id="region"
                    name="region"
                    label="Region"
                    options={regionOptions}
                    defaultValue={defaultRegionOption}
                    value={dataCtx.formData.region}
                    onSingleChange={handleSingleSelectChange}
                  />
                </div>
                <div className={styles["details"]}>
                  <SingleSelectNew
                    className={styles["country-select"]}
                    id="country"
                    name="country"
                    label="Country"
                    options={countryOptions}
                    defaultValue={defaultCountryOption}
                    value={dataCtx.formData.country}
                    onSingleChange={handleSingleSelectChange}
                  />
                </div>
              </div>
              <div className={styles.address}>
                <div className={styles["details"]}>
                  <Input
                    label="Zip"
                    id="zip"
                    name="zip"
                    value={dataCtx.formData.zip || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles["details"]}>
                  <Input
                    label="City"
                    id="city"
                    name="city"
                    value={dataCtx.formData.city || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className={styles["details"]}>
                <Input
                  label="County"
                  id="county"
                  name="county"
                  value={dataCtx.formData.county || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className={styles["details"]}>
                <Input
                  label="Address"
                  id="address"
                  name="address"
                  required="required"
                  value={dataCtx.formData.address || ""}
                  onChange={handleInputChange}
                />
              </div>
              <PhoneInputField
                id="phone"
                name="phone"
                label="Phone number"
                title="phone"
                startValue={data.phone}
                value={dataCtx.formData.phone}
                onChange={handlePhoneChange}
              />
              <h3 className={styles["coordinates-sub-title"]}>Coordinates</h3>
              <div className={styles.coordinates}>
                <div className={styles["details"]}>
                  <Input
                    label="Latitude"
                    id="latitude"
                    name="latitude"
                    type="number"
                    min="-90"
                    max="90"
                    value={dataCtx.formData.latitude || ""}
                    onChange={handleNumberInputChange}
                  />
                </div>
                <div className={styles["details"]}>
                  <Input
                    label="Longitude"
                    id="longitude"
                    name="longitude"
                    type="number"
                    min="-180"
                    max="180"
                    value={dataCtx.formData.longitude || ""}
                    onChange={handleNumberInputChange}
                  />
                </div>
              </div>
              <LocationFinder
                onMarkerClick={handleMarkerClick}
                onLocationFind={handleGoogleMapChange}
                defaultLocation={generateCoordinates()}
                onDelete={locationDelete}
                latitude={latitude}
                longitude={longitude}
              />{" "}
              <div className={styles["buttons"]}>
                <Button
                  className={styles["submit-button"]}
                  onClick={submitHandler}
                  // disabled={isDisabled}
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
}

export default LocationEditPage;

import React, { useEffect, useState } from "react";
import styles from "./SelectCounter.module.css";

function SelectCounter(props) {
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (props.counter === 1) {
      setMessage("1 selected item");
    } else {
      setMessage(`${props.counter} selected items`);
    }
  }, [props.counter]);

  return <div className={styles.counter}>{message}</div>;
}

export default SelectCounter;

import { Link, createBrowserRouter } from "react-router-dom";

import Login, { action as loginAction } from "./Login";
import { action as logoutAction } from "./Logout";
import AdminHomePage from "./Dashboard";
import Error from "./Error";
import {
  checkAuthLoader,
  loginCheck,
  rootLoader,
} from "../components/util/auth";
import RootLayout from "./Root";
import Forgot, { action as forgotAction } from "./Forgot";
import Profile, { loader as profileLoader } from "./User/Profile";
import PartnerList from "./AVA/Intranet/PartnerList";
import Services from "./AVA/Intranet/Services";
import PartnerDetail, {
  loader as partnerDetailLoader,
} from "../pages/AVA/Intranet/PartnerDetail";
import PartnerUpdate, {
  loader as partnerUpdateLoader,
} from "./AVA/Intranet/PartnerUpdate";
import Currencies from "./AVA/Intranet/Currencies";
import OutletLayout from "./AVA/Intranet/OutletLayout";
import NewPartner, {
  loader as newPartnerLoader,
} from "./AVA/Intranet/NewPartner";
import PartnerDetailsUpdate from "./AVA/Intranet/PartnerDetailsUpdate";
import JobApplicants from "./AVA/Intranet/JobApplicants";
import Members from "./Shared/Members";
import Sponsors from "./Shared/Sponsors";
import Contacts from "./Shared/Contacts";
import Locations from "./Shared/Locations";
import SharedPartnerDetail, {
  loader as sharedPartnerLoader,
} from "./Shared/SharedPartnerDetail";
import SharedJobApplicants from "./Shared/JobApplicants";
import Requests from "./AVA/CRM/Requests";
import Leads from "./AVA/CRM/Leads";
import Events from "./AVA/CRM/Events";
import { loader as IntranetPartnerLoader } from "./AVA/Intranet/IntranetPartnerDetailsUpdate";
import { AVAloader as avaRequestLoader } from "./AVA/CRM/Requests";
import { memberLoader as memberRequestLoader } from "./AVA/CRM/Requests";
import { loader as eventsLoader } from "./AVA/CRM/Events";
import LeadDetails, {
  loader as leadDetailsLoader,
} from "./AVA/CRM/LeadDetails";
import Users from "./System/Users";
import Documents from "./AVA/Intranet/Documents";
import PartnerDocuments from "./AVA/Intranet/PartnerDocuments";
import SharedDocuments from "./Shared/SharedDocuments";
import { Variables } from "./System/Variables";
import NewPW, { action as newPasswordAction } from "./NewPW";
import { UserInvite } from "./AVA/User/UserInvite";
import Register, { action as registerAction } from "./Register";
import PushNotifications from "./AVA/Notifications/PushNotifications";
import Calendar from "./Calendar";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <AdminHomePage />,
        errorElement: <Error />,
        loader: checkAuthLoader,
      },
      {
        path: "/user",
        loader: checkAuthLoader,
        handle: {
          crumb: () => <span>User</span>,
        },
        children: [
          {
            path: "/user/profile",
            element: <Profile />,
            handle: {
              crumb: () => <Link to="/user/profile">Profile</Link>,
            },
            loader: profileLoader,
          },
        ],
      },
      {
        path: "/intranet",
        loader: checkAuthLoader,
        handle: {
          crumb: () => <Link to="/">Intranet</Link>,
        },
        children: [
          {
            path: "partners/:partnerId",
            element: <OutletLayout />,
            children: [
              {
                index: true,
                element: <PartnerDetail intranet={true} />,
                loader: partnerDetailLoader,
                handle: {
                  crumb: (data) => (
                    <Link to={`/intranet/partners/${data.partnerDetails.id}`}>
                      {data.partnerDetails.name}
                    </Link>
                  ),
                },
              },
              {
                path: "update",
                element: <OutletLayout />,
                loader: IntranetPartnerLoader,
                handle: {
                  crumb: (data) => (
                    <Link to={`/intranet/partners/${data.partnerData.id}`}>
                      {data.partnerData.name}
                    </Link>
                  ),
                },
                children: [
                  {
                    index: true,
                    element: <PartnerDetailsUpdate />,
                    loader: IntranetPartnerLoader,
                    handle: { crumb: () => <span>Update</span> },
                  },
                ],
              },
            ],
          },
          {
            path: ":partnerId/documents",
            element: <OutletLayout />,
            loader: partnerDetailLoader,
            errorElement: <Error />,
            handle: {
              crumb: (data) => (
                <Link to={`/intranet/${data.partnerDetails.id}/documents`}>
                  Documents
                </Link>
              ),
            },
            children: [
              {
                index: true,
                loader: avaRequestLoader,
                element: <PartnerDocuments />,
              },
            ],
          },
          {
            path: ":partnerId/users",
            element: <OutletLayout />,
            errorElement: <Error />,
            handle: {
              crumb: () => <span>Users</span>,
            },
            children: [
              {
                index: true,
                loader: avaRequestLoader,
                element: <UserInvite type="partner" />,
              },
            ],
          },
          {
            path: "shared/members/",
            element: <OutletLayout />,
            handle: {
              crumb: () => <Link to="/intranet/shared/members">Members</Link>,
            },
            children: [
              {
                index: true,
                element: <Members />,
              },
              {
                path: ":partnerId",
                element: <OutletLayout />,
                children: [
                  {
                    index: true,
                    element: <SharedPartnerDetail />,
                    loader: sharedPartnerLoader,
                    handle: {
                      crumb: (data) => (
                        <Link
                          to={`/intranet/shared/members/${data.partnerDetails.id}`}
                        >
                          {data.partnerDetails.name}
                        </Link>
                      ),
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "shared/sponsors/",
            element: <OutletLayout />,
            handle: {
              crumb: () => <Link to="/intranet/shared/sponsors">Sponsors</Link>,
            },
            children: [
              {
                index: true,
                element: <Sponsors />,
              },
              {
                path: ":partnerId",
                element: <OutletLayout />,
                children: [
                  {
                    index: true,
                    element: <SharedPartnerDetail />,
                    loader: sharedPartnerLoader,
                    handle: {
                      crumb: (data) => (
                        <Link
                          to={`/intranet/shared/sponsors/${data.partnerDetails.id}`}
                        >
                          {data.partnerDetails.name}
                        </Link>
                      ),
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "shared/contacts/",
            element: <Contacts />,
            handle: {
              crumb: () => <Link to="/intranet/shared/contacts">Contacts</Link>,
            },
          },
          {
            path: "shared/locations/",
            element: <Locations />,
            handle: {
              crumb: () => (
                <Link to="/intranet/shared/locations">Locations</Link>
              ),
            },
          },
          {
            path: "shared/job-applicants/",
            element: <SharedJobApplicants />,
            handle: {
              crumb: () => (
                <Link to="/intranet/shared/job-applicants">Job applicants</Link>
              ),
            },
          },
          {
            path: "shared/documents/",
            element: <SharedDocuments />,
            loader: avaRequestLoader,
            handle: {
              crumb: () => (
                <Link to="/intranet/shared/documents">Shared documents</Link>
              ),
            },
          },
        ],
      },
      {
        path: "/av-alliance",
        loader: checkAuthLoader,
        handle: {
          crumb: () => <span>AV Alliance</span>,
        },
        children: [
          {
            path: "intranet/partners/",
            element: <OutletLayout />,
            handle: {
              crumb: () => (
                <Link to="/av-alliance/intranet/partners">Partners</Link>
              ),
            },
            children: [
              {
                index: true,
                element: <PartnerList />,
              },
              {
                path: ":partnerId",
                element: <OutletLayout />,
                children: [
                  {
                    index: true,
                    element: <PartnerDetail />,
                    loader: partnerUpdateLoader,
                    handle: {
                      crumb: (data) => (
                        <Link
                          to={`/av-alliance/intranet/partners/${data.partnerData.id}`}
                        >
                          {data.partnerData.name}
                        </Link>
                      ),
                    },
                  },
                  {
                    path: "update",
                    element: <OutletLayout />,
                    loader: partnerUpdateLoader,
                    handle: {
                      crumb: (data) => (
                        <Link
                          to={`/av-alliance/intranet/partners/${data.partnerData.id}`}
                        >
                          {data.partnerData.name}
                        </Link>
                      ),
                    },
                    children: [
                      {
                        index: true,
                        element: <PartnerUpdate />,
                        loader: partnerUpdateLoader,
                        handle: { crumb: () => <span>Update</span> },
                      },
                    ],
                  },
                  {
                    path: "details/update",
                    element: <OutletLayout />,
                    loader: partnerDetailLoader,
                    handle: {
                      crumb: (data) => (
                        <Link
                          to={`/av-alliance/intranet/partners/${data.partnerDetails.id}`}
                        >
                          {data.partnerDetails.name}
                        </Link>
                      ),
                    },
                    children: [
                      {
                        index: true,
                        element: <PartnerDetailsUpdate />,
                        loader: partnerDetailLoader,
                        handle: {
                          crumb: () => <span>Update Details</span>,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: "create",
                element: <NewPartner />,
                loader: newPartnerLoader,
                handle: { crumb: () => <span>Add new partner</span> },
              },
            ],
          },
          {
            path: "intranet/services",
            element: <OutletLayout />,
            errorElement: <Error />,
            handle: {
              crumb: () => (
                <Link to="/av-alliance/intranet/services">Services</Link>
              ),
            },
            children: [
              {
                index: true,
                element: <Services />,
              },
            ],
          },
          {
            path: "intranet/currencies",
            element: <OutletLayout />,
            errorElement: <Error />,
            handle: {
              crumb: () => (
                <Link to="/av-alliance/intranet/currencies">Currencies</Link>
              ),
            },
            children: [
              {
                index: true,
                element: <Currencies />,
              },
            ],
          },
          {
            path: "intranet/job-applicants",
            element: <OutletLayout />,
            errorElement: <Error />,
            handle: {
              crumb: () => (
                <Link to="/av-alliance/intranet/job-applicants">
                  Job Applicants
                </Link>
              ),
            },
            children: [
              {
                index: true,
                element: <JobApplicants />,
              },
            ],
          },
          {
            path: "intranet/documents",
            element: <OutletLayout />,
            errorElement: <Error />,
            handle: {
              crumb: () => (
                <Link to="/av-alliance/intranet/documents">Documents</Link>
              ),
            },
            children: [
              {
                index: true,
                loader: avaRequestLoader,
                element: <Documents />,
              },
            ],
          },
          {
            path: "users",
            element: <OutletLayout />,
            errorElement: <Error />,
            handle: {
              crumb: () => <Link to="/av-alliance/users">Users</Link>,
            },
            children: [
              {
                index: true,
                loader: avaRequestLoader,
                element: <UserInvite type="AVA" />,
              },
            ],
          },
          {
            path: "accounts",
            element: <Users />,
            errorElement: <Error />,
            handle: {
              crumb: () => <Link to="/av-alliance/accounts">Accounts</Link>,
            },
          },
        ],
      },
      {
        path: "/crm",
        loader: checkAuthLoader,
        handle: { crumb: () => <span>CRM</span> },
        element: <OutletLayout />,
        children: [
          {
            path: "requests",
            loader: avaRequestLoader,
            element: <Requests type="AVA" />,
            handle: { crumb: () => <Link to={"/crm/requests"}>Requests</Link> },
          },
          {
            path: "leads",
            element: <OutletLayout />,
            children: [
              {
                index: true,
                element: <Leads type="AVA" />,
                handle: { crumb: () => <Link to={"/crm/leads"}>Leads</Link> },
              },
            ],
          },
          {
            path: ":partnerId/leads/:leadId",
            element: <OutletLayout />,
            children: [
              {
                index: true,
                loader: leadDetailsLoader,

                element: <LeadDetails />,
              },
            ],
          },
          {
            path: "events",
            loader: eventsLoader,
            element: <Events type="AVA" />,
            handle: { crumb: () => <Link to={"/crm/events"}>Events</Link> },
          },
          {
            path: ":partnerId",
            children: [
              {
                path: "requests",
                loader: memberRequestLoader,
                element: <Requests type="partner" />,
                handle: { crumb: () => <span>Requests</span> },
              },
              {
                path: "leads",
                element: <OutletLayout />,
                children: [
                  {
                    index: true,
                    element: <Leads type="partner" />,
                    handle: { crumb: () => <span>Leads</span> },
                  },
                  {
                    path: ":leadId",
                    element: <OutletLayout />,
                    children: [
                      {
                        index: true,
                        loader: leadDetailsLoader,

                        element: <LeadDetails />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "events",
                loader: eventsLoader,
                element: <Events type="partner" />,
                handle: { crumb: () => <span>Events</span> },
              },
            ],
          },
        ],
      },
      {
        path: "/system",
        loader: rootLoader,
        handle: {
          crumb: () => <span>Root</span>,
        },
        children: [
          {
            path: "users",
            element: <Users />,
            handle: {
              crumb: () => <Link to={"/system/users"}>Users</Link>,
            },
          },
          {
            path: "variables",
            element: <Variables />,
            handle: {
              crumb: () => <Link to={"/system/variables"}>Variables</Link>,
            },
          },
        ],
      },
      {
        path: "/pushnotifications",
        element: <PushNotifications />,
        errorElement: <Error />,
        loader: avaRequestLoader,
        handle: {
          crumb: () => (
            <Link to={"/pushnotifications"}>Push notifications</Link>
          ),
        },
      },
      {
        path: "/calendar",
        element: <Calendar />,
        errorElement: <Error />,
      },
    ],
  },

  {
    path: "/login",
    element: <Login />,
    action: loginAction,
    errorElement: <Error />,
    loader: loginCheck,
  },
  {
    path: "/reset-password",
    element: <Forgot />,
    action: forgotAction,
    errorElement: <Error />,
  },
  {
    path: "/new-password/:token",
    element: <NewPW />,
    action: newPasswordAction,
    errorElement: <Error />,
  },
  {
    path: "/register/:token",
    element: <Register />,
    action: registerAction,
    errorElement: <Error />,
  },
  { path: "logout", action: logoutAction },
]);

export default router;

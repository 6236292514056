import { useEffect, useContext, useState } from "react";
import styles from "./UserEditPage.module.css";
import { Form } from "react-router-dom";
import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";
import FormDataContext from "../../context/formData-context";
import { getAuthToken } from "../../util/auth";
import { fetchRequest } from "../../util/fetchRequest";
import Modal from "../../UI/Modal/Modal";
import ModalContext from "../../context/modal-context";
import * as Hi2Icons from "react-icons/hi2";
import Password from "../../UI/Input/Password";

function UserEditPage(props) {
  const method = props.method;
  const token = getAuthToken();
  const URL = process.env.REACT_APP_API_URL + `user/accounts/` + props.userId;
  const headers = { Authorization: `Bearer ${token}` };

  const dataCtx = useContext(FormDataContext);

  const modalCtx = useContext(ModalContext);

  useEffect(() => {
    dataCtx.editFromData({});
  }, []);

  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchRequest(URL, "GET", headers, undefined);
      setData(response);
    };
    if (method === "PUT") {
      fetchData();
    }
  }, []);

  useEffect(() => {
    let initialValues = {
      firstName: "",
      lastName: "",
      email: "",
      imageURL: "",
      password: null,
    };
    let URLsuffix = "";

    if (method === "PUT") {
      initialValues = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        imageURL: data.imageURL,
        password: null,
      };
      URLsuffix = data.id;
    }

    dataCtx.editFromData(initialValues);

    const requestData = {
      url: process.env.REACT_APP_API_URL + "user/accounts/" + URLsuffix,
      method: method,
      headers: { Authorization: `Bearer ${token}` },
    };

    dataCtx.editRequestData(requestData);

    dataCtx.editRequestData(requestData);
  }, [data, method]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      if (emailIsTouched) {
        setEmailIsTouched(false);
      }
    }

    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    return regex.test(email);
  };

  const [emailIsTouched, setEmailIsTouched] = useState(false);
  const [passwordIsTouched, setPasswordIsTouched] = useState(false);

  const handleEmailBlur = () => {
    setEmailIsTouched(true);
    handleInputChange({
      target: {
        name: "email",
        value: dataCtx.formData.email,
      },
    });
  };

  const handlePasswordBlur = () => {
    setPasswordIsTouched(true);
    handleInputChange({
      target: {
        name: "password",
        value: dataCtx.formData.password,
      },
    });
  };

  const isEmailValid =
    !emailIsTouched ||
    dataCtx.formData.email.trim() === "" ||
    validateEmail(dataCtx.formData.email);

  const isPasswordValid =
    !passwordIsTouched || dataCtx.formData.password?.length > 0;

  const submitHandler = async () => {
    await dataCtx.fetchData();

    props.refresh();
    modalCtx.showModal();
  };

  const title = method === "PUT" ? "Edit" : method === "POST" ? "Create" : "";

  return (
    <Modal>
      <div className={styles.container}>
        <div className={styles.title}>
          <h2>{title} user</h2>
          <Hi2Icons.HiOutlineXMark
            className={styles["close-button"]}
            onClick={() => {
              modalCtx.showModal();
              dataCtx.editFromData({});
            }}
          />
        </div>
        <Form>
          <div className={styles.content}>
            <Input
              label="First name"
              id="firstName"
              name="firstName"
              value={dataCtx.formData.firstName || ""}
              onChange={handleInputChange}
              autoFocus={true}
            />
            <Input
              label="Last name"
              id="lastName"
              name="lastName"
              value={dataCtx.formData.lastName || ""}
              onChange={handleInputChange}
            />
            <Input
              type="email"
              label="Email address"
              id="email"
              name="email"
              className={styles.emailInput}
              value={dataCtx.formData.email || ""}
              onChange={handleInputChange}
              onBlur={handleEmailBlur}
            />
            {!isEmailValid && (
              <p className={styles.error}>Please enter a valid email.</p>
            )}
            <Password
              className={styles["login-password"]}
              id="password"
              label="Password"
              name="password"
              onChange={handleInputChange}
              onBlur={handlePasswordBlur}
            />
            {!isPasswordValid && (
              <p className={styles.error}>Please enter a valid password.</p>
            )}
            <div className={styles["buttons"]}>
              <Button
                type="submit"
                className={styles["submit-button"]}
                onClick={submitHandler}
                disabled={!isEmailValid || !isPasswordValid}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default UserEditPage;

import { useContext, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "./SubMenu.module.css";
import MenuContext from "./context/sidebar-context";

const SubMenu = ({ item, menuKey }) => {
  const menuCtx = useContext(MenuContext);

  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  const closeHandler = () => {
    menuCtx.showSidebar();
  };

  const clickHandler = () => {
    if (item.subNav) {
      showSubnav();
    } else {
      menuCtx.showSidebar();
    }
  };

  return (
    <>
      <Link
        className={styles["sidebar-link"]}
        to={item.path}
        onClick={clickHandler}
      >
        <div className={styles["subnav-title"]}>
          {item.icon}
          <span className={styles["sidebar-label"]}>{item.title}</span>
        </div>
        <div className={styles["subnav-title"]}>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </Link>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${styles["dropdown-link"]} ${styles.active}`
                  : styles["dropdown-link"]
              }
              to={item.path}
              key={index}
              onClick={closeHandler}
            >
              {item.icon}
              <span className={styles["sidebar-label"]}>{item.title}</span>
            </NavLink>
          );
        })}
    </>
  );
};

export default SubMenu;

import { useState } from "react";
import Modal from "../Modal/Modal";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../util/cropImage";
import Button from "../Button/Button";
import Slider from "../Slider/Slider";
import { useContext } from "react";
import ModalContext from "../../context/modal-context";
import styles from "./Crop.module.css";

const Crop = ({ photoURL, setPhotoURL, setFile, handleSubmit }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const ctx = useContext(ModalContext);

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    try {
      const { file, url } = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation
      );
      setPhotoURL(url);
      setFile(file);
    } catch (error) {
      console.log(error);
    }

    ctx.showModal();
  };

  const cancelHandler = () => {
    setFile(null);
    setPhotoURL(null);
    ctx.showModal();
  };

  const zoomPercent = (value) => {
    return `${Math.round(value * 100)}%`;
  };
  return (
    <Modal className={styles["crop-modal"]}>
      <div className={styles.cropperContainer}>
        <Cropper
          classes={{
            containerClassName: styles.cropper,
          }}
          image={photoURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
        />
      </div>
      <div>
        <div className={styles.editContainer}>
          <div className={styles.sliders}>
            <p className={styles.sliderLabel}>Zoom: {zoomPercent(zoom)}</p>
            <Slider
              min={1}
              max={3}
              step={0.01}
              value={zoom}
              onChange={(zoom) => setZoom(zoom)}
            />
          </div>
          <div className={styles.sliders}>
            <p className={styles.sliderLabel}>Rotation: {rotation + "°"}</p>
            <Slider
              min={0}
              max={360}
              step={1}
              value={rotation}
              onChange={(rotation) => setRotation(rotation)}
            />
          </div>
        </div>
        <div className={styles.cropButtons}>
          <Button onClick={cancelHandler}>Cancel</Button>
          <Button onClick={cropImage}>Crop</Button>
        </div>
      </div>
    </Modal>
  );
};

export default Crop;

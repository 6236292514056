import PartnerUpdatePage from "../../../components/AVA/Intranet/Partners/PartnerUpdatePage";
import { getAuthToken } from "../../../components/util/auth";
import { fetchRequest } from "../../../components/util/fetchRequest";
import { json } from "react-router-dom";

function PartnerUpdate() {
  return <PartnerUpdatePage method="PUT" />;
}

export default PartnerUpdate;

export async function loader({ request, params }) {
  const id = params.partnerId;
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  const fetchPartnerData = async () => {
    const partnerURL = process.env.REACT_APP_API_URL + `ava/partners/` + id;
    const method = "GET";

    const response = await fetchRequest(partnerURL, method, headers, undefined);

    return response;
  };

  const fetchPartnerDetails = async () => {
    const partnerURL =
      process.env.REACT_APP_API_URL + `intranet/partners/` + id + `/info`;
    const method = "GET";

    const response = await fetchRequest(partnerURL, method, headers, undefined);

    return response;
  };

  const fetchCurrenciesOptions = async () => {
    const URL = process.env.REACT_APP_API_URL + "ava/currencies";
    const response = await fetchRequest(URL, "GET", headers, undefined);
    const options = response.items.map((d) => ({
      value: d.id,
      label: d.name,
    }));
    return options;
  };

  const fetchUsersOptions = async () => {
    const URL =
      process.env.REACT_APP_API_URL + "user/accounts?page=1&size=1000";

    const response = await fetchRequest(URL, "GET", headers, undefined);
    const options = response.items.map((d) => ({
      value: d.id,
      label: d.email,
    }));
    return options;
  };

  const fetchServicesOptions = async () => {
    const URL = process.env.REACT_APP_API_URL + "ava/services";
    const response = await fetchRequest(URL, "GET", headers, undefined);
    const options = response.items.map((d) => ({
      value: d.id,
      label: d.name,
    }));
    return options;
  };

  const [
    partnerData,
    currenciesOptions,
    usersOptions,
    partnerDetails,
    servicesOptions,
  ] = await Promise.all([
    fetchPartnerData(),
    fetchCurrenciesOptions(),
    fetchUsersOptions(),
    fetchPartnerDetails(),
    fetchServicesOptions(),
  ]);
  return json({
    partnerData,
    currenciesOptions,
    usersOptions,
    partnerDetails,
    servicesOptions,
  });
}

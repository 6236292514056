import { redirect, useActionData } from "react-router-dom";
import LoginFrom from "../components/LoginForm";
import { fetchRequest } from "../components/util/fetchRequest";

function Login() {
  const errors = useActionData();

  return (
    <>
      <LoginFrom error={errors} />
    </>
  );
}

export default Login;

export async function action({ request }) {
  try {
    const data = await request.formData();
    const authData = {
      email: data.get("email"),
      password: data.get("password"),
    };

    const URL = process.env.REACT_APP_API_URL + "user/auth";

    const method = "POST";
    const headers = {
      "Content-type": "application/json",
      Accept: "application/json",
    };

    const resData = await fetchRequest(URL, method, headers, authData);
    const userId = resData.account.id;
    const token = resData.token.value;
    const email = resData.account.email;
    const imageKey = resData.account.imageKey;

    if (resData.account.firstName || resData.account.lastName) {
      const name = `${resData.account.firstName} ${resData.account.lastName}`;
      localStorage.setItem("name", name);
    }

    if (resData.account.position) {
      const position = resData.account.position;
      localStorage.setItem("position", position);
    }

    localStorage.setItem("token", token);
    localStorage.setItem("email", email);
    localStorage.setItem("userId", userId);
    localStorage.setItem("imageKey", imageKey);

    const roles = resData.roles.map((item) => item.key);

    localStorage.setItem("roles", JSON.stringify(roles));

    const ownURL = process.env.REACT_APP_API_URL + "intranet/partners?own=true";
    const ownHeaders = { Authorization: `Bearer ${token}` };

    const ownResData = await fetchRequest(ownURL, "GET", ownHeaders, undefined);
    const ownCompany = ownResData.items.map((item) => ({
      id: item.id,
      kind: item.kind,
      name: item.name,
    }));

    localStorage.setItem("company", JSON.stringify(ownCompany));

    return redirect("/");
  } catch (error) {
    let errorMessage = "";
    if (error.message === "403") {
      errorMessage = "Invalid username or password! Please try again!";
    } else {
      errorMessage = "An error occurred. Please try again later.";
    }
    return errorMessage;
  }
}

import { Form, useNavigation, Link, useParams } from "react-router-dom";

import Button from "./UI/Button/Button";
import Card from "./UI/Card/Card";
import Footer from "./UI/Footer/Footer";
import Input from "./UI/Input/Input";
import styles from "./RegisterNewUser.module.css";
import useInput from "../hooks/use-input";
import Password from "./UI/Input/Password";

function RegisterNewUser(props) {
  const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

  const registerError = props.error;
  const params = useParams();

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput((value) => regex.test(value));

  const {
    value: enteredPassword,
    isValid: enteredPasswordIsValid,
    valueChangeHandler: passwordChangedHandler,
    inputBlurHandler: passwordBlurHandler,
  } = useInput((value) => value.trim().length > 0);

  const {
    value: enteredFirstName,
    isValid: enteredFirstNameIsValid,
    valueChangeHandler: firstNameChangedHandler,
    inputBlurHandler: firstNameBlurHandler,
  } = useInput((value) => value.trim().length > 0);

  const {
    value: enteredLastName,
    isValid: enteredLastNameIsValid,
    valueChangeHandler: lastNameChangedHandler,
    inputBlurHandler: lastNameBlurHandler,
  } = useInput((value) => value.trim().length > 0);

  let formIsValid = false;

  if (
    enteredPasswordIsValid &&
    enteredEmailIsValid &&
    enteredFirstNameIsValid &&
    enteredLastNameIsValid
  ) {
    formIsValid = true;
  }

  const emailInputClasses = emailInputHasError ? styles.invalid : null;

  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  return (
    <>
      <picture>
        <source
          srcSet="/site-logo_dark.png"
          media="(prefers-color-scheme:dark)"
        />
        <img
          className={styles.img}
          src={`/site-logo_light.png`}
          alt="AV Alliance logo"
          height="25"
        />
      </picture>
      <h2 className={styles.h2}>Intranet</h2>
      <p className={styles.note}>Please register your user account.</p>
      <Card className={styles.login}>
        <Form method="post" action={`/register/${params.token}`}>
          <Input
            type="text"
            id="firstName"
            label="First name"
            name="firstName"
            onChange={firstNameChangedHandler}
            onBlur={firstNameBlurHandler}
            value={enteredFirstName}
            required
          />
          <Input
            type="text"
            id="lastName"
            label="Last name"
            name="lastName"
            onChange={lastNameChangedHandler}
            onBlur={lastNameBlurHandler}
            value={enteredLastName}
            required
          />
          <Input
            type="email"
            id="emailaddress"
            label="Email address"
            name="email"
            class={emailInputClasses}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            value={enteredEmail}
            required
          />
          {emailInputHasError && (
            <p className={styles.error}>Please enter a valid email.</p>
          )}
          <Password
            className={styles["login-password"]}
            id="password"
            label="Password"
            name="password"
            onChange={passwordChangedHandler}
            onBlur={passwordBlurHandler}
            value={enteredPassword}
            required
          />

          {registerError && (
            <p className={`${styles.error} ${styles.warning}`}>{props.error}</p>
          )}
          <Button type="submit" disabled={!formIsValid || isSubmitting}>
            {isSubmitting ? "Registering..." : "Registration"}
          </Button>
        </Form>
      </Card>
      <Footer />
    </>
  );
}
export default RegisterNewUser;

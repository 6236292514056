import React, { useEffect, useState } from "react";
import Modal from "../../../UI/Modal/Modal";
import { useContext } from "react";
import FormDataContext from "../../../context/formData-context";
import styles from "./ContactEditPage.module.css";
import Button from "../../../UI/Button/Button";
import ModalContext from "../../../context/modal-context";
import { getAuthToken } from "../../../util/auth";
import { fetchRequest } from "../../../util/fetchRequest";
import { Form, useParams } from "react-router-dom";
import Input from "../../../UI/Input/Input";
import PhoneInputField from "../../../UI/Input/PhoneInputField";
import CheckBoxSimple from "../../../UI/Input/CheckBoxSimple";
import * as Hi2Icons from "react-icons/hi2";
import MultiSelect from "../../../UI/Input/MultiSelect";
import { Img } from "react-image";
import CheckBox from "../../../UI/Input/CheckBox";

function ContactEditPage(props) {
  const dataCtx = useContext(FormDataContext);
  const modalCtx = useContext(ModalContext);
  const param = useParams();
  const method = props.method;
  const token = getAuthToken();

  const URL =
    process.env.REACT_APP_API_URL +
    `intranet/partners/` +
    param.partnerId +
    `/contacts/${props.contactId}`;
  const headers = { Authorization: `Bearer ${token}` };

  const [checkboxes, setCheckboxes] = useState([
    {
      id: "visibleToSponsors",
      label: "Visible to sponsors",
      name: "visibleToSponsors",
      checked: false,
    },
    {
      id: "newsletterSubscriptions",
      label: "Subscribe to newsletters",
      name: "newsletterSubscriptions",
      checked: false,
    },
  ]);
  useEffect(() => {
    dataCtx.editFromData({});
  }, []);

  const [data, setData] = useState({});

  const [file, setFile] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const locationsURL =
          process.env.REACT_APP_API_URL +
          `intranet/partners/` +
          param.partnerId +
          `/locations`;

        const res = await fetchRequest(locationsURL, "GET", headers, undefined);
        const options = res.items.map((d) => ({
          value: d.id,
          label: `${d.country}, ${d.city}, ${d.address}`,
        }));
        setLocationOptions(options);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    const fetchData = async () => {
      try {
        const locationsURL =
          process.env.REACT_APP_API_URL +
          `intranet/partners/` +
          param.partnerId +
          `/locations`;

        const res = await fetchRequest(locationsURL, "GET", headers, undefined);
        const options = res.items.map((d) => ({
          value: d.id,
          label: `${d.country}, ${d.city}, ${d.address}`,
        }));
        setLocationOptions(options);
        const response = await fetchRequest(URL, "GET", headers, undefined);
        setData(response);

        setCheckboxes((prevCheckboxes) => {
          return prevCheckboxes.map((checkbox) => {
            return {
              ...checkbox,
              checked: response[checkbox.name] || false,
            };
          });
        });
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchOptions();
    if (method === "PUT") {
      fetchData();
    }
  }, []);

  let defaultLocationValues = [];

  if (method === "PUT" && !isLoading) {
    defaultLocationValues = data.locations.map((value) => {
      return locationOptions.find((option) => option.value === value);
    });
  }

  useEffect(() => {
    let initialValues = {
      name: "",
      position: "",
      phone: "",
      email: "",
      web: "",
      linkedIn: "",
      visibleToSponsors: false,
      newsletterSubscriptions: false,
      locations: [],
    };
    let URLsuffix = "";

    if (method === "PUT") {
      initialValues = {
        name: data.name,
        position: data.position,
        phone: data.phone,
        email: data.email,
        web: data.web,
        linkedIn: data.linkedIn,
        visibleToSponsors: data.visibleToSponsors,
        newsletterSubscriptions: data.newsletterSubscriptions,
        locations: data.locations,
      };
      URLsuffix = "/" + data.id;
    }

    const requestData = {
      url:
        process.env.REACT_APP_API_URL +
        "intranet/partners/" +
        param.partnerId +
        "/contacts" +
        URLsuffix,
      method: method,
      headers: { Authorization: `Bearer ${token}` },
    };

    dataCtx.editFromData(initialValues);
    dataCtx.editRequestData(requestData);
  }, [data]);

  const handleCheckboxChange = (event, checkboxId) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.id === checkboxId
        ? { ...checkbox, checked: event.target.checked }
        : checkbox
    );
    setCheckboxes(updatedCheckboxes);

    const updatedFormValues = {
      ...dataCtx.formData,
      [checkboxId]: event.target.checked,
    };
    dataCtx.editFromData(updatedFormValues);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      if (isTouched) {
        setIsTouched(false);
      }
    }

    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    return regex.test(email);
  };

  const [isTouched, setIsTouched] = useState(false);

  const handleEmailBlur = () => {
    setIsTouched(true);
    handleInputChange({
      target: {
        name: "email",
        value: dataCtx.formData.email,
      },
    });
  };

  const isEmailValid =
    !isTouched ||
    dataCtx.formData.email.trim() === "" ||
    validateEmail(dataCtx.formData.email);

  const handlePhoneChange = (value) => {
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      phone: value,
    }));
  };

  const handleMultiSelectChange = (selectedOptions, fieldName) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      [fieldName]: selectedValues,
    }));
  };

  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsDisabled(true);
      setFile(file);
    }
  };

  const submitHandler = async () => {
    await dataCtx.fetchData();
    await photoAction();

    props.refresh();
    modalCtx.showModal();
  };

  const photoAction = async () => {
    if (file) {
      const URL =
        process.env.REACT_APP_API_URL +
        `intranet/partners/${param.partnerId}/contacts/${data.id}/image?fileName=${file.name}`;
      fetch(URL, {
        method: "POST",
        body: file,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).catch((err) => {
        console.error(err);
      });
    } else if (data.imageKey && !file && isChecked) {
      const URL =
        process.env.REACT_APP_API_URL +
        `intranet/partners/${param.partnerId}/contacts/${data.id}/image`;
      fetchRequest(URL, "DELETE", headers, undefined)
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handlePhotoDeleteChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const title = method === "PUT" ? "Edit" : method === "POST" ? "Create" : "";

  return (
    <Modal>
      {(!isLoading || method !== "PUT") && (
        <div className={styles.container}>
          <div className={styles.title}>
            <h2>{title} contact</h2>

            <Hi2Icons.HiOutlineXMark
              className={styles["close-button"]}
              onClick={() => {
                modalCtx.showModal();
                dataCtx.editFromData({});
              }}
            />
          </div>
          <Form>
            <div className={styles.content}>
              <h3 className={styles["sub-title"]}>Basic Info</h3>
              <div className={styles.basic}>
                <div className={styles["basic-details"]}>
                  <div className={styles["photo-contanier"]}>
                    {data.imageKey ? (
                      <Img
                        className={styles["contact-photo"]}
                        src={
                          process.env.REACT_APP_API_URL +
                          `file/download?key=${data.imageKey}`
                        }
                        alt="Contact phot"
                        height={150}
                        loader={<img src="/spinner.gif" alt="loading..."></img>}
                      />
                    ) : (
                      <div>
                        <span>Photo</span>
                        <p>-</p>
                      </div>
                    )}
                  </div>
                  <Input
                    accept="image/*"
                    type="file"
                    label="Upload photo"
                    id="imageKey"
                    name="imageKey"
                    onChange={handleFileChange}
                  />
                  <CheckBox
                    id="deletePhoto"
                    label="Delete Photo"
                    name="Delete Photo"
                    description="If you checked and cilck Save, the uploaded photo will be deleted"
                    onChange={handlePhotoDeleteChange}
                    checked={isChecked}
                    disabled={isDisabled}
                  />
                  <Input
                    label="Name"
                    id="name"
                    name="name"
                    value={dataCtx.formData.name || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <Input
                  label="Position"
                  id="position"
                  name="position"
                  value={dataCtx.formData.position || ""}
                  onChange={handleInputChange}
                />
              </div>
              <h3 className={styles["sub-title"]}>Contact details</h3>
              <div className={styles.contact}>
                <PhoneInputField
                  id="phone"
                  name="phone"
                  label="Phone number"
                  title="phone"
                  startValue={data.phone}
                  value={dataCtx.formData.phone}
                  onChange={handlePhoneChange}
                />
                <div>
                  <Input
                    label="Email"
                    type="email"
                    id="email"
                    name="email"
                    value={dataCtx.formData.email || ""}
                    onChange={handleInputChange}
                    onBlur={handleEmailBlur}
                  />
                  {!isEmailValid && (
                    <p className={styles.error}>Please enter a valid email.</p>
                  )}
                </div>
                <div>
                  <Input
                    label="Web"
                    id="web"
                    name="web"
                    value={dataCtx.formData.web || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <Input
                    label="LinkedIn"
                    id="linkedIn"
                    name="linkedIn"
                    value={dataCtx.formData.linkedIn || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <h3 className={styles["sub-title"]}>Other details</h3>
              <div className={styles.other}>
                {checkboxes.map((checkbox) => (
                  <CheckBoxSimple
                    key={checkbox.id}
                    label={checkbox.label}
                    id={checkbox.id}
                    name={checkbox.name}
                    description={checkbox.description}
                    checked={checkbox.checked}
                    onChange={(event) =>
                      handleCheckboxChange(event, checkbox.id)
                    }
                  />
                ))}
              </div>
              <MultiSelect
                id="locations"
                name="locations"
                label="Location"
                menuPlacement="top"
                options={locationOptions}
                defaultValue={defaultLocationValues}
                value={dataCtx.formData.locations}
                onMultiChange={handleMultiSelectChange}
              />
              <div className={styles["buttons"]}>
                <Button
                  type="submit"
                  className={styles["submit-button"]}
                  onClick={submitHandler}
                  disabled={!isEmailValid}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Modal>
  );
}

export default ContactEditPage;

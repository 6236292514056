import * as Hi2Icons from "react-icons/hi2";

export const SystemSidebarData = [
  {
    title: "System",
    path: "#",
    icon: <Hi2Icons.HiOutlineCog8Tooth />,
    iconClosed: <Hi2Icons.HiOutlineChevronRight className="subnavArrow" />,
    iconOpened: <Hi2Icons.HiOutlineChevronDown className="subnavArrow" />,

    subNav: [
      {
        title: "Users",
        path: "/system/users",
        icon: <Hi2Icons.HiOutlineUsers />,
      },
      {
        title: "Variables",
        path: "/system/variables",
        icon: <Hi2Icons.HiOutlineVariable />,
      },
    ],
  },
];

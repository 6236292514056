import React, { useEffect, useState } from "react";
import styles from "./LocationFinder.module.css";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Autocomplete,
} from "@react-google-maps/api";
import Input from "./Input";
import Button from "../Button/Button";

const containerStyle = {
  height: "400px",
  borderRadius: "5px",
  marginTop: "20px",
};

const googleMapsLibraries = ["places"];

function LocationFinder({
  onMarkerClick,
  onLocationFind,
  defaultLocation,
  onDelete,
  latitude,
  longitude,
}) {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const handleThemeChange = (event) => {
      setIsDarkMode(event.matches);
    };

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(mediaQuery.matches);
    mediaQuery.addListener(handleThemeChange);

    return () => {
      mediaQuery.removeListener(handleThemeChange);
    };
  }, []);

  const mapStyles = isDarkMode
    ? [
        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#17263c" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }],
        },
      ]
    : [];

  const googleMapsOptions = {
    gestureHandling: "cooperative",
    controlSize: 25,
    styles: mapStyles,
  };
  const initialCenter = {
    lat: 47.4384,
    lng: 8.46502,
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: googleMapsLibraries,
  });

  useEffect(() => {
    if (latitude && longitude) {
      setMarkerPosition({
        lat: latitude,
        lng: longitude,
      });

      map?.panTo({ lat: latitude, lng: longitude });
    }
  }, [latitude, longitude]);

  const [map, setMap] = useState(/** @type google.maps.Map */ null);
  const [center, setCenter] = useState(defaultLocation || initialCenter);
  const [markerPosition, setMarkerPosition] = useState(defaultLocation);
  const [searchResult, setSearchResult] = useState(null);

  const handleMapClick = (event) => {
    const clickedLatLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setMarkerPosition(clickedLatLng);
    onMarkerClick(clickedLatLng);
    console.log(markerPosition);
  };

  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  function onPlaceChanged() {
    const place = searchResult?.getPlace();
    if (place?.geometry) {
      map.panTo(place?.geometry.location.toJSON());
      setMarkerPosition(place?.geometry.location.toJSON());
      onLocationFind(place?.geometry.location.toJSON());
      console.log(place?.geometry.location.toJSON());
    }
  }

  const clearLocation = () => {
    setCenter(initialCenter);
    setMarkerPosition(null);
    onDelete();
  };

  return isLoaded ? (
    <>
      <div className={styles["location-control"]}>
        <Autocomplete
          className={styles["location-autocomplete"]}
          onPlaceChanged={onPlaceChanged}
          onLoad={onLoad}
        >
          <Input
            type="text"
            note={"Enter coordinates or search for your location."}
            placeholder={"Search on Google Maps..."}
            className={styles.search}
          />
        </Autocomplete>
        <Button className={styles["clear-location"]} onClick={clearLocation}>
          Clear location
        </Button>
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onClick={handleMapClick}
        options={googleMapsOptions}
        onLoad={(map) => setMap(map)}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
    </>
  ) : null;
}

export default LocationFinder;

import { useRouteError } from "react-router-dom";
import ErrorPage from "../components/ErrorPage";

function Error() {
  const error = useRouteError();

  let title = "An error occured!";
  let message = "Something went wrong! Try again!";

  if (error.status === 400) {
    title = "Bad request";
    message = "Something went wrong with the request.";
  }

  if (error.message === "403") {
    title = "Forbidden";
    message = "Forbidden";
  }

  if (error.status === 404) {
    title = "Page not found";
    message = "Sorry, we couldn't find the page you're looking for.";
  }

  return <ErrorPage error={error} title={title} message={message} />;
}
export default Error;

import React, { useState, useEffect, useRef } from "react";
import styles from "./PartnersPage.module.css";
import { useContext } from "react";
import Table from "../../../UI/Table/Table";
import Header from "../../../UI/Table/Header";
import Pagination from "../../../UI/Pagination/Pagination";
import Card from "../../../UI/Card/Card";
import { Link, useNavigate } from "react-router-dom";
import CheckBoxSimple from "../../../UI/Input/CheckBoxSimple";
import SearchBar from "../../../UI/SearchBar/SearchBar";
import Button from "../../../UI/Button/Button";
import ErrorPage from "../../../ErrorPage";
import { useDataFetching } from "../../../../hooks/use-datafetching";
import { getAuthToken } from "../../../util/auth";
import ModalContext from "../../../context/modal-context";
import { fetchRequest } from "../../../util/fetchRequest";
import * as Hi2Icons from "react-icons/hi2";
import Confirm from "../../../UI/Confirm/Confirm";
import SelectCounter from "../../../UI/SelectCounter/SelectCounter";
import EmptyTable from "../../../UI/Table/EmptyTable";
import Tooltip from "../../../UI/Tooltip/Tooltip";

function PartnersPage() {
  const modalCtx = useContext(ModalContext);

  const URLsuffix = "ava/partners";
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  const navigate = useNavigate();

  //* Fetch with sort and search params

  const [currentPage, setCurrentPage] = useState(1);
  const [sortParam, setSortParam] = useState("");
  const [searchParam, setSearchParam] = useState("");

  const { error, pageData, getTableData } = useDataFetching(
    currentPage,
    URLsuffix,
    sortParam,
    searchParam
  );

  const [selectedIds, setSelectedIds] = useState([]);

  const [deleteAction, setDeleteAction] = useState(null);

  const [isDelete, setIsDelete] = useState(false);

  //* Search

  const searchRef = useRef(null);

  const handleSearch = (event) => {
    event.preventDefault();
    const searchValue = searchRef.current.value;
    setSearchParam(`&search=${searchValue}`);
  };

  const clearSearchHandler = () => {
    setSearchParam("");
  };

  useEffect(() => {
    setCurrentPage(1);
    getTableData();
  }, [searchParam]);

  //* Sorting

  const sortPartner = (accessor, order) => {
    setSortParam(`&sort=${accessor}&order=${order}`);
  };

  useEffect(() => {
    getTableData();
  }, [sortParam]);

  //* Handlers

  const [allSelectedIds, setAllSelectedIds] = useState([]);

  const prevSelectedIdsRef = useRef([]);

  useEffect(() => {
    setSelectedIds(prevSelectedIdsRef.current);
  }, []);

  const handleSelectAll = (event) => {
    const checked = event.target.checked;

    if (checked) {
      const currentPageIds = pageData.rowData.map((item) => item.id);
      setSelectedIds(currentPageIds);
      setAllSelectedIds((prevAllSelectedIds) => {
        const newSelectedIds = [
          ...new Set([...prevAllSelectedIds, ...currentPageIds]),
        ];
        return newSelectedIds;
      });
    } else {
      setSelectedIds([]);
      setAllSelectedIds((prevAllSelectedIds) => {
        const currentPageIds = pageData.rowData.map((item) => item.id);
        const updatedSelectedIds = prevAllSelectedIds.filter(
          (selectedId) => !currentPageIds.includes(selectedId)
        );
        return updatedSelectedIds;
      });
    }
  };

  useEffect(() => {
    if (pageData.rowData.length && allSelectedIds.length) {
      const currentPageIds = pageData.rowData.map((item) => item.id);
      const updatedSelectedIds = allSelectedIds.filter((selectedId) =>
        currentPageIds.includes(selectedId)
      );
      setSelectedIds(updatedSelectedIds);
    } else {
      setSelectedIds([]);
    }
  }, [currentPage, pageData.rowData, allSelectedIds]);

  const handleSelectSingle = (event, id) => {
    const checked = event.target.checked;
    let updatedSelectedIds = [...selectedIds];
    let updatedAllSelectedIds = [...allSelectedIds];

    if (checked) {
      updatedSelectedIds.push(id);
      updatedAllSelectedIds.push(id);
    } else {
      updatedSelectedIds = updatedSelectedIds.filter(
        (selectedId) => selectedId !== id
      );
      updatedAllSelectedIds = updatedAllSelectedIds.filter(
        (selectedId) => selectedId !== id
      );
    }

    setSelectedIds(updatedSelectedIds);
    setAllSelectedIds(updatedAllSelectedIds);
  };

  const clearSelectionHandler = () => {
    setAllSelectedIds([]);
  };

  const deleteSelectedHandler = async () => {
    const URL = process.env.REACT_APP_API_URL + "ava/partners/";
    await fetchRequest(URL, "DELETE", headers, allSelectedIds);
    setSelectedIds([]);
    setAllSelectedIds([]);
    getTableData();
  };

  const deleteHandler = (id) => {
    setIsDelete(true);
    modalCtx.showModal();
    setDeleteAction(() => () => deleteFunction(id));
  };

  const multiDeleteHandler = () => {
    setIsDelete(true);
    modalCtx.showModal();
    setDeleteAction(() => deleteSelectedHandler);
  };

  const deleteFunction = async (id) => {
    const URL = process.env.REACT_APP_API_URL + "ava/partners/" + id;
    await fetchRequest(URL, "DELETE", headers, undefined);
    let updatedSelectedIds = [...selectedIds];
    updatedSelectedIds = updatedSelectedIds.filter(
      (selectedId) => selectedId !== id
    );
    setSelectedIds(updatedSelectedIds);
    setAllSelectedIds((prevAllSelectedIds) =>
      prevAllSelectedIds.filter((selectedId) => selectedId !== id)
    );
    getTableData();
  };

  const createHandler = () => {
    navigate(`create`);
  };

  const editHandler = (id) => {
    navigate(`${id}/update`);
  };

  const detailsHandler = (id) => {
    navigate(`${id}`);
  };

  //* Table header
  const columns = [
    {
      Header: (
        <div className={styles["checkbox-header"]}>
          <CheckBoxSimple
            id="name-column"
            name="name-column"
            disabled={pageData.rowData.length === 0}
            checked={
              selectedIds.length === pageData.rowData.length &&
              pageData.rowData.length !== 0
            }
            onChange={handleSelectAll}
          />
          <Header sortHandler={sortPartner} title="Name" id="name" />
        </div>
      ),
      accessor: "name",
      width: "50%",

      Cell: ({ value, row }) => (
        <CheckBoxSimple
          key={row.original.id}
          label={
            <Link className={styles.name} to={`${row.original.id}`}>
              {value}
            </Link>
          }
          id={row.original.id}
          name={row.original.name}
          checked={selectedIds.includes(row.original.id)}
          onChange={(event) => handleSelectSingle(event, row.original.id)}
        />
      ),
    },
    {
      Header: <Header sortHandler={sortPartner} title="Kind" id="kind" />,
      accessor: "kind",
      width: "50%",
    },
    {
      Header: "Actions",
      accessor: "id",
      id: "actions",
      width: "100px",

      Cell: ({ row, value }) => (
        <>
          <Tooltip text={"Open details"}>
            <Hi2Icons.HiOutlineInformationCircle
              className={styles["details-icon"]}
              onClick={() => detailsHandler(row.original.id)}
            />
          </Tooltip>
          <Tooltip text={"Edit partner"}>
            <Hi2Icons.HiOutlinePencil
              className={styles["edit-icon"]}
              onClick={() => editHandler(row.original.id)}
            />
          </Tooltip>
          <Tooltip text={"Delete partner"}>
            <Hi2Icons.HiOutlineTrash
              className={styles["delete-icon"]}
              onClick={() => deleteHandler(row.original.id)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      {modalCtx.isModal && isDelete && <Confirm onDelete={deleteAction} />}

      <div className={styles.title}>
        <h1>Partners</h1>
        <p>
          The complete list of members and sponsors for AV Alliance editors.
        </p>
      </div>
      <div className={styles.control}>
        <div className={styles["control-buttons"]}>
          <Button className={styles["create-button"]} onClick={createHandler}>
            New partner
          </Button>
          {allSelectedIds.length > 0 && (
            <>
              <Button
                className={styles["delete-button"]}
                onClick={() => multiDeleteHandler()}
              >
                Delete selected
              </Button>
              <Button
                className={styles["clear-button"]}
                onClick={() => clearSelectionHandler()}
              >
                Clear selection
              </Button>
              <SelectCounter counter={allSelectedIds.length} />
            </>
          )}
        </div>
        <SearchBar
          searchRef={searchRef}
          onSubmit={handleSearch}
          clearSearch={clearSearchHandler}
        />
      </div>
      <Card className={styles.card}>
        {error ? (
          <ErrorPage
            error={error}
            title={error.title}
            message={error.message}
          />
        ) : (
          <>
            <Table
              columns={columns}
              data={pageData.rowData}
              isLoading={pageData.isLoading}
            />
            {pageData.rowData.length === 0 && <EmptyTable />}

            <Pagination
              totalRows={pageData.totalRecords}
              pageChangeHandler={setCurrentPage}
              rowsPerPage={20}
              currentPage={currentPage}
            />
          </>
        )}
      </Card>
    </>
  );
}

export default PartnersPage;

import { Form, useNavigation, Link } from "react-router-dom";

import Button from "./UI/Button/Button";
import Card from "./UI/Card/Card";
import Footer from "./UI/Footer/Footer";
import Input from "./UI/Input/Input";
import styles from "./ForgotPassword.module.css";
import useInput from "../hooks/use-input";

function ForgotPassword(props) {
  const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

  const forgotMessage = props.message;

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput((value) => regex.test(value));

  let formIsValid = false;

  if (enteredEmailIsValid) {
    formIsValid = true;
  }

  const emailInputClasses = emailInputHasError ? styles.invalid : null;

  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  return (
    <>
      <picture>
        <source
          srcSet="/site-logo_dark.png"
          media="(prefers-color-scheme:dark)"
        />
        <img
          className={styles.img}
          src={`/site-logo_light.png`}
          alt="AV Alliance logo"
          height="25"
        />
      </picture>
      <h2 className={styles.h2}>Forgot password?</h2>
      <p className={styles.note}>
        No worries, we'll send you reset instructions.
      </p>
      <Card className={styles.login}>
        <Form method="post" action="/reset-password">
          <Input
            type="email"
            id="email"
            label="Email address"
            name="email"
            class={emailInputClasses}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            value={enteredEmail}
            required
          />
          {emailInputHasError && (
            <p className={styles.error}>Please enter a valid email.</p>
          )}
          {forgotMessage && <p className={styles.message}>{props.message}</p>}
          <Button type="submit" disabled={!formIsValid || isSubmitting}>
            {isSubmitting ? "Reseting..." : "Reset password"}
          </Button>
          <div className={styles.link}>
            <Link to="/login">Back to login</Link>
          </div>
        </Form>
      </Card>
      <Footer />
    </>
  );
}
export default ForgotPassword;

import { createContext, useState } from "react";
import { getAuthToken, getImageKey, getMyCompanies } from "../util/auth";
import { fetchRequest } from "../util/fetchRequest";

const UserContext = createContext({
  imageKey: null,
  myCompanies: null,
  getNewImageKey: () => {},
  getNewCompanies: () => {},
  setImageKey: () => {},
  setMyCompanies: () => {},
});

export const UserContextProvider = (props) => {
  const defaultImageKey = getImageKey();
  const defaultCompanies = getMyCompanies();
  const [imageKey, setImageKey] = useState(defaultImageKey);
  const [myCompanies, setMyCompanies] = useState(defaultCompanies);

  const getNewImageKey = () => {
    const newImageKey = getImageKey();
    setImageKey(newImageKey);
  };

  const getNewCompanies = async () => {
    const token = getAuthToken();
    const ownURL = process.env.REACT_APP_API_URL + "intranet/partners?own=true";
    const ownHeaders = { Authorization: `Bearer ${token}` };

    const ownResData = await fetchRequest(ownURL, "GET", ownHeaders, undefined);
    const ownCompany = ownResData.items.map((item) => ({
      id: item.id,
      kind: item.kind,
      name: item.name,
    }));

    localStorage.setItem("company", JSON.stringify(ownCompany));
    const newCompanies = getMyCompanies();
    setMyCompanies(newCompanies);
  };

  return (
    <UserContext.Provider
      value={{
        imageKey,
        myCompanies,
        getNewImageKey,
        getNewCompanies,
        setImageKey,
        setMyCompanies,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;

import PartnerDetailsPage from "../../../components/AVA/Intranet/Partners/PartnerDetailPage";
import { getAuthToken } from "../../../components/util/auth";
import { fetchRequest } from "../../../components/util/fetchRequest";
import { json } from "react-router-dom";

function PartnerDetail(props) {
  return <PartnerDetailsPage intranet={props.intranet} />;
}

export default PartnerDetail;

export async function loader({ request, params }) {
  const id = params.partnerId;
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  const fetchPartnerDetails = async () => {
    const partnerURL =
      process.env.REACT_APP_API_URL + `intranet/partners/` + id + `/info`;
    const method = "GET";

    const response = await fetchRequest(partnerURL, method, headers, undefined);
    return response;
  };

  const fetchServicesOptions = async () => {
    const URL = process.env.REACT_APP_API_URL + "ava/services";
    const response = await fetchRequest(URL, "GET", headers, undefined);
    const options = response.items.map((d) => ({
      value: d.id,
      label: d.name,
    }));
    return options;
  };

  const [partnerDetails, servicesOptions] = await Promise.all([
    fetchPartnerDetails(),
    fetchServicesOptions(),
  ]);

  return json({ partnerDetails, servicesOptions });
}

import { redirect } from "react-router-dom";

export function getUserId() {
  const userId = localStorage.getItem("userId");
  return userId;
}
export function getAuthToken() {
  const token = localStorage.getItem("token");
  return token;
}

export function getEmail() {
  const email = localStorage.getItem("email");
  return email;
}

export function getImageKey() {
  const imageKey = localStorage.getItem("imageKey");
  return imageKey;
}

export function getName() {
  const name = localStorage.getItem("name");
  return name;
}

export function getPosition() {
  const position = localStorage.getItem("position");
  return position;
}

export function getMyCompanies() {
  const myCompanies = JSON.parse(localStorage.getItem("company"));
  return myCompanies;
}

export function getMyRoles() {
  const myRoles = JSON.parse(localStorage.getItem("roles"));
  return myRoles;
}

export function tokenLoader() {
  return getAuthToken();
}

export function checkAuthLoader() {
  const token = getAuthToken();

  if (!token) {
    return redirect("/login");
  }
  return null;
}

export function rootLoader() {
  const myRoles = JSON.parse(localStorage.getItem("roles"));

  if (!myRoles.includes("root")) {
    console.log("Access denied!");
    return redirect("/");
  }
  return null;
}

export function loginCheck() {
  const token = getAuthToken();

  if (token) {
    return redirect("/");
  }
  return null;
}

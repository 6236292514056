import React from "react";
import LeadDetailsPage from "../../../components/AVA/CRM/Leads/LeadDetailsPage";
import { getAuthToken } from "../../../components/util/auth";
import { fetchRequest } from "../../../components/util/fetchRequest";
import { json } from "react-router-dom";

function LeadDetails() {
  return <LeadDetailsPage />;
}

export default LeadDetails;

export async function loader({ request, params }) {
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  const fetchLeadDetails = async () => {
    const partnerURL =
      process.env.REACT_APP_API_URL +
      `crm/${params.partnerId}/leads/${params.leadId}`;

    const response = await fetchRequest(partnerURL, "GET", headers, undefined);

    return response;
  };

  const fetchLeadComments = async () => {
    const URL =
      process.env.REACT_APP_API_URL +
      `crm/${params.partnerId}/leads/${params.leadId}/comments`;

    const response = await fetchRequest(URL, "GET", headers, undefined);
    return response;
  };

  const fetchUsersOptions = async () => {
    const URL = process.env.REACT_APP_API_URL + `user/accounts`;

    const response = await fetchRequest(URL, "GET", headers, undefined);
    const options = response.items.map((d) => ({
      value: d.id,
      label: d.email,
    }));
    return options;
  };

  const [leadDetails, comments, userOptions] = await Promise.all([
    fetchLeadDetails(),
    fetchLeadComments(),
    fetchUsersOptions(),
  ]);

  return json({ leadDetails, comments, userOptions });
}

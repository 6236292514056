import styles from "./CheckBox.module.css";

function CheckBox({ label, checked, onChange, description, name, disabled }) {
  return (
    <label className={styles.checkboxContainer}>
      <input
        name={name}
        className={styles.checkbox}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      ></input>
      <div
        className={styles.checkboxLabel}
        style={disabled ? { color: "#ccc", cursor: "not-allowed" } : {}}
      >
        {label}
        <p className={styles.note} style={disabled ? { color: "#ccc" } : {}}>
          {description}
        </p>
      </div>
    </label>
  );
}

export default CheckBox;

import * as Hi2Icons from "react-icons/hi2";

export const companiesSidebarData = (id) => {
  return [
    {
      title: "Intranet",
      path: "#",
      icon: <Hi2Icons.HiOutlineShare />,
      iconClosed: <Hi2Icons.HiOutlineChevronRight className="subnavArrow" />,
      iconOpened: <Hi2Icons.HiOutlineChevronDown className="subnavArrow" />,

      subNav: [
        {
          title: "Details",
          path: `/intranet/partners/${id}`,
          icon: <Hi2Icons.HiOutlineDocumentText />,
        },
        {
          title: "Documents",
          path: `/intranet/${id}/documents`,
          icon: <Hi2Icons.HiOutlineFolderOpen />,
        },
      ],
    },
    {
      title: "CRM",
      path: "#",
      icon: <Hi2Icons.HiOutlineUserGroup />,
      iconClosed: <Hi2Icons.HiOutlineChevronRight className="subnavArrow" />,
      iconOpened: <Hi2Icons.HiOutlineChevronDown className="subnavArrow" />,

      subNav: [
        // {
        //   title: "Reports",
        //   path: "/crm/reports",
        //   icon: <Hi2Icons.HiOutlineDocumentArrowUp />,
        // },
        {
          title: "Requests",
          path: `/crm/${id}/requests`,
          icon: <Hi2Icons.HiOutlineDocumentArrowDown />,
        },
        {
          title: "Leads",
          path: `/crm/${id}/leads`,
          icon: <Hi2Icons.HiOutlineFunnel />,
        },
        {
          title: "Events",
          path: `/crm/${id}/events`,
          icon: <Hi2Icons.HiOutlineSparkles />,
        },
      ],
    },
    {
      title: "User",
      path: "#",
      icon: <Hi2Icons.HiOutlineUser />,
      iconClosed: <Hi2Icons.HiOutlineChevronRight className="subnavArrow" />,
      iconOpened: <Hi2Icons.HiOutlineChevronDown className="subnavArrow" />,

      subNav: [
        {
          title: "Invitation",
          path: `intranet/${id}/users`,
          icon: <Hi2Icons.HiOutlineUserPlus />,
        },
      ],
    },
  ];
};

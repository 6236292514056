import React, { useEffect, useState } from "react";
import Modal from "../../../UI/Modal/Modal";
import { useContext } from "react";
import styles from "./JopApplicantsDetailPage.module.css";
import ModalContext from "../../../context/modal-context";
import { getAuthToken } from "../../../util/auth";
import { fetchRequest } from "../../../util/fetchRequest";
import * as Hi2Icons from "react-icons/hi2";

function JobApplicantsDetailPage(props) {
  const modalCtx = useContext(ModalContext);
  const token = getAuthToken();
  const URL =
    process.env.REACT_APP_API_URL + `ava/jobapplicants/` + props.applicantId;
  const headers = { Authorization: `Bearer ${token}` };

  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchRequest(URL, "GET", headers, undefined);
      setData(response);
      setDate(response.date.split("T")[0]);
    };
    fetchData();
  }, []);

  const [statusValue, setStatusValue] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const [employmentValue, setEmploymentValue] = useState("");
  const [expiryValue, setExpiryValue] = useState("");
  const [date, setDate] = useState();
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (data.status === "new") {
      setStatusValue("New");
    } else if (data.status === "published") {
      setStatusValue("Published");
    } else if (data.status === "discarded") {
      setStatusValue("Discarded");
    }

    if (data.field === "av") {
      setFieldValue("AV");
    } else if (data.field === "pm") {
      setFieldValue("Project manager");
    } else if (data.field === "marketing") {
      setFieldValue("Marketing");
    } else if (data.field === "other") {
      setFieldValue("Other");
    }

    if (data.employment === "fullTime") {
      setEmploymentValue("Full-time");
    } else if (data.employment === "partTime") {
      setEmploymentValue("Part-time");
    } else if (data.employment === "freelancer") {
      setEmploymentValue("Freelancer");
    }

    if (data.expiry === "sixMonths") {
      setExpiryValue("6 months");
    } else if (data.expiry === "oneYear") {
      setExpiryValue("1 year");
    }
  }, [data]);

  useEffect(() => {
    if (data.cv) {
      const shortFileName = data.cv.substring(data.cv.lastIndexOf("/") + 1);
      setFileName(shortFileName);
    }
  }, [data.cv]);

  const downloadHandler = () => {
    const link = document.createElement("a");
    link.href = process.env.REACT_APP_API_URL + `file/download?key=${data.cv}`;
    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
  };

  return (
    <Modal>
      <div className={styles.container}>
        <div className={styles.title}>
          <h2>Job applicant details</h2>
          <Hi2Icons.HiOutlineXMark
            className={styles["close-button"]}
            onClick={() => modalCtx.showModal()}
          />
        </div>
        <div className={styles.content}>
          {!props.hide && (
            <div className={styles["details"]}>
              <p>Status</p>
              <span>{statusValue || "-"}</span>
            </div>
          )}
          <h3 className={styles["sub-title"]}>Personal Details</h3>

          <div className={styles["details"]}>
            <p>First name</p>
            <span>{data.firstName || "-"}</span>
          </div>
          <div className={styles["details"]}>
            <p>Last name</p>
            <span>{data.lastName || "-"}</span>
          </div>
          <div className={styles["details"]}>
            <p>E-mail</p>
            <span>
              <a href={`mailto:${data.email}`}>{data.email || "-"}</a>
            </span>
          </div>
          <div className={styles["details"]}>
            <p>Country</p>
            <span>{data.country || "-"}</span>
          </div>
          <div className={styles["details"]}>
            <p>Languages</p>
            <span>{data.languages || "-"}</span>
          </div>
          <div className={styles["details"]}>
            <p>Additional Information</p>
            <span>{data.info || "-"}</span>
          </div>
          <div className={styles["details"]}>
            <div className={styles["CV-contanier"]}>
              <p>Curricullum vitae</p>
              {data.cv ? (
                <span className={styles["CV-file"]} onClick={downloadHandler}>
                  <Hi2Icons.HiOutlineDocumentText size={22} />
                  {fileName}
                </span>
              ) : (
                <div className={styles["CV-file"]}>
                  <span>-</span>
                </div>
              )}
            </div>
          </div>

          <h3 className={styles["sub-title"]}>Job Details</h3>

          <div className={styles.contact}>
            <div className={styles["details"]}>
              <p>Field</p>
              <span>{fieldValue || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>Role</p>
              <span>{data.role || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>Location</p>
              <span>{data.location || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>Employment</p>
              <span>{employmentValue || "-"}</span>
            </div>
          </div>
          <h3 className={styles["sub-title"]}>Submission Details</h3>
          <div className={styles["details"]}>
            <p>Date</p>
            <span>{date || "-"}</span>
          </div>
          <div className={styles["details"]}>
            <p>Expiry</p>
            <span>{expiryValue || "-"}</span>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default JobApplicantsDetailPage;

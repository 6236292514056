import styles from "./ImageLoader.module.css";

const ImageLoader = () => {
  return (
    <div className={styles.loader}>
      <img src="/spinner.gif" alt="Loader" height="40" />
    </div>
  );
};

export default ImageLoader;

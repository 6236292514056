import React, { useContext, useEffect, useState } from "react";
import { Link, useLoaderData, useParams } from "react-router-dom";
import styles from "./SharedPartnerDetailsPage.module.css";
import Card from "../UI/Card/Card";
import Header from "../UI/Table/Header";
import Table from "../UI/Table/Table";
import { useSimpleDataFetching } from "../../hooks/use-simpledatafetching";
import ErrorPage from "../ErrorPage";
import * as Hi2Icons from "react-icons/hi2";
import SharedPartnerData from "./SharedPartnerData";
import ModalContext from "../context/modal-context";
import LocationDetailsPage from "../AVA/Intranet/Locations/LocationDetailsPage";
import ContactDetailPage from "../AVA/Intranet/Contacts/ContactDetailPage";
import { Img } from "react-image";

function SharedPartnerDetailsPage() {
  const data = useLoaderData();
  const params = useParams();

  const modalCtx = useContext(ModalContext);

  const [locationDetails, setLocationDetails] = useState(false);
  const [contactDetails, setContactDetails] = useState(false);
  const [locationId, setLocationId] = useState("");
  const [contactId, setContactId] = useState("");

  const [locationsURLsuffix, setLocationsURLsuffix] = useState(
    "intranet/partners/" + params.partnerId + "/locations"
  );
  const [contactsURLsuffix, setContactsURLsuffix] = useState(
    "intranet/partners/" + params.partnerId + "/contacts"
  );

  const {
    error: locationsError,
    pageData: locationsPageData,
    getSimpleTableData: getLocationsData,
  } = useSimpleDataFetching(locationsURLsuffix);

  const {
    error: contactsError,
    pageData: contactsPageData,
    getSimpleTableData: getContactsData,
  } = useSimpleDataFetching(contactsURLsuffix);

  //* Sorting

  const sortLocation = (accessor, order) => {
    setLocationsURLsuffix(
      "intranet/partners/" +
        params.partnerId +
        `/locations?page=1&size=20&sort=${accessor}&order=${order}`
    );
  };

  useEffect(() => {
    getLocationsData();
  }, [locationsURLsuffix]);

  const locationsColumns = [
    {
      Header: (
        <Header sortHandler={sortLocation} title="Country" id="country" />
      ),
      accessor: "country",
      width: "33%",

      Cell: ({ value, row }) => (
        <Link
          className={styles["link"]}
          onClick={() => locationDetailsHandler(row.original.id)}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: <Header sortHandler={sortLocation} title="City" id="city" />,
      accessor: "city",
      width: "33%",

      Cell: ({ value, row }) => (
        <Link
          className={styles["link"]}
          onClick={() => locationDetailsHandler(row.original.id)}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: (
        <Header sortHandler={sortLocation} title="Address" id="address" />
      ),
      accessor: "address",
      width: "33%",

      Cell: ({ value, row }) => (
        <Link
          className={styles["link"]}
          onClick={() => locationDetailsHandler(row.original.id)}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: "Info",
      accessor: "id",
      id: "info",
      width: "50px",

      Cell: ({ row, value }) => (
        <Hi2Icons.HiOutlineInformationCircle
          className={styles["details-icon"]}
          onClick={() => locationDetailsHandler(row.original.id)}
        />
      ),
    },
  ];

  //*Sorting

  const sortContact = (accessor, order) => {
    setContactsURLsuffix(
      "intranet/partners/" +
        params.partnerId +
        `/contacts?page=1&size=20&sort=${accessor}&order=${order}`
    );
  };

  useEffect(() => {
    getContactsData();
  }, [contactsURLsuffix]);

  const contactsColumns = [
    {
      Header: <Header sortHandler={sortContact} title="Name" id="name" />,
      accessor: "name",
      width: "50%",

      Cell: ({ value, row }) => (
        <Link
          className={styles["link"]}
          onClick={() => contactDetailsHandler(row.original.id)}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: (
        <Header sortHandler={sortContact} title="Position" id="position" />
      ),
      accessor: "position",
      width: "50%",

      Cell: ({ value, row }) => (
        <Link
          className={styles["link"]}
          onClick={() => contactDetailsHandler(row.original.id)}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: "Info",
      accessor: "id",
      id: "info",
      width: "50px",
      Cell: ({ row, value }) => (
        <Hi2Icons.HiOutlineInformationCircle
          className={styles["details-icon"]}
          onClick={() => contactDetailsHandler(row.original.id)}
        />
      ),
    },
  ];

  const locationDetailsHandler = (id) => {
    setLocationDetails(true);
    setContactDetails(false);
    setLocationId(id);

    modalCtx.showModal();
  };

  const contactDetailsHandler = (id) => {
    setLocationDetails(false);
    setContactDetails(true);
    setContactId(id);

    modalCtx.showModal();
  };

  return (
    <>
      {modalCtx.isModal && locationDetails && (
        <LocationDetailsPage locationId={locationId} />
      )}
      {modalCtx.isModal && contactDetails && (
        <ContactDetailPage contactId={contactId} />
      )}

      <Card className={styles.container}>
        <Img
          className={styles["company-logo"]}
          src={
            process.env.REACT_APP_API_URL +
            `file/download?key=${data.partnerDetails.imageKey}`
          }
          alt="Patner logo"
          height={150}
          loader={<img src="/spinner.gif" alt="loading..."></img>}
        />
        <div className={styles["title-container"]}>
          <div className={styles.title}>
            <h1>{data.partnerDetails.name}</h1>
          </div>
        </div>
        <div className={styles.content}>
          <SharedPartnerData
            data={data.partnerDetails}
            services={data.servicesOptions}
          />
          {locationsError ? (
            <ErrorPage
              error={locationsError}
              title={locationsError.title}
              message={locationsError.message}
            />
          ) : (
            <div className={styles["partner-locations"]}>
              <h2>Locations</h2>
              <Table
                columns={locationsColumns}
                data={locationsPageData.rowData}
                isLoading={locationsPageData.isLoading}
              />
            </div>
          )}
          {contactsError ? (
            <ErrorPage
              error={contactsError}
              title={contactsError.title}
              message={contactsError.message}
            />
          ) : (
            <div className={styles["partner-contacts"]}>
              <h2>Contacts</h2>
              <Table
                columns={contactsColumns}
                data={contactsPageData.rowData}
                isLoading={contactsPageData.isLoading}
              />
            </div>
          )}
        </div>
      </Card>
    </>
  );
}

export default SharedPartnerDetailsPage;

import NewPassword from "../components/NewPassword";
import { redirect, useActionData } from "react-router-dom";
import { fetchDataRequest } from "../components/util/fetchDataRequest";

function NewPW() {
  const errors = useActionData();
  return <NewPassword error={errors} />;
}

export default NewPW;

export async function action({ request, params }) {
  try {
    const data = await request.formData();
    const authData = {
      token: params.token,
      password: data.get("password"),
    };

    const URL = process.env.REACT_APP_API_URL + "user/auth/new-password";

    const method = "POST";
    const headers = {
      "Content-type": "application/json",
      Accept: "application/json",
    };

    await fetchDataRequest(URL, method, headers, authData);

    return redirect("/login");
  } catch (error) {
    const errorMessage = "Something went wrong. Please contact support.";
    return errorMessage;
  }
}

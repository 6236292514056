import React, { useEffect, useState } from "react";
import Modal from "../../../UI/Modal/Modal";
import { useContext } from "react";
import styles from "./EventDetailsPage.module.css";
import ModalContext from "../../../context/modal-context";
import { getAuthToken } from "../../../util/auth";
import { fetchRequest } from "../../../util/fetchRequest";
import * as Hi2Icons from "react-icons/hi2";

function EventDetailsPage(props) {
  const currencies = props.currencies;

  const modalCtx = useContext(ModalContext);
  const token = getAuthToken();
  const URL =
    process.env.REACT_APP_API_URL +
    `crm/${props.partnerId}/events/${props.eventId}`;
  const headers = { Authorization: `Bearer ${token}` };

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [leadOptions, setLeadOptions] = useState([]);

  const fetchData = async () => {
    const response = await fetchRequest(URL, "GET", headers, undefined);
    setData(response);
    return response;
  };

  const fetchLeadOptions = async () => {
    const URL = process.env.REACT_APP_API_URL + `crm/${props.partnerId}/leads`;

    const response = await fetchRequest(URL, "GET", headers, undefined);
    const options = response.items.map((d) => ({
      value: d.id,
      label: d.name,
    }));
    setLeadOptions(options);
    return options;
  };

  useEffect(() => {
    Promise.all([fetchLeadOptions(), fetchData()]);
    setIsLoading(false);
  }, []);

  const [formatValue, setFormatValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [monthValue, setMonthValue] = useState("");
  const [currencyValue, setCurrencyValue] = useState("");
  const [leadValue, setLeadValue] = useState("");

  const getMonthName = (monthNumber) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndex = parseInt(monthNumber) - 1;

    if (months[monthIndex]) {
      setMonthValue(months[monthIndex]);
    }
  };

  useEffect(() => {
    if (data.status === "upcomming") {
      setStatusValue("Upcomming");
    } else if (data.status === "delivered") {
      setStatusValue("Delivered");
    } else if (data.status === "cancelled") {
      setStatusValue("Cancelled");
    }

    if (data.format === "unspecified") {
      setFormatValue("Unspecified");
    } else if (data.format === "live") {
      setFormatValue("Live");
    } else if (data.format === "hybrid") {
      setFormatValue("Hybrid");
    } else if (data.format === "virtual") {
      setFormatValue("Virtual");
    }

    const foundCurrency = currencies.find(
      (option) => option.value === data.currency
    );

    if (foundCurrency) {
      setCurrencyValue(foundCurrency.label);
    }

    getMonthName(data.month);
  }, [data, leadOptions]);

  useEffect(() => {
    const foundLead = leadOptions.find(
      (option) => option.value === data.leadId
    );

    if (foundLead) {
      setLeadValue(foundLead.label);
    }
  }, [leadOptions]);

  const closeHandler = () => {
    modalCtx.showModal();
  };

  const Services = () => {
    const names = data.services.map((id) => {
      const dataItem = props.services.find((item) => item.value === id);
      return dataItem ? dataItem.label : "";
    });

    return (
      <ul>
        {names.map((name, index) => (
          <li key={index}>{name}</li>
        ))}
      </ul>
    );
  };

  return (
    <Modal>
      {!isLoading && (
        <div className={styles.container}>
          <div className={styles.title}>
            <h2>Event details</h2>
            <Hi2Icons.HiOutlineXMark
              className={styles["close-button"]}
              onClick={closeHandler}
            />
          </div>
          <div className={styles.content}>
            <h3 className={styles["sub-title"]}>Basic info</h3>
            <div className={styles["details"]}>
              <p>Name</p>
              <span>{data.name || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>Location</p>
              <span>{data.location || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>Format</p>
              <span>{formatValue || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>Status</p>
              <span>{statusValue || "-"}</span>
            </div>

            <h3 className={styles["sub-title"]}>Possible timeframe</h3>
            <div className={styles["details"]}>
              <p>Year</p>
              <span>{data.year || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>Month</p>
              <span>{monthValue || "-"}</span>
            </div>

            <h3 className={styles["sub-title"]}>Pricing</h3>
            <div className={styles["details"]}>
              <p>Currency</p>
              <span>{currencyValue || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>Estimated value</p>
              <span>{data.estimation || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>Real value</p>
              <span>{data.realized || "-"}</span>
            </div>

            <h3 className={styles["sub-title"]}>Actual event dates</h3>
            <div className={styles["details"]}>
              <p>Start date</p>
              <span>{data.startDate || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>End date</p>
              <span>{data.endDate || "-"}</span>
            </div>

            <h3 className={styles["sub-title"]}>Other details</h3>
            <div className={styles["details"]}>
              <p>Notes</p>
              <span>{data.notes || "-"}</span>
            </div>
            <div className={styles["details"]}>
              <p>Services</p>
              {data.services && <Services />}
            </div>
            <div className={styles["details"]}>
              <p>Lead</p>
              <span>{leadValue || "-"}</span>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default EventDetailsPage;

import React, { useEffect, useState } from "react";
import Modal from "../../../UI/Modal/Modal";
import { useContext } from "react";
import FormDataContext from "../../../context/formData-context";
import styles from "./LeadEditPage.module.css";
import Button from "../../../UI/Button/Button";
import ModalContext from "../../../context/modal-context";
import { getAuthToken } from "../../../util/auth";
import { fetchRequest } from "../../../util/fetchRequest";
import { Form } from "react-router-dom";
import Input from "../../../UI/Input/Input";
import * as Hi2Icons from "react-icons/hi2";
import SingleSelectNew from "../../../UI/Input/SingleSelectNew";
import TextArea from "../../../UI/Input/TextArea";
import PhoneInputField from "../../../UI/Input/PhoneInputField";

function LeadEditPage(props) {
  const dataCtx = useContext(FormDataContext);
  const modalCtx = useContext(ModalContext);

  const method = props.method;
  const token = getAuthToken();

  const URL =
    process.env.REACT_APP_API_URL +
    `crm/${props.partnerId}/leads/` +
    props.leadId;
  const headers = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    dataCtx.editFromData({});
  }, []);

  const [data, setData] = useState({});

  const [defaultStatusValue, setDefaultStatusValue] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    const response = await fetchRequest(URL, "GET", headers, undefined);
    setData(response);

    if (method === "PUT") {
      const statusValue = statusOptions.find(
        (option) => option.value === response.status
      );
      setDefaultStatusValue(statusValue);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (method === "PUT") {
      fetchData();
    }
  }, []);

  const partnerId = props.partnerId || dataCtx.formData.partnerId;

  useEffect(() => {
    let initialValues = {
      name: "",
      email: "",
      phone: "",
      company: "",
      notes: "",
      status: "",
    };
    let URLsuffix = "";

    if (method === "PUT") {
      initialValues = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        company: data.company,
        notes: data.notes,
        status: data.status,
      };
      URLsuffix = "/" + data.id;
    }

    const requestData = {
      url: process.env.REACT_APP_API_URL + `crm/${partnerId}/leads` + URLsuffix,
      method: method,
      headers: { Authorization: `Bearer ${token}` },
    };

    dataCtx.editFromData(initialValues);
    dataCtx.editRequestData(requestData);
  }, [data, method]);

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      if (isTouched) {
        setIsTouched(false);
      }
    }

    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    return regex.test(email);
  };

  const [isTouched, setIsTouched] = useState(false);

  const handleEmailBlur = () => {
    setIsTouched(true);
    handleInputChange({
      target: {
        name: "email",
        value: dataCtx.formData.email,
      },
    });
  };

  const isEmailValid =
    !isTouched ||
    dataCtx.formData.email.trim() === "" ||
    validateEmail(dataCtx.formData.email);

  const handlePhoneChange = (value) => {
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      phone: value,
    }));
  };

  const handleSingleSelectChange = (selectedOption, fieldName) => {
    const selectedValue = selectedOption.value;
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      [fieldName]: selectedValue,
    }));
  };

  const submitHandler = async () => {
    await dataCtx.fetchData();

    props.refresh();
    modalCtx.showModal();
  };

  const closeHandler = () => {
    dataCtx.editFromData({});
    modalCtx.showModal();
  };

  const title = method === "PUT" ? "Edit" : method === "POST" ? "Create" : "";

  return (
    <Modal>
      {(!isLoading || method !== "PUT") && (
        <div className={styles.container}>
          <Form>
            <div className={styles.title}>
              <h2>{title} lead</h2>
              <div>
                <Hi2Icons.HiOutlineXMark
                  className={styles["close-button"]}
                  onClick={closeHandler}
                />
              </div>
            </div>
            <div className={styles.content}>
              <Input
                label="Name"
                id="name"
                name="name"
                value={dataCtx.formData.name || ""}
                onChange={handleInputChange}
              />
              <Input
                label="Email"
                type="email"
                id="email"
                name="email"
                value={dataCtx.formData.email || ""}
                onChange={handleInputChange}
                onBlur={handleEmailBlur}
              />
              {!isEmailValid && (
                <p className={styles.error}>Please enter a valid email.</p>
              )}
              <PhoneInputField
                id="phone"
                name="phone"
                label="Phone number"
                title="phone"
                startValue={data.phone}
                value={dataCtx.formData.phone}
                onChange={handlePhoneChange}
              />
              <Input
                label="Company"
                id="company"
                name="company"
                value={dataCtx.formData.company || ""}
                onChange={handleInputChange}
              />
              <TextArea
                label="Notes"
                id="notes"
                name="notes"
                rows={10}
                value={dataCtx.formData.notes}
                onChange={handleInputChange}
              />
              <SingleSelectNew
                id="status"
                name="status"
                label="Status"
                options={statusOptions}
                defaultValue={defaultStatusValue}
                value={dataCtx.formData.status}
                onSingleChange={handleSingleSelectChange}
              />
              <div className={styles["buttons"]}>
                <Button
                  type="submit"
                  className={styles["submit-button"]}
                  onClick={submitHandler}
                  disabled={!isEmailValid}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Modal>
  );
}

export default LeadEditPage;

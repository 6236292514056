import { createContext, useState } from "react";

const SortContext = createContext({
  ascending: true,
  activeColumn: null,
  setActiveColumn: () => {},
  setAscending: () => {},
});

export const SortContextProvider = (props) => {
  const [activeColumn, setActiveColumn] = useState(null);
  const [ascending, setAscending] = useState(true);

  return (
    <SortContext.Provider
      value={{
        ascending,
        activeColumn,
        setAscending,
        setActiveColumn,
      }}
    >
      {props.children}
    </SortContext.Provider>
  );
};

export default SortContext;

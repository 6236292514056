import React, { useEffect, useState } from "react";
import Modal from "../../../UI/Modal/Modal";
import { useContext } from "react";
import FormDataContext from "../../../context/formData-context";
import styles from "./EventEditPage.module.css";
import Button from "../../../UI/Button/Button";
import ModalContext from "../../../context/modal-context";
import { getAuthToken } from "../../../util/auth";
import { fetchRequest } from "../../../util/fetchRequest";
import { Form } from "react-router-dom";
import Input from "../../../UI/Input/Input";
import * as Hi2Icons from "react-icons/hi2";
import SingleSelectNew from "../../../UI/Input/SingleSelectNew";
import TextArea from "../../../UI/Input/TextArea";
import MultiSelect from "../../../UI/Input/MultiSelect";
import moment from "moment";

function EventEditPage(props) {
  const dataCtx = useContext(FormDataContext);
  const modalCtx = useContext(ModalContext);

  const servicesOptions = props.services;
  const currenciesOptions = props.currencies;
  const countryOptions = props.countries;

  const method = props.method;

  const token = getAuthToken();
  const URL =
    process.env.REACT_APP_API_URL +
    `crm/${props.partnerId}/events/` +
    props.eventId;
  const headers = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    dataCtx.editFromData({});
  }, []);

  const [data, setData] = useState({});
  const [leadOptions, setLeadOptions] = useState([]);

  const [defaultStatusValue, setDefaultStatusValue] = useState("");
  const [defaultFormatValue, setDefaultFormatValue] = useState("");
  const [defaultCountryOption, setDefaultCountryOption] = useState("");
  const [defaultYearValue, setDefaultYearValue] = useState("");
  const [defaultMonthValue, setDefaultMonthValue] = useState("");
  const [defaultCurrencyValue, setDefaultCurrencyValue] = useState("");
  const [defaultServicesValues, setDefaultServicesValues] = useState("");
  const [defaultLeadValue, setDefaultLeadValue] = useState([]);
  const [formatedStartDate, setformatedStartDate] = useState("");
  const [formatedEndDate, setformatedEndDate] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const statusOptions = [
    { value: "upcoming", label: "Upcoming" },
    { value: "delivered", label: "Delivered" },
    { value: "cancelled", label: "Cancelled" },
  ];

  const fetchLeadOptions = async () => {
    const URL = process.env.REACT_APP_API_URL + `crm/${props.partnerId}/leads`;

    const response = await fetchRequest(URL, "GET", headers, undefined);
    const options = response.items.map((d) => ({
      value: d.id,
      label: d.name,
    }));
    setLeadOptions(options);
    return options;
  };

  const fetchData = async () => {
    const response = await fetchRequest(URL, "GET", headers, undefined);
    setData(response);
    if (method === "PUT") {
      const statusValue = statusOptions.find(
        (option) => option.value === response.status
      );
      setDefaultStatusValue(statusValue);

      const countryOption = countryOptions.find(
        (option) => option.value === response.location
      );
      setDefaultCountryOption(countryOption);

      const formatValue = formatOptions.find(
        (option) => option.value === response.format
      );
      setDefaultFormatValue(formatValue);

      const yearValue = yearOptions.find(
        (option) => option.value === response.year
      );
      setDefaultYearValue(yearValue);

      const monthValue = monthOptions.find(
        (option) => option.value === response.month
      );
      setDefaultMonthValue(monthValue);

      const currencyValue = currenciesOptions.find(
        (option) => option.value === response.currency
      );
      setDefaultCurrencyValue(currencyValue);

      const servicesValues = response.services.map((value) => {
        return servicesOptions.find((option) => option.value === value);
      });
      setDefaultServicesValues(servicesValues);

      setformatedStartDate(response.startDate.split("T")[0]);
      setformatedEndDate(response.endDate.split("T")[0]);
    }
    setIsLoading(false);
    return response;
  };

  useEffect(() => {
    if (method === "POST") {
      fetchLeadOptions().then((result) => {
        setLeadOptions(result);
      });
    } else if (method === "PUT") {
      fetchLeadOptions().then((result) => {
        fetchData();
      });
    }
  }, []);

  useEffect(() => {
    if (data && leadOptions) {
      const leadValue = leadOptions.find(
        (option) => option.value === data.leadId
      );
      setDefaultLeadValue(leadValue);
    }
  }, [data, leadOptions]);

  const partnerId = props.partnerId || dataCtx.formData.partnerId;

  useEffect(() => {
    const countryOption = countryOptions.find(
      (option) => option.value === data.country
    );
    setDefaultCountryOption(countryOption);
  }, [data, countryOptions]);

  useEffect(() => {
    let initialValues = {
      name: "",
      location: "",
      format: "",
      year: "",
      month: "",
      notes: "",
      status: "",
      currency: "",
      estimation: "",
      realized: "",
      startDate: "",
      endDate: "",
      services: [],
      leadId: null,
    };
    let URLsuffix = "";

    if (method === "PUT") {
      initialValues = {
        name: data.name,
        location: data.location,
        format: data.format,
        year: data.year,
        month: data.month,
        notes: data.notes,
        status: data.status,
        currency: data.currency,
        estimation: data.estimation,
        realized: data.realized,
        startDate: data.startDate,
        endDate: data.endDate,
        services: data.services,
        leadId: data.leadId,
      };
      URLsuffix = "/" + data.id;
    }

    const requestData = {
      url:
        process.env.REACT_APP_API_URL + `crm/${partnerId}/events` + URLsuffix,
      method: method,
      headers: { Authorization: `Bearer ${token}` },
    };

    dataCtx.editFromData(initialValues);
    dataCtx.editRequestData(requestData);
  }, [data, method]);

  const formatOptions = [
    { value: "unspecified", label: "Unspecified" },
    { value: "live", label: "Live" },
    { value: "hybrid", label: "Hybrid" },
    { value: "virtual", label: "Virtual" },
  ];

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const generateYearOptions = () => {
    const currentYear = getCurrentYear();
    const yearOptions = [];

    for (let i = 0; i < 11; i++) {
      const year = currentYear + i;
      yearOptions.push({ value: year, label: year.toString() });
    }

    return yearOptions;
  };

  const yearOptions = generateYearOptions();

  const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let formattedDate;

    if (name === "startDate") {
      setformatedStartDate(value);
      const dateObj = moment(value, "YYYY-MM-DD HH:mm:ss Z").add(2, "hours");
      formattedDate = dateObj.format();
    } else if (name === "endDate") {
      setformatedEndDate(value);
      const dateObj = moment(value, "YYYY-MM-DD HH:mm:ss Z").add(2, "hours");
      formattedDate = dateObj.format();
    } else {
      formattedDate = value;
    }

    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: formattedDate,
    });
  };

  const handleNumberInputChange = (event) => {
    const { name, value } = event.target;
    const parsedValue = value !== "" ? parseFloat(value) : null;

    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: parsedValue,
    });
  };

  const handleSingleSelectChange = (selectedOption, fieldName) => {
    const selectedValue = selectedOption.value;
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      [fieldName]: selectedValue,
    }));
  };

  const handleMultiSelectChange = (selectedOptions, fieldName) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      [fieldName]: selectedValues,
    }));
  };

  const submitHandler = async () => {
    await dataCtx.fetchData();

    props.refresh();
    modalCtx.showModal();
  };

  const closeHandler = () => {
    dataCtx.editFromData({});
    modalCtx.showModal();
  };

  const title = method === "PUT" ? "Edit" : method === "POST" ? "Create" : "";

  return (
    <Modal>
      {(!isLoading || method !== "PUT") && (
        <div className={styles.container}>
          <Form>
            <div className={styles.title}>
              <h2>{title} event</h2>
              <div className={styles["close-container"]}>
                <Hi2Icons.HiOutlineXMark
                  className={styles["close-button"]}
                  onClick={closeHandler}
                />
              </div>
            </div>
            <div className={styles.content}>
              <h3 className={styles["sub-title"]}>Basic info</h3>
              <Input
                label="Name"
                id="name"
                name="name"
                note="(Required)"
                value={dataCtx.formData.name || ""}
                onChange={handleInputChange}
              />
              <SingleSelectNew
                className={styles["country-select"]}
                id="location"
                name="location"
                label="Location"
                options={countryOptions}
                defaultValue={defaultCountryOption}
                value={dataCtx.formData.location}
                onSingleChange={handleSingleSelectChange}
              />
              <SingleSelectNew
                id="format"
                name="format"
                label="Format"
                options={formatOptions}
                defaultValue={defaultFormatValue}
                value={dataCtx.formData.format}
                onSingleChange={handleSingleSelectChange}
              />
              <SingleSelectNew
                id="status"
                name="status"
                label="Status"
                options={statusOptions}
                defaultValue={defaultStatusValue}
                value={dataCtx.formData.status}
                onSingleChange={handleSingleSelectChange}
              />
              <h3 className={styles["sub-title"]}>Possible timeframe</h3>
              <SingleSelectNew
                id="year"
                name="year"
                label="Year"
                options={yearOptions}
                defaultValue={defaultYearValue}
                value={dataCtx.formData.year}
                onSingleChange={handleSingleSelectChange}
              />
              <SingleSelectNew
                id="month"
                name="month"
                label="Month"
                options={monthOptions}
                defaultValue={defaultMonthValue}
                value={dataCtx.formData.month}
                onSingleChange={handleSingleSelectChange}
              />
              <h3 className={styles["sub-title"]}>Pricing</h3>
              <SingleSelectNew
                id="currency"
                name="currency"
                label="Currency"
                options={currenciesOptions}
                defaultValue={defaultCurrencyValue}
                value={dataCtx.formData.currency}
                onSingleChange={handleSingleSelectChange}
              />
              <Input
                type="number"
                label="Estimated value"
                id="estimation"
                name="estimation"
                value={dataCtx.formData.estimation || ""}
                onChange={handleNumberInputChange}
              />
              <Input
                type="number"
                label="Real value"
                id="realized"
                name="realized"
                value={dataCtx.formData.realized || ""}
                onChange={handleNumberInputChange}
              />
              <h3 className={styles["sub-title"]}>Actual event dates</h3>
              <Input
                label="Start date (yyyy-MM-dd)"
                id="startDate"
                name="startDate"
                type="date"
                value={formatedStartDate || ""}
                onChange={handleInputChange}
              />
              <Input
                label="End date (yyyy-MM-dd)"
                id="endDate"
                name="endDate"
                type="date"
                value={formatedEndDate || ""}
                onChange={handleInputChange}
              />
              <h3 className={styles["sub-title"]}>Other details</h3>
              <TextArea
                label="Notes"
                id="notes"
                name="notes"
                rows={10}
                value={dataCtx.formData.notes}
                onChange={handleInputChange}
              />
              <MultiSelect
                id="services"
                name="services"
                label="Services"
                menuPlacement="top"
                options={servicesOptions}
                defaultValue={defaultServicesValues}
                value={dataCtx.formData.services}
                onMultiChange={handleMultiSelectChange}
              />
              {(defaultLeadValue || method !== "PUT") && (
                <SingleSelectNew
                  id="leadId"
                  name="leadId"
                  label="Lead"
                  options={leadOptions}
                  defaultValue={defaultLeadValue}
                  value={dataCtx.formData.leadId}
                  onSingleChange={handleSingleSelectChange}
                />
              )}
              <div className={styles["buttons"]}>
                <Button
                  type="submit"
                  className={styles["submit-button"]}
                  onClick={submitHandler}
                  // disabled={isDisabled}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Modal>
  );
}

export default EventEditPage;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MenuContextProvider } from "./components/context/sidebar-context";
import { ModalContextProvider } from "./components/context/modal-context";
import { FormDataContextProvider } from "./components/context/formData-context";
import { SortContextProvider } from "./components/context/tablesort-context";
import { DnDContextProvider } from "./components/context/dnd-context";
import { UserContextProvider } from "./components/context/user-context";
import "react-big-calendar/lib/css/react-big-calendar.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ModalContextProvider>
      <MenuContextProvider>
        <FormDataContextProvider>
          <SortContextProvider>
            <DnDContextProvider>
              <UserContextProvider>
                <App />
              </UserContextProvider>
            </DnDContextProvider>
          </SortContextProvider>
        </FormDataContextProvider>
      </MenuContextProvider>
    </ModalContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useEffect, useContext, useState } from "react";
import styles from "./ShareDocuments.module.css";
import { Form } from "react-router-dom";
import Button from "../../../UI/Button/Button";
import FormDataContext from "../../../context/formData-context";
import { getAuthToken } from "../../../util/auth";
import { fetchRequest } from "../../../util/fetchRequest";
import Modal from "../../../UI/Modal/Modal";
import ModalContext from "../../../context/modal-context";
import * as Hi2Icons from "react-icons/hi2";
import MultiSelect from "../../../UI/Input/MultiSelect";

function ShareDocument(props) {
  const token = getAuthToken();
  const URL = process.env.REACT_APP_API_URL + props.URLsuffix;
  const headers = { Authorization: `Bearer ${token}` };

  const modalCtx = useContext(ModalContext);
  const dataCtx = useContext(FormDataContext);

  const partnerOptions = [
    { label: "All partners", value: "all" },
    ...props.options,
  ];

  useEffect(() => {
    dataCtx.editFromData({});
  }, []);

  const [defaultPartnerValues, setDefaultPartnerValues] = useState([]);
  const [defaultIds, setDefaultIds] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchRequest(URL, "GET", headers, undefined);
      console.log(response);
      const defaultPartners = [];
      response.forEach((partner) => {
        const foundPartner = partnerOptions.find(
          (option) => option.value === partner.partnerId
        );
        if (foundPartner) {
          defaultPartners.push(foundPartner);
        }
      });
      const defaultIds = response.map((partner) => partner.partnerId);
      setDefaultIds(defaultIds);
      setDefaultPartnerValues(defaultPartners);
      setisLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const initialValues = {
      partnerIds: defaultIds,
    };

    dataCtx.editFromData(initialValues);

    const requestData = {
      url: process.env.REACT_APP_API_URL + props.URLsuffix,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
    };

    dataCtx.editRequestData(requestData);
  }, [defaultPartnerValues]);

  const handleMultiSelectChange = (selectedOptions, fieldName) => {
    if (selectedOptions.some((option) => option.value === "all")) {
      const allPartnerValues = partnerOptions
        .filter((option) => option.value !== "all")
        .map((option) => option.value);
      dataCtx.editFromData((prevFormValues) => ({
        ...prevFormValues,
        [fieldName]: allPartnerValues,
      }));
    } else {
      const selectedValues = selectedOptions.map((option) => option.value);
      dataCtx.editFromData((prevFormValues) => ({
        ...prevFormValues,
        [fieldName]: selectedValues,
      }));
    }
  };

  const submitHandler = async () => {
    await dataCtx.fetchData();

    props.refresh();
    modalCtx.showModal();
  };

  return (
    <Modal className={styles.modal}>
      <div className={styles.card}>
        <div className={styles.title}>
          <h2>Share {props.name}</h2>
          <Hi2Icons.HiOutlineXMark
            className={styles["close-button"]}
            onClick={() => {
              modalCtx.showModal();
              dataCtx.editFromData({});
            }}
          />
        </div>
        <Form>
          <div className={styles.content}>
            {!isLoading && (
              <MultiSelect
                id="partnerIds"
                name="partnerIds"
                label="Partners"
                menuPlacement="bottom"
                options={partnerOptions}
                defaultValue={defaultPartnerValues}
                value={dataCtx.formData.partnerIds}
                onMultiChange={handleMultiSelectChange}
              />
            )}
            <div className={styles["buttons"]}>
              <Button
                type="submit"
                className={styles["submit-button"]}
                onClick={submitHandler}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default ShareDocument;

import React, { useEffect, useState } from "react";
import Modal from "../../../UI/Modal/Modal";
import { useContext } from "react";
import styles from "./ContactDetailPage.module.css";
import ModalContext from "../../../context/modal-context";
import { getAuthToken } from "../../../util/auth";
import { useParams } from "react-router-dom";
import { fetchRequest } from "../../../util/fetchRequest";
import * as Hi2Icons from "react-icons/hi2";
import { Img } from "react-image";

function ContactDetailPage(props) {
  const modalCtx = useContext(ModalContext);
  const token = getAuthToken();
  const param = useParams();

  const partnerId = param.partnerId || props.partnerId;
  const URL =
    process.env.REACT_APP_API_URL +
    `intranet/partners/` +
    partnerId +
    `/contacts/${props.contactId}`;
  const headers = { Authorization: `Bearer ${token}` };

  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchRequest(URL, "GET", headers, undefined);
      setData(response);
    };
    fetchData();
  }, []);

  const sourceLink = (link) => {
    if (!link) {
      return null;
    }
    if (!link.startsWith("http://") && !link.startsWith("https://")) {
      link = "http://" + link;
    }

    return link;
  };

  return (
    <Modal>
      <div className={styles.container}>
        <div className={styles.title}>
          <h2>Contact details</h2>

          <Hi2Icons.HiOutlineXMark
            className={styles["close-button"]}
            onClick={() => modalCtx.showModal()}
          />
        </div>
        <div className={styles.content}>
          <h3 className={styles["sub-title"]}>Basic Info</h3>

          <div className={styles["details"]}>
            <p>Image</p>
            {data.imageKey ? (
              <Img
                className={styles["contact-photo"]}
                src={
                  process.env.REACT_APP_API_URL +
                  `file/download?key=${data.imageKey}`
                }
                alt="Contact photo"
                height={150}
                loader={<img src="/spinner.gif" alt="loading..."></img>}
              />
            ) : (
              <span>{"-"}</span>
            )}
          </div>
          <div className={styles["details"]}>
            <p>Name</p>
            <span>{data.name}</span>
          </div>
          <div className={styles["details"]}>
            <p>Position</p>
            <span>{data.position}</span>
          </div>

          <h3 className={styles["sub-title"]}>Contact Details</h3>

          <div className={styles["details"]}>
            <p>Phone</p>
            <span>
              <a href={`tel:${data.phone}`}>{data.phone}</a>
            </span>
          </div>
          <div className={styles["details"]}>
            <p>Email</p>
            <span>
              <a href={`mailto:${data.email}`}>{data.email}</a>
            </span>
          </div>
          {data.web && (
            <div className={styles["details"]}>
              <p>Web</p>
              <span>
                <a
                  href={sourceLink(data.web)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.web}
                </a>
              </span>
            </div>
          )}
          {data.linkedIn && (
            <div className={styles["details"]}>
              <p>LinkedIn</p>
              <a
                href={sourceLink(data.linkedIn)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.linkedIn}
              </a>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ContactDetailPage;

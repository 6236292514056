import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import styles from "./Calendar.module.css";
import Card from "../UI/Card/Card";

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const myEventsList = [
  {
    id: 0,
    start: moment("2023-10-16T11:00:00").toDate(),
    end: moment("2023-10-16T16:30:00").toDate(),
    title: "Daily Standup",
    details: "This is a test",
  },
  {
    id: 1,
    start: moment("2023-10-16T14:00:00").toDate(),
    end: moment("2023-10-16T16:30:00").toDate(),
    title: "Meeting with a client",
  },
  {
    id: 2,
    start: moment("2023-10-17T11:00:00").toDate(),
    end: moment("2023-10-17T11:30:00").toDate(),
    title: "Daily Standup",
  },
];

const onEventClick = (event) => {
  console.log(event.id);
};

const MyCalendar = (props) => {
  return (
    <Card className={styles.container}>
      <div className={styles.calendar}>
        <Calendar
          localizer={localizer}
          events={myEventsList}
          startAccessor="start"
          endAccessor="end"
          formats={{
            timeGutterFormat: (date, culture, localizer) =>
              localizer.format(date, "H:mm", culture),
          }}
          onSelectEvent={onEventClick}
        />
      </div>
    </Card>
  );
};

export default MyCalendar;

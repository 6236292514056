import React from "react";
import * as Hi2Icons from "react-icons/hi2";
import styled from "styled-components";

const ImageOverlay = (props) => (
  <OverlayContainer onClick={props.download}>
    <Hi2Icons.HiOutlineArrowDownTray size="2em" />
  </OverlayContainer>
);

export default ImageOverlay;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0%;
  right: 0%;
  background: rgba(39, 39, 39, 0.3);
  cursor: pointer;
  :hover {
    svg {
      color: #fff;
    }
  }
  svg {
    color: #000;
    margin: 10px;
    padding: 5px;
  }
`;

import { useParams } from "react-router-dom";
import MyFileBrowser from "./FileBrowser";

function PartnerDocumentsPage() {
  const params = useParams();
  return (
    <MyFileBrowser
      URLsuffix={`intranet/${params.partnerId}/documents/`}
      root={""}
    />
  );
}

export default PartnerDocumentsPage;

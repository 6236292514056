import Tooltip from "../../../UI/Tooltip/Tooltip";
import { MyFileIcon } from "./FileBrowser";
import * as Hi2Icons from "react-icons/hi";
import styles from "./GridItem.module.css";
import Loader from "../../../UI/Loader/Loader";
import ImageLoader from "../../../UI/ImageLoader/ImageLoader";
import { Img } from "react-image";

function GridItem(props) {
  const { ext, kind, name, id, index } = props.item;

  const handleClick = () => {
    props.clickHandler({
      dirId: id,
      dirName: name,
      kind: kind,
      ext: ext,
      index: index,
    });
  };

  const handleShare = () => {
    props.onShare(id, name);
  };

  const handleDelete = () => {
    props.onDelete(id);
  };

  const photoExt = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];

  return (
    <div className={styles.gridItem}>
      <div className={styles.gridCard} onClick={handleClick}>
        <div className={styles.icon}>
          {photoExt.includes(ext) ? (
            <Img
              src={`${process.env.REACT_APP_API_URL}file/documents/download/${id}?format=small`}
              alt=""
              className={styles["image-preview"]}
              loader={<ImageLoader />}
              unloader={<MyFileIcon kind={kind} ext={ext} size={50} />}
            />
          ) : (
            <MyFileIcon kind={kind} ext={ext} size={50} />
          )}
        </div>
        <div className={styles.folderName}>{name}</div>
      </div>
      {kind === "back" || props.noIcons ? (
        ""
      ) : (
        <div className={styles.actions}>
          <Tooltip className={styles.buttons} text={"Share document"}>
            <div className={styles.actionButton}>
              <Hi2Icons.HiOutlineShare
                size={18}
                className={styles["share-icon"]}
                onClick={handleShare}
              />
            </div>
          </Tooltip>
          <Tooltip className={styles.buttons} text={"Delete document"}>
            <div className={styles.actionButton}>
              <Hi2Icons.HiOutlineTrash
                size={18}
                className={styles["delete-icon"]}
                onClick={handleDelete}
              />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
export default GridItem;

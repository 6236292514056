import styles from "./TextArea.module.css";

function TextArea(props) {
  const classes = `${styles.textarea} ${props.class}`;
  return (
    <div className={classes}>
      <label className={`${styles.label} ${props.id}`} htmlFor={props.id}>
        <div>{props.label}</div>
        <div className={styles.note}>{props.note}</div>
      </label>
      <textarea
        className={`${styles.area} ${props.areaClass}`}
        id={props.id}
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        rows={props.rows}
      />
    </div>
  );
}
export default TextArea;

import React, { useState } from "react";
import styles from "./SegmentedSwitch.module.css";
import * as Hi2Icons from "react-icons/hi2";

function SegmentedSwitch({ onToggle, className }) {
  const [isOn, setIsOn] = useState(false);

  const handleToggle = () => {
    setIsOn(!isOn);
    onToggle(!isOn);
  };

  return (
    <div
      className={`${styles["segmented-switch"]} ${className}`}
      onClick={handleToggle}
    >
      <div
        className={`${styles["switch-handle"]} ${isOn ? styles["on"] : ""}`}
      ></div>
      <div
        className={`${styles["label"]} ${styles["left"]} ${
          isOn ? "" : styles["active"]
        }`}
      >
        <Hi2Icons.HiOutlineTableCells className={styles.icons} size={22} />
        Table
      </div>
      <div
        className={`${styles["label"]} ${styles["right"]} ${
          isOn ? styles["active"] : ""
        }`}
      >
        <Hi2Icons.HiOutlineSquares2X2 className={styles.icons} size={22} />
        Grid
      </div>
    </div>
  );
}

export default SegmentedSwitch;

import { useState } from "react";
import { getData, formatRowData } from "../components/util/GETbrowserData";

export const useBrowserFetching = (URLsuffix) => {
  const [error, setError] = useState(null);
  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
    // totalPages: 0,
    // totalRecords: 0,
  });

  const getTableData = async () => {
    try {
      const info = await getData(URLsuffix).catch((error) => {
        if (error.status === 400) {
          setError({
            title: "Bad request",
            message: "Something went wrong with the request.",
            status: 401,
          });
        } else if (error.status === 401) {
          setError({
            title: "Unauthorized",
            message:
              "Your identification token has expired, please log in and try again.",
            status: 401,
          });
        } else if (error.status === 404) {
          setError({
            title: "Not found",
            message: "The page you want to communicate with does not exist",
            status: 404,
          });
        } else if (error.status === 503) {
          setError({
            title: "Service unavailable!",
            message:
              "Sorry, the requested server is unavailable. Please contact your support and describe your issue.",
            status: 503,
          });
        } else {
          setError(error);
        }
      });

      setPageData({
        isLoading: false,
        rowData: formatRowData(info),
        // totalPages: metadata.page.total,
        // totalRecords: metadata.items.total,
      });
    } catch (error) {}
  };

  return { error, setError, pageData, getTableData };
};

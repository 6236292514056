import { Form, useNavigation, Link, useParams } from "react-router-dom";

import Button from "./UI/Button/Button";
import Card from "./UI/Card/Card";
import Footer from "./UI/Footer/Footer";
import styles from "./NewPassword.module.css";
import Password from "./UI/Input/Password";
import { useState } from "react";

function NewPassword(props) {
  const params = useParams();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  const serverError = props.error;

  const [password, setPassword] = useState("");

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  return (
    <>
      <picture>
        <source
          srcSet="/site-logo_dark.png"
          media="(prefers-color-scheme:dark)"
        />
        <img
          className={styles.img}
          src={`/site-logo_light.png`}
          alt="AV Alliance logo"
          height="25"
        />
      </picture>
      <h2 className={styles.h2}>Set new password</h2>
      <p className={styles.note}>Here you can set your new password.</p>
      <Card className={styles.login}>
        <Form method="post" action={`/new-password/${params.token}`}>
          <Password
            className={styles["login-password"]}
            id="password"
            label="Password"
            name="password"
            onChange={passwordHandler}
            value={password}
            required
          />
          {serverError && (
            <p className={`${styles.error} ${styles.warning}`}>{props.error}</p>
          )}
          <Button type="submit" disabled={!password || isSubmitting}>
            {isSubmitting ? "Sending..." : "Set password"}
          </Button>
          <div className={styles.link}>
            <Link to="/login">Back to login</Link>
          </div>
        </Form>
      </Card>
      <Footer />
    </>
  );
}
export default NewPassword;

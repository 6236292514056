import styles from "./Button.module.css";

const Button = (props) => {
  return (
    <div>
      <button
        id={props.id}
        type={props.type || "button"}
        className={`${styles.button} ${props.className}`}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.children}
      </button>
    </div>
  );
};

export default Button;

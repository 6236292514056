import { Link } from "react-router-dom";
import * as Hi2Icons from "react-icons/hi2";
import { SidebarData } from "./MainNavigationData";
import SubMenu from "./SubMenu";
import { companiesSidebarData } from "./CompanyNavigationData";
import { AVASidebarData } from "./AVAllianceNavigationData";
import { IconContext } from "react-icons/lib";
import { useContext, useEffect, useState } from "react";
import MenuContext from "./context/sidebar-context";
import styles from "./MainNavigation.module.css";
import Dropdown from "./UI/UserDropDown/UserDropDown";
import { getMyCompanies, getMyRoles } from "./util/auth";
import { SystemSidebarData } from "./SystemNavigationData";
import UploadProgress from "./UploadProgress";
import UserContext from "./context/user-context";

const MainNavigation = () => {
  const menuCtx = useContext(MenuContext);
  const userCtx = useContext(UserContext);
  const myRoles = getMyRoles();
  const myDefaultCompanies = getMyCompanies();
  const appVersion = process.env.REACT_APP_VERSION;

  const [myCompanies, setMyCompanies] = useState(myDefaultCompanies);

  useEffect(() => {
    async function getCompanies() {
      setMyCompanies(await getMyCompanies());
    }
    getCompanies();
  }, []);

  useEffect(() => {
    setMyCompanies(userCtx.myCompanies);
  }, [userCtx.myCompanies]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest("#sidebar") &&
        !event.target.closest("#navicon") &&
        !event.target.closest("#menu-button") &&
        !event.target.closest(".subnavArrow") &&
        menuCtx.sidebar === true
      ) {
        menuCtx.showSidebar();
      }
    };

    if (menuCtx.sidebar === true) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuCtx]);

  const avaMenuRoles = ["root", "ava-manager"];

  return (
    <>
      <IconContext.Provider value={{ className: styles.icon }}>
        <div className={styles.nav} id="navbar">
          <div className={styles.control}>
            <Link
              className={styles["menu-icon"]}
              to="#"
              id="navicon"
              onClick={menuCtx.showSidebar}
            >
              <Hi2Icons.HiOutlineBars3 />
            </Link>
          </div>
          <div className={styles["site-logo"]}>
            <a href="/">
              <picture>
                <source
                  srcSet="/site-logo_dark.png"
                  media="(prefers-color-scheme:dark)"
                />
                <img
                  src={`/site-logo_light.png`}
                  alt="AV Alliance logo"
                  height="26"
                />
              </picture>
            </a>
          </div>
          <div>
            <Dropdown />
          </div>
        </div>
        <UploadProgress />
        <nav
          className={`${styles["sidebar-nav"]} ${
            menuCtx.sidebar ? styles.open : ""
          }`}
          id="sidebar"
        >
          <div className={styles.sidebar}>
            <div className={styles.close}>
              <Link
                className={styles["menu-icon"]}
                to="#"
                onClick={menuCtx.showSidebar}
              >
                <Hi2Icons.HiOutlineXMark />
              </Link>
            </div>
            {SidebarData.map((item, index) => {
              return <SubMenu item={{ ...item }} key={index} />;
            })}
            {avaMenuRoles.some((item) => myRoles.includes(item)) && (
              <>
                <p className={styles["menu-title"]}>AV Alliance</p>

                {AVASidebarData.map((item, index) => (
                  <SubMenu item={{ ...item }} key={index} />
                ))}
              </>
            )}
            {myCompanies &&
              myCompanies.map((item) => (
                <div key={item.id}>
                  <p className={styles["menu-title"]}>{item.name}</p>
                  {companiesSidebarData(item.id).map((subItem, index) => {
                    if (item.kind === "sponsor" && subItem.title === "CRM") {
                      return null;
                    }
                    return <SubMenu item={subItem} key={index} />;
                  })}
                </div>
              ))}
            {myRoles.includes("root") && (
              <>
                <p className={styles["menu-title"]}>Root Menu</p>

                {SystemSidebarData.map((item, index) => (
                  <SubMenu item={{ ...item }} key={index} />
                ))}
              </>
            )}

            <div className={styles["sidebar-logo"]}>
              <a
                href="https://feathercms.com"
                rel="noreferrer noopener"
                target="_blank"
              >
                <img src="/logo.png" alt="feather logo" height="50" />
              </a>
              <span className={styles.version}>{appVersion} </span>
            </div>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
};

export default MainNavigation;

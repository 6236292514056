import styles from "./CircularProgressbar.module.css";

function Progressbar({ percentage, circleWidth, done, failed }) {
  let progress = "";
  if (failed) {
    progress = 100;
  } else {
    progress = percentage;
  }

  let text = `${progress}%`;
  if (failed) {
    text = "X";
  }

  const radius = 20;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <div className={styles["circle-container"]}>
      <svg
        width={circleWidth}
        height={circleWidth}
        viewBox={`0 0 ${circleWidth} ${circleWidth}`}
      >
        <circle
          cx={circleWidth / 2}
          cy={circleWidth / 2}
          strokeWidth="3px"
          r={radius}
          className={styles["circle-background"]}
        />
        <circle
          cx={circleWidth / 2}
          cy={circleWidth / 2}
          strokeWidth="4px"
          r={radius}
          className={`
          ${styles["circle-progress"]} 
          ${done ? styles.done : ""} 
          ${failed ? styles.failed : ""}
          `}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
          transform={`rotate (-90 ${circleWidth / 2} ${circleWidth / 2})`}
        />
        <text
          x="50%"
          y="50%"
          dy="4px"
          textAnchor="middle"
          className={`
          ${styles["circle-text"]} 
          ${done ? styles.done : ""}
          ${failed ? styles.failed : ""} 
          `}
        >
          {text}
        </text>
      </svg>
    </div>
  );
}

export default Progressbar;

import PartnerUpdatePage from "../../../components/AVA/Intranet/Partners/PartnerUpdatePage";
import { getAuthToken } from "../../../components/util/auth";
import { fetchRequest } from "../../../components/util/fetchRequest";
import { json } from "react-router-dom";

function NewPartner() {
  return <PartnerUpdatePage method="POST" />;
}

export default NewPartner;

export async function loader() {
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  const fetchCurrenciesOptions = async () => {
    const URL = process.env.REACT_APP_API_URL + "ava/currencies";
    const response = await fetchRequest(URL, "GET", headers, undefined);
    const options = response.items.map((d) => ({
      value: d.id,
      label: d.name,
    }));
    return options;
  };

  const fetchUsersOptions = async () => {
    const URL =
      process.env.REACT_APP_API_URL + "user/accounts?page=1&size=1000";

    const response = await fetchRequest(URL, "GET", headers, undefined);
    const options = response.items.map((d) => ({
      value: d.id,
      label: d.email,
    }));
    return options;
  };

  const [currenciesOptions, usersOptions] = await Promise.all([
    fetchCurrenciesOptions(),
    fetchUsersOptions(),
  ]);
  return json({ currenciesOptions, usersOptions });
}

import EventsPage from "../../../components/AVA/CRM/Events/EventsPage";
import { json } from "react-router-dom";
import { getAuthToken } from "../../../components/util/auth";
import { fetchRequest } from "../../../components/util/fetchRequest";

function Events(props) {
  return <EventsPage type={props.type} />;
}

export default Events;

export async function loader({ params }) {
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  const fetchCurrenciesOptions = async () => {
    const URL = process.env.REACT_APP_API_URL + "ava/currencies";
    const response = await fetchRequest(URL, "GET", headers, undefined);
    const options = response.items.map((d) => ({
      value: d.id,
      label: d.name,
    }));
    return options;
  };

  const fetchServicesOptions = async () => {
    const URL = process.env.REACT_APP_API_URL + "ava/services";
    const response = await fetchRequest(URL, "GET", headers, undefined);
    const options = response.items.map((d) => ({
      value: d.id,
      label: d.name,
    }));
    return options;
  };

  const [currenciesOptions, servicesOptions] = await Promise.all([
    fetchCurrenciesOptions(),
    fetchServicesOptions(),
  ]);
  return json({ currenciesOptions, servicesOptions });
}

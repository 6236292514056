import * as Hi2Icons from "react-icons/hi2";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/",
    icon: <Hi2Icons.HiOutlineHome />,
  },
  {
    title: "Intranet",
    path: "#",
    icon: <Hi2Icons.HiOutlineShare />,
    iconClosed: <Hi2Icons.HiOutlineChevronRight className="subnavArrow" />,
    iconOpened: <Hi2Icons.HiOutlineChevronDown className="subnavArrow" />,

    subNav: [
      {
        title: "Members",
        path: "/intranet/shared/members",
        icon: <Hi2Icons.HiOutlineUserGroup />,
      },
      {
        title: "Sponsors",
        path: "/intranet/shared/sponsors",
        icon: <Hi2Icons.HiOutlineCheckBadge />,
      },
      {
        title: "Contacts",
        path: "/intranet/shared/contacts",
        icon: <Hi2Icons.HiOutlinePhone />,
      },
      {
        title: "Locations",
        path: "/intranet/shared/locations",
        icon: <Hi2Icons.HiOutlineMap />,
      },
      {
        title: "Job applicants",
        path: "/intranet/shared/job-applicants",
        icon: <Hi2Icons.HiOutlineIdentification />,
      },
      {
        title: "Documents",
        path: "/intranet/shared/documents",
        icon: <Hi2Icons.HiOutlineFolderOpen />,
      },
    ],
  },
  // {
  //   title: "System",
  //   path: "#",
  //   icon: <FiIcons.FiSettings />,
  //   iconClosed: <FiIcons.FiChevronRight className="subnavArrow" />,
  //   iconOpened: <FiIcons.FiChevronDown className="subnavArrow" />,

  //   subNav: [
  //     {
  //       title: "File",
  //       path: "system/file",
  //       icon: <FiIcons.FiChevronRight />,
  //       // cName: "sub-nav",
  //     },
  //   ],
  // },
  // {
  //   title: "User",
  //   path: "#",
  //   icon: <FiIcons.FiUser />,
  //   iconClosed: <FiIcons.FiChevronRight className="subnavArrow" />,
  //   iconOpened: <FiIcons.FiChevronDown className="subnavArrow" />,

  //   subNav: [
  //     {
  //       title: "Profile",
  //       path: "/user/profile",
  //       icon: <FiIcons.FiChevronRight />,
  //     },
  //     {
  //       title: "Accounts",
  //       path: "/user/accounts",
  //       icon: <FiIcons.FiChevronRight />,
  //     },
  //     {
  //       title: "Invitations",
  //       path: "/user/invitations",
  //       icon: <FiIcons.FiChevronRight />,
  //     },
  //     {
  //       title: "Roles",
  //       path: "/user/roles",
  //       icon: <FiIcons.FiChevronRight />,
  //     },
  //   ],
  // },
  // {
  //   title: "AV Alliance",
  //   path: "#",
  //   icon: <FiIcons.FiAperture />,
  //   iconClosed: <FiIcons.FiChevronRight className="subnavArrow" />,
  //   iconOpened: <FiIcons.FiChevronDown className="subnavArrow" />,

  //   subNav: [
  //     {
  //       title: "Partners",
  //       path: "/av-alliance/partners",
  //       icon: <FiIcons.FiChevronRight />,
  //     },
  //     {
  //       title: "Services",
  //       path: "/av-alliance/services",
  //       icon: <FiIcons.FiChevronRight />,
  //     },
  //     {
  //       title: "Currencies",
  //       path: "/av-alliance/currencies",
  //       icon: <FiIcons.FiChevronRight />,
  //     },
  //     {
  //       title: "Job applicants",
  //       path: "/av-alliance/jobs",
  //       icon: <FiIcons.FiChevronRight />,
  //     },
  //     {
  //       title: "Documents",
  //       path: "/av-alliance/documents",
  //       icon: <FiIcons.FiChevronRight />,
  //     },
  //   ],
  // },
  // {
  //   title: "CRM",
  //   path: "#",
  //   icon: <FiIcons.FiUsers />,
  //   iconClosed: <FiIcons.FiChevronRight className="subnavArrow" />,
  //   iconOpened: <FiIcons.FiChevronDown className="subnavArrow" />,

  //   subNav: [
  //     {
  //       title: "Reports",
  //       path: "/crm/reports",
  //       icon: <FiIcons.FiChevronRight />,
  //     },
  //     {
  //       title: "Requests",
  //       path: "/crm/requests",
  //       icon: <FiIcons.FiChevronRight />,
  //     },
  //     {
  //       title: "Leads",
  //       path: "/crm/leads",
  //       icon: <FiIcons.FiChevronRight />,
  //     },
  //     {
  //       title: "Events",
  //       path: "/crm/events",
  //       icon: <FiIcons.FiChevronRight />,
  //     },
  //   ],
  // },
];

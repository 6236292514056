import { useActionData } from "react-router-dom";
import ForgotPassword from "../components/ForgotPassword";
import { fetchRequest } from "../components/util/fetchRequest";

function Forgot() {
  const message = useActionData();

  return <ForgotPassword message={message} />;
}

export default Forgot;

export async function action({ request }) {
  const message =
    "Thank you for your request, we're going to send you an email with the password reset instructions.";
  try {
    const data = await request.formData();
    const authData = {
      email: data.get("email"),
    };

    const URL = process.env.REACT_APP_API_URL + "user/auth/reset-password";

    const method = "POST";
    const headers = {
      "Content-type": "application/json",
      Accept: "application/json",
    };

    await fetchRequest(URL, method, headers, authData);

    return message;
  } catch (error) {
    return message;
  }
}

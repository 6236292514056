import { createContext, useEffect, useReducer, useState } from "react";
import { getAuthToken } from "../util/auth";

const DnDContext = createContext({
  files: [],
  setFiles: () => {},
  upload: "",
  setUpload: () => {},
  uploadedCount: 0,
  setUploadedCount: () => {},
  isError: null,
  setIsError: () => {},
  fileCounter: 0,
  setFileCounter: () => {},
  bigFiles: [],
  addBigFile: () => {},
  removeBigFile: () => {},
  abortBigFile: () => {},
  bigFileHasError: () => {},
  resetBigFiles: () => {},
  uploadFiles: () => {},
  abortControllers: new Map(),
  removeController: () => {},
  CHUNK_SIZE: 0,
});

export const DnDContextProvider = (props) => {
  const MAX_FILE_SIZE = 32 * 1024 * 1024;
  const CHUNK_SIZE = 32 * 1024 * 1024;

  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };
  const [files, setFiles] = useState([]);
  const [upload, setUpload] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [fileCounter, setFileCounter] = useState(0);
  const [isError, setIsError] = useState(null);

  const fileHandler = (data) => {
    setFiles(data);
  };

  //* Big files reducer

  const initialState = [];

  const bigFilesReducer = (state, action) => {
    switch (action.type) {
      case "ADD_BIG_FILE":
        return [...state, action.file];
      case "UPDATE_BIG_FILE":
        return state.map((file) =>
          file.name === action.file.name ? { ...file, ...action.file } : file
        );
      case "ABORT_BIG_FILE":
        return state.map((file) =>
          file.name === action.fileName ? { ...file, abort: true } : file
        );
      case "BIG_FILE_HAS_ERROR":
        return state.map((file) =>
          file.name === action.fileName ? { ...file, hasError: true } : file
        );
      case "REMOVE_BIG_FILE":
        return state.filter((file) => file.name !== action.fileName);
      case "RESET_BIG_FILES":
        return initialState;
      default:
        return state;
    }
  };

  const [bigFiles, dispatchBigFiles] = useReducer(
    bigFilesReducer,
    initialState
  );

  //* Big files reducer actions

  const addBigFile = (file) => {
    dispatchBigFiles({
      type: "ADD_BIG_FILE",
      file: {
        name: file.name,
        size: file.size,
        chunkCounter: 0,
        abort: false,
        hasError: false,
      },
    });
    const controller = new AbortController();
    addController(file.name, controller);
  };

  const updateBigFile = (file, uploadedChunks) => {
    dispatchBigFiles({
      type: "UPDATE_BIG_FILE",
      file: {
        name: file.name,
        size: file.size,
        chunkCounter: uploadedChunks,
      },
    });
  };

  const abortBigFile = (fileName) => {
    dispatchBigFiles({
      type: "ABORT_BIG_FILE",
      fileName: fileName,
    });
    removeController(fileName);
  };

  const bigFileHasError = (fileName) => {
    dispatchBigFiles({
      type: "BIG_FILE_HAS_ERROR",
      fileName: fileName,
    });
    removeController(fileName);
  };

  const removeBigFile = (fileName) => {
    dispatchBigFiles({
      type: "REMOVE_BIG_FILE",
      fileName: fileName,
    });
  };

  const resetBigFiles = () => {
    dispatchBigFiles({ type: "RESET_BIG_FILES" });
  };

  //* Abort controllers reducer

  function abortControllerReducer(state, action) {
    switch (action.type) {
      case "ADD_CONTROLLER":
        return new Map(state).set(action.fileName, action.controller);
      case "REMOVE_CONTROLLER":
        const newState = new Map(state);
        newState.delete(action.fileName);
        return newState;
      case "RESET_CONTROLLERS":
        return new Map();
      default:
        throw new Error();
    }
  }

  const [abortControllers, dispatchAbortControllers] = useReducer(
    abortControllerReducer,
    new Map()
  );

  //* Abort controllers reducer actions

  const addController = (fileName, controller) => {
    dispatchAbortControllers({ type: "ADD_CONTROLLER", fileName, controller });
  };

  const removeController = (fileName) => {
    dispatchAbortControllers({ type: "REMOVE_CONTROLLER", fileName });
  };

  const resetControllers = () => {
    dispatchAbortControllers({ type: "RESET_CONTROLLERS" });
  };

  //* Upload handler

  const uploadFiles = async (directoryId, refresh, uploadURL) => {
    setIsError(null);
    setUploadedCount(0);
    setUpload(true);
    setFileCounter(files.length);
    if (!files?.length) return;

    try {
      const uploadPromises = files.map((file) => {
        if (file.size > MAX_FILE_SIZE) {
          return uploadLargeFile(file, directoryId, refresh, uploadURL);
        } else {
          return uploadSmallFiles(file, directoryId, refresh, uploadURL);
        }
      });

      await Promise.all(uploadPromises);
      setUpload(false);
      setFiles([]);
      resetControllers();
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsError("500");
    }
  };

  //* Upload small files

  const uploadSmallFiles = async (file, directoryId, refresh, uploadURL) => {
    const URL = uploadURL + "upload/" + directoryId + `?fileName=${file.name}`;

    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: headers,
        body: file,
      });

      if (!response.ok) {
        setIsError(response.status);
      }

      setFiles((prevFiles) =>
        prevFiles.filter((prevFile) => prevFile !== file)
      );
      setUploadedCount((prevCount) => prevCount + 1);
      refresh();
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsError("500");
    }
  };

  //* Upload large files

  const uploadLargeFile = async (file, directoryId, refresh, uploadURL) => {
    const numberOfChunks = Math.ceil(file.size / CHUNK_SIZE);
    let uploadedChunks = 0;
    let uploadingChunkIndex = 0;
    let retry = { count: 0 };

    const uploadedChunkID = [];

    //* Upload chunks
    const uploadChunk = async (chunkIndex, multiId, retry) => {
      const controller = abortControllers.get(file.name);

      if (!controller || controller.signal.aborted) {
        try {
          const response = await fetch(uploadURL + `multipart/${multiId}`, {
            method: "DELETE",
            headers,
          });
          if (response.ok) {
            console.log("Upload successfully aborted by the user.");
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          setIsError("500");
        }
        return;
      }

      try {
        const start = chunkIndex * CHUNK_SIZE;
        const end = Math.min((chunkIndex + 1) * CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
        const ID = multiId;

        try {
          const response = await fetch(
            uploadURL + `multipart/${multiId}/chunks/${chunkIndex + 1}`,
            {
              method: "POST",
              headers,
              body: chunk,
              signal: controller.signal,
            }
          );

          const data = await response.json();
          uploadedChunkID.push(data);

          if (!response.ok) {
            setIsError(response.status);
            throw new Error(
              `Error uploading chunk ${chunkIndex + 1} to server.`
            );
          }

          uploadedChunks++;
          updateBigFile(file, uploadedChunks);
          uploadingChunkIndex = chunkIndex + 1;

          if (uploadedChunks === numberOfChunks) {
            //* Upload chunks finished

            try {
              const response = await fetch(uploadURL + `multipart/${multiId}`, {
                method: "POST",
                headers,
                body: JSON.stringify(uploadedChunkID),
              });
              if (!response.ok) {
                setIsError(response.status);
              } else {
                const controller = abortControllers.get(file.name);
                if (controller) {
                  controller.abort();
                  removeController(file.name);
                }
                setFiles((prevFiles) =>
                  prevFiles.filter((prevFile) => prevFile !== file)
                );
                setUploadedCount((prevCount) => prevCount + 1);
                setTimeout(() => {
                  removeBigFile(file.name);
                }, 2000);
                refresh();
              }
            } catch (error) {
              console.error("Error uploading file:", error);
              setIsError("500");
            }
          } else {
            //* Upload next chunk

            await uploadChunk(uploadingChunkIndex, ID, retry);
          }
        } catch (error) {
          console.error(
            `Error uploading chunk ${chunkIndex + 1} to server.`,
            error
          );
          setIsError("500");

          //* Retry upload 4 more times

          if (retry.count < 4) {
            retry.count++;
            await uploadChunk(uploadingChunkIndex, ID, retry);
          } else {
            //* Abort upload
            bigFileHasError(file.name);
            const controller = abortControllers.get(file.name);
            if (controller) {
              controller.abort();
              setFiles((prevFiles) =>
                prevFiles.filter((prevFile) => prevFile !== file)
              );
              removeController(file.name);
            }

            try {
              const response = await fetch(uploadURL + `multipart/${multiId}`, {
                method: "DELETE",
                headers,
              });
              if (response.ok) {
                console.log(
                  "Upload failed, the uploaded chunks has been deleted."
                );
              }
            } catch (error) {
              console.error("Error uploading file:", error);
              setIsError("500");
            }
            return;
          }
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        setIsError("500");
      }
    };

    try {
      const response = await fetch(
        uploadURL +
          `multipart/?fileName=${file.name}&parentDocumentId=${directoryId}`,
        {
          method: "POST",
          headers: headers,
        }
      );
      const data = await response.json();

      if (!response.ok) {
        setIsError(response.status);
      } else {
        //* Upload first chunk

        await uploadChunk(uploadingChunkIndex, data.id, retry);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsError("500");
    }
  };

  return (
    <DnDContext.Provider
      value={{
        files: files,
        setFiles: fileHandler,
        upload: upload,
        setUpload: setUpload,
        uploadedCount: uploadedCount,
        setUploadedCount: setUploadedCount,
        uploadFiles: uploadFiles,
        fileCounter: fileCounter,
        setFileCounter: setFileCounter,
        isError: isError,
        setIsError: setIsError,
        bigFiles: bigFiles,
        addBigFile: addBigFile,
        removeBigFile: removeBigFile,
        abortBigFile: abortBigFile,
        bigFileHasError: bigFileHasError,
        resetBigFiles: resetBigFiles,
        abortControllers: abortControllers,
        removeController: removeController,
        CHUNK_SIZE: MAX_FILE_SIZE,
      }}
    >
      {props.children}
    </DnDContext.Provider>
  );
};

export default DnDContext;

import React, { useContext, useEffect, useRef, useState } from "react";
import * as Hi2Icons from "react-icons/hi2";
import * as LiaIcons from "react-icons/lia";
import { Link, useLoaderData, useParams } from "react-router-dom";
import { useBrowserFetching } from "../../../../hooks/use-browserfetching";
import ErrorPage from "../../../ErrorPage";
import Button from "../../../UI/Button/Button";
import Card from "../../../UI/Card/Card";
import Confirm from "../../../UI/Confirm/Confirm";
import Dropzone from "../../../UI/Input/DropZone";
import Lightbox from "../../../UI/LightBox";
import Table from "../../../UI/Table/Table";
import ModalContext from "../../../context/modal-context";
import { getAuthToken } from "../../../util/auth";
import { fetchRequest } from "../../../util/fetchRequest";
import EditDirectory from "./EditDirectory";
import styles from "./FileBrowser.module.css";
import ShareDocument from "./ShareDocuments";
import Tooltip from "../../../UI/Tooltip/Tooltip";
import GridBrowser from "./GridBrowser";
import SegmentedSwitch from "../../../UI/Switch/SegmentedSwitch";

export const MyFileIcon = ({ kind, ext, size }) => {
  if (kind === "back") {
    return (
      <LiaIcons.LiaLevelUpAltSolid
        className={styles["browser-up-icon"]}
        size={size}
      />
    );
  } else if (kind === "directory") {
    return (
      <Hi2Icons.HiOutlineFolder
        className={styles["browser-icons"]}
        size={size}
      />
    );
  } else if (ext === "jpg" || ext === "png" || ext === "JPG") {
    return (
      <Hi2Icons.HiOutlinePhoto
        className={styles["browser-icons"]}
        size={size}
      />
    );
  } else if (ext === "avi" || ext === "mov" || ext === "mp4" || ext === "mkv") {
    return (
      <Hi2Icons.HiOutlineFilm className={styles["browser-icons"]} size={size} />
    );
  } else {
    return (
      <Hi2Icons.HiOutlineDocument
        className={styles["browser-icons"]}
        size={size}
      />
    );
  }
};

const MyFileBrowser = (props) => {
  const params = useParams();
  const modalCtx = useContext(ModalContext);
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  const options = useLoaderData();

  const [images, setImages] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingEndpoint, setLoadingEndpoint] = useState(true);

  //* Fetch with sort and search params
  const [URLsuffix, setURLsuffix] = useState(props.URLsuffix + props.root);

  const { error, pageData, getTableData } = useBrowserFetching(URLsuffix);

  const [pictureRowData, setPictureRowData] = useState([]);

  const [modalType, setModalType] = useState("");

  const [isGridView, setIsGridView] = useState(false);

  const [directoryId, setDirectoryId] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [path, setPath] = useState([{ id: "", name: "Home" }]);

  useEffect(() => {
    if (path.length === 1) {
      setURLsuffix(props.URLsuffix + props.root);
    }
    setLoadingEndpoint(false);
  }, [path]);

  //* Handlers

  const deleteHandler = (id) => {
    setDirectoryId(id);
    setModalType("delete");
    modalCtx.showModal();
  };

  const deleteAction = async () => {
    const URL = process.env.REACT_APP_API_URL + props.URLsuffix + directoryId;
    await fetchRequest(URL, "DELETE", headers, undefined);

    getTableData();
  };

  const createHandler = () => {
    setModalType("new");
    modalCtx.showModal();
  };

  const shareHandler = (id, name) => {
    setDirectoryId(id);
    setDocumentName(name);
    setModalType("share");
    modalCtx.showModal();
  };

  const downloadFile = (fileURL) => {
    const link = document.createElement("a");
    link.href = fileURL;
    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
  };

  const clickHandler = ({ dirId, dirName, kind, ext, index }) => {
    if (kind === "directory") {
      setPath((prevPath) => [...prevPath, { id: dirId, name: dirName }]);
      setDirectoryId(dirId);
      setURLsuffix(props.URLsuffix + dirId);
    } else if (kind === "back") {
      const prevDirId = path[path.length - 2].id;
      setPath((prevPath) => prevPath.slice(0, prevPath.length - 1));
      setURLsuffix(props.URLsuffix + prevDirId);
    } else if (
      kind === "document" &&
      ext !== "png" &&
      ext !== "jpg" &&
      ext !== "jpeg" &&
      ext !== "PNG" &&
      ext !== "JPG" &&
      ext !== "JPEG"
    ) {
      const fileURL =
        process.env.REACT_APP_API_URL + "file/documents/download/" + dirId;
      downloadFile(fileURL);
    } else if (
      ext === "png" ||
      ext === "jpg" ||
      ext === "jpeg" ||
      ext === "PNG" ||
      ext === "JPG" ||
      ext === "JPEG"
    ) {
      setCurrentIndex(index);
      setOpen(true);
    }
  };

  useEffect(() => {
    setURLsuffix(props.URLsuffix + props.root);
  }, [props.URLsuffix, params.partnerId]);

  useEffect(() => {
    getTableData();
  }, [URLsuffix]);

  const downloadPicture = () => {
    const pictureURL = images[currentImageIndex].src;
    downloadFile(pictureURL);
  };

  //* Icons

  useEffect(() => {
    let updatedRowData = pageData.rowData.slice();

    if (URLsuffix !== props.URLsuffix + props.root) {
      updatedRowData = [
        {
          id: "",
          name: "..",
          kind: "back",
        },
        ...pageData.rowData,
      ];
    }

    let currentIndex = 0;
    const indexedData = updatedRowData.map((data) => {
      if (["jpg", "jpeg", "png"].includes(data.ext?.toLowerCase())) {
        return { ...data, index: currentIndex++ };
      }
      return { ...data, index: undefined };
    });

    setPictureRowData(indexedData);
    setIsLoading(false);
  }, [URLsuffix, props.URLsuffix, pageData.rowData]);

  //* Lightbox

  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const currentImages = [];
    pageData.rowData.forEach((data) => {
      if (["jpg", "jpeg", "png"].includes(data.ext?.toLowerCase())) {
        currentImages.push({
          src:
            process.env.REACT_APP_API_URL +
            "file/documents/download/" +
            data.id,
          loading: "lazy",
          alt: data.name,
        });
      }
    });
    setImages(currentImages);
  }, [pageData.rowData]);

  //* Navigation

  const Navigation = () => {
    const navigationClickHandler = (dirId) => {
      setURLsuffix(props.URLsuffix + dirId);
      setPath((prevPath) =>
        prevPath.slice(0, prevPath.findIndex((dir) => dir.id === dirId) + 1)
      );
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 800);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return (
      <Card className={styles["navigation-container"]}>
        <div className={styles.head}>
          <div className={styles.navbreadcrumbs}>
            {path.map((dir, index) => (
              <li
                key={dir.id}
                className={styles.crumb}
                onClick={() => navigationClickHandler(dir.id)}
              >
                <Hi2Icons.HiOutlineFolderOpen
                  className={styles["navigation-icons"]}
                />
                {isMobile && index !== path.length - 1 ? "..." : dir.name}
              </li>
            ))}
          </div>
        </div>
      </Card>
    );
  };

  //* Table header

  const [noIcons, setNoIcons] = useState(false);

  useEffect(() => {
    if (path.length <= 1 && props.root) {
      setNoIcons(true);
    } else {
      setNoIcons(false);
    }
  }, [path, props.root]);

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      width: "95%",

      Cell: ({ value, row }) => (
        <Link
          className={styles.name}
          onClick={() =>
            clickHandler({
              dirId: row.original.id,
              dirName: row.original.name,
              kind: row.original.kind,
              ext: row.original.ext,
              index: row.original.index,
            })
          }
        >
          <MyFileIcon
            kind={row.original.kind}
            ext={row.original.ext}
            size={22}
          />
          {value}
        </Link>
      ),
    },
    {
      Header: "Actions",
      accessor: "id",
      id: "actions",
      width: "100px",

      Cell: ({ value, row }) =>
        row.original.kind === "back" || noIcons ? (
          ""
        ) : (
          <>
            <Tooltip text={"Share document"}>
              <Hi2Icons.HiOutlineShare
                className={styles["share-icon"]}
                size={22}
                onClick={() => shareHandler(row.original.id, row.original.name)}
              />
            </Tooltip>
            {/* <Hi2Icons.HiOutlinePencil
            className={styles["edit-icon"]}
            onClick={() => editHandler(row.original.id)}
          /> */}
            <Tooltip text={"Delete document"}>
              <Hi2Icons.HiOutlineTrash
                className={styles["delete-icon"]}
                size={22}
                onClick={() => deleteHandler(row.original.id)}
              />
            </Tooltip>
          </>
        ),
    },
  ];

  const handleToggle = (isOn) => {
    if (isOn) {
      setIsGridView(true);
    } else {
      setIsGridView(false);
    }
  };

  return (
    <>
      {images.length > 0 && (
        <Lightbox
          currentImageIndex={currentImageIndex}
          setCurrentIndex={setCurrentIndex}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          images={images}
          galleryTitle={path[path.length - 1]?.name}
          download={downloadPicture}
          pageTransitionConfig={{
            from: { transform: "scale(0.75)", opacity: 0 },
            enter: { transform: "scale(1)", opacity: 1 },
            leave: { transform: "scale(0.75)", opacity: 0 },
            config: { mass: 1, tension: 320, friction: 32 },
          }}
        />
      )}
      {modalCtx.isModal && modalType === "delete" && (
        <Confirm onDelete={deleteAction} />
      )}
      {modalCtx.isModal && modalType === "new" && (
        <EditDirectory
          method="POST"
          refresh={getTableData}
          currentDir={URLsuffix}
        />
      )}
      {modalCtx.isModal && modalType === "share" && (
        <ShareDocument
          refresh={getTableData}
          options={options}
          name={documentName}
          URLsuffix={props.URLsuffix + "share/" + directoryId}
        />
      )}
      <div className={styles.title}>
        <h1>Documents</h1>
        <p>List of available documents.</p>
      </div>
      {path.length === 1 && props.root ? (
        ""
      ) : (
        <div className={styles.control}>
          <div className={styles["control-buttons"]}>
            <Button className={styles["create-button"]} onClick={createHandler}>
              Create directory
            </Button>
          </div>
        </div>
      )}
      <div className={styles.navigation}>
        <Navigation />
        <div className={styles.gridControl}>
          <SegmentedSwitch onToggle={handleToggle} />
        </div>
      </div>
      <Card className={styles.card}>
        {error ? (
          <ErrorPage
            error={error}
            title={error.title}
            message={error.message}
          />
        ) : (
          <>
            {!isLoading && !loadingEndpoint && (
              <>
                {isGridView ? (
                  <GridBrowser
                    data={pictureRowData}
                    noIcons={noIcons}
                    clickHandler={clickHandler}
                    onDelete={deleteHandler}
                    onShare={shareHandler}
                  />
                ) : (
                  <Table
                    columns={columns}
                    data={pictureRowData}
                    isLoading={pageData.isLoading}
                  />
                )}
              </>
            )}
            {path.length === 1 && props.root ? (
              ""
            ) : (
              <div className={styles.upload}>
                <Dropzone
                  uploadURL={process.env.REACT_APP_API_URL + props.URLsuffix}
                  directoryId={path[path.length - 1]?.id}
                  refresh={getTableData}
                />
              </div>
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default MyFileBrowser;

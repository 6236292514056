import { createContext, useContext, useEffect, useState } from "react";
import FormDataContext from "./formData-context";

const ModalContext = createContext({
  isModal: false,
  showModal: () => {},
});

export const ModalContextProvider = (props) => {
  const [isModal, setIsModal] = useState(false);
  const dataCtx = useContext(FormDataContext);

  const modalHandler = () => {
    setIsModal(!isModal);
    dataCtx.editFromData({});
  };

  useEffect(() => {
    if (isModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isModal]);

  return (
    <ModalContext.Provider
      value={{
        isModal: isModal,
        showModal: modalHandler,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalContext;

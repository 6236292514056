import ProfilePage from "../../components/User/ProfilePage";
import { getAuthToken, getUserId } from "../../components/util/auth";
import { fetchRequest } from "../../components/util/fetchRequest";

function Profile() {
  return <ProfilePage />;
}

export default Profile;

export async function loader() {
  const id = getUserId();
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  const URL = process.env.REACT_APP_API_URL + `user/accounts/${id}`;
  const method = "GET";

  const response = await fetchRequest(URL, method, headers, undefined);

  return response;
}

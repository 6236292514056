import React, { useState, useEffect, useRef } from "react";
import styles from "./SharedLocationsPage.module.css";
import { useContext } from "react";
import Table from "../../components/UI/Table/Table";
import Header from "../../components/UI/Table/Header";
import Pagination from "../../components/UI/Pagination/Pagination";
import Card from "../../components/UI/Card/Card";
import { Link } from "react-router-dom";
import SearchBar from "../../components/UI/SearchBar/SearchBar";
import ErrorPage from "../../components/ErrorPage";
import { useDataFetching } from "../../hooks/use-datafetching";
import * as Hi2Icons from "react-icons/hi2";
import ModalContext from "../context/modal-context";
import LocationDetailsPage from "../AVA/Intranet/Locations/LocationDetailsPage";
import Button from "../UI/Button/Button";
import { fetchDataRequest } from "../util/fetchDataRequest";
import { getAuthToken } from "../util/auth";
import Tooltip from "../UI/Tooltip/Tooltip";

function SharedLocationsPage() {
  const token = getAuthToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "content-disposition": `attachment; filename="locations.csv"`,
  };
  const modalCtx = useContext(ModalContext);
  const [locationId, setLocationId] = useState("");
  const [partnerId, setPartnerId] = useState("");

  const URLsuffix = "intranet/locations";

  //* Fetch with sort and search params

  const [currentPage, setCurrentPage] = useState(1);
  const [sortParam, setSortParam] = useState("");
  const [searchParam, setSearchParam] = useState("");

  const { error, pageData, getTableData } = useDataFetching(
    currentPage,
    URLsuffix,
    sortParam,
    searchParam
  );

  //* Search

  const searchRef = useRef(null);

  const handleSearch = (event) => {
    event.preventDefault();
    const searchValue = searchRef.current.value;
    setSearchParam(`&search=${searchValue}`);
  };

  const clearSearchHandler = () => {
    setSearchParam("");
  };

  useEffect(() => {
    setCurrentPage(1);
    getTableData();
  }, [searchParam]);

  //* Sorting

  const sortLocations = (accessor, order) => {
    setSortParam(`&sort=${accessor}&order=${order}`);
  };

  useEffect(() => {
    getTableData();
  }, [sortParam]);

  //* Handlers

  const detailsHandler = (locationId, parnterId) => {
    setLocationId(locationId);
    setPartnerId(parnterId);

    modalCtx.showModal();
  };

  //* Download CSV

  const downloadCSV = () => {
    const requestURL = process.env.REACT_APP_API_URL + `intranet/locations/csv`;
    fetchDataRequest(requestURL, "GET", headers, undefined)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = "locations.csv";

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  //* Table header
  const columns = [
    {
      Header: (
        <Header sortHandler={sortLocations} title="Company" id="partner.name" />
      ),
      accessor: "partnerName",
      width: "25%",

      Cell: ({ value, row }) => (
        <Link
          className={styles.name}
          onClick={() =>
            detailsHandler(row.original.id, row.original.partnerId)
          }
        >
          {value}
        </Link>
      ),
    },
    {
      Header: (
        <Header sortHandler={sortLocations} title="Country" id="country" />
      ),
      accessor: "country",
      width: "25%",

      Cell: ({ value, row }) => (
        <Link
          className={styles.name}
          onClick={() =>
            detailsHandler(row.original.id, row.original.partnerId)
          }
        >
          {value}
        </Link>
      ),
    },
    {
      Header: <Header sortHandler={sortLocations} title="City" id="city" />,
      accessor: "city",
      width: "25%",

      Cell: ({ value, row }) => (
        <Link
          className={styles.name}
          onClick={() =>
            detailsHandler(row.original.id, row.original.partnerId)
          }
        >
          {value}
        </Link>
      ),
    },
    {
      Header: (
        <Header sortHandler={sortLocations} title="Address" id="address" />
      ),
      accessor: "address",
      width: "25%",

      Cell: ({ value, row }) => (
        <Link
          className={styles.name}
          onClick={() =>
            detailsHandler(row.original.id, row.original.partnerId)
          }
        >
          {value}
        </Link>
      ),
    },
    {
      Header: "Info",
      accessor: "id",
      id: "info",
      width: "50px",

      Cell: ({ row, value }) => (
        <Tooltip text="Open details">
          <Hi2Icons.HiOutlineInformationCircle
            className={styles["details-icon"]}
            onClick={() =>
              detailsHandler(row.original.id, row.original.partnerId)
            }
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      {modalCtx.isModal && (
        <LocationDetailsPage locationId={locationId} partnerId={partnerId} />
      )}
      <div className={styles.title}>
        <h1>Locations</h1>
        <p>The complete list of the AV Alliance network locations.</p>
      </div>
      <div className={styles.control}>
        <Button className={styles["csv-button"]} onClick={downloadCSV}>
          Download CSV
        </Button>

        <SearchBar
          searchRef={searchRef}
          onSubmit={handleSearch}
          clearSearch={clearSearchHandler}
        />
      </div>
      <Card className={styles.card}>
        {error ? (
          <ErrorPage
            error={error}
            title={error.title}
            message={error.message}
          />
        ) : (
          <>
            <Table
              columns={columns}
              data={pageData.rowData}
              isLoading={pageData.isLoading}
            />
            <Pagination
              totalRows={pageData.totalRecords}
              pageChangeHandler={setCurrentPage}
              rowsPerPage={20}
              currentPage={currentPage}
            />
          </>
        )}
      </Card>
    </>
  );
}

export default SharedLocationsPage;

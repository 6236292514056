import { useState, useEffect } from "react";
import Select from "react-select";
import styles from "./SingleSelectNew.module.css";

const SingleSelectNew = (props) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const handleThemeChange = (event) => {
      setIsDarkMode(event.matches);
    };

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(mediaQuery.matches);
    mediaQuery.addListener(handleThemeChange);

    return () => {
      mediaQuery.removeListener(handleThemeChange);
    };
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isDarkMode ? "#333" : "#ffffff",
      fontSize: "16px",
      height: "35px",
      minHeight: "35px",
      borderRadius: "5px",
      border: state.isFocused
        ? "1px solid #007aff4d"
        : isDarkMode
        ? "1px solid #555"
        : "1px solid #e0e0e0",
      boxShadow: state.isFocused ? "0 0 5px 1px #007aff4d" : "initial",
      "&:hover": {
        border: "1px solid initial",
        cursor: "pointer",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? "#555" : "#e0e0e0",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: isDarkMode ? "#555" : "inherit",
      padding: "0px 5px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: "13px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? "#333" : "#ffffff",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#e0e0e0" : "inherit",
      color: state.isFocused ? "#333" : "inherit",
      "&:hover": {
        backgroundColor: "#e0e0e0",
        color: "#333",
        cursor: "pointer",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "inherit",
      marginTop: "0px",
    }),
  };

  const [selectedOption, setSelectedOption] = useState();

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    props.onSingleChange(selectedOption, props.name);
  };

  return (
    <div className={`${styles.singleContainer} ${props.className}`}>
      {props.label && (
        <label htmlFor={props.id} className={styles.singleLabel}>
          <div>{props.label}</div>
          <div className={styles.note}>{props.note}</div>
        </label>
      )}
      <Select
        styles={customStyles}
        defaultValue={props.defaultValue}
        minMenuHeight={600}
        maxMenuHeight={160}
        menuPlacement={props.menuPlacement}
        id={props.id}
        name={props.name}
        options={props.options}
        onChange={handleChange}
        value={selectedOption}
      />
    </div>
  );
};
export default SingleSelectNew;

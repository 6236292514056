import styles from "./TableHeader.module.css";

function TableHeader({ headerGroups }) {
  return (
    <thead className={styles.tableHeader}>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th {...column.getHeaderProps()} style={{ width: column.width }}>
              {column.render("Header")}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}

export default TableHeader;

import React, { useContext, useEffect, useState } from "react";
import { Form, useParams } from "react-router-dom";
import styles from "./LeadComments.module.css";
import TextArea from "../../../UI/Input/TextArea";
import Button from "../../../UI/Button/Button";
import FormDataContext from "../../../context/formData-context";
import { getAuthToken } from "../../../util/auth";
import moment from "moment";
import * as Hi2Icons from "react-icons/hi2";
import ModalContext from "../../../context/modal-context";
import Confirm from "../../../UI/Confirm/Confirm";
import { fetchRequest } from "../../../util/fetchRequest";

function LeadComments(props) {
  const dataCtx = useContext(FormDataContext);
  const modalCtx = useContext(ModalContext);
  const token = getAuthToken();
  const params = useParams();

  const URL =
    process.env.REACT_APP_API_URL +
    `crm/${params.partnerId}/leads/${params.leadId}/comments`;
  const headers = { Authorization: `Bearer ${token}` };

  const [comments, setComments] = useState([]);
  const [commentId, setCommentId] = useState("");
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    let initialValues = {
      value: "",
    };

    const requestData = {
      url: URL,
      method: "POST",
      headers: headers,
    };

    setComments(props.comments);

    dataCtx.editFromData(initialValues);
    dataCtx.editRequestData(requestData);
  }, []);

  const fetchLeadComments = async () => {
    const URL =
      process.env.REACT_APP_API_URL +
      `crm/${params.partnerId}/leads/${params.leadId}/comments`;

    const response = await fetchRequest(URL, "GET", headers, undefined);
    return response.items;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: value,
    });
  };

  const submitHandler = async () => {
    await dataCtx.fetchData();
    fetchLeadComments().then((data) => {
      setComments(data);
    });
    dataCtx.editFromData({
      value: "",
    });
  };

  const deleteHandler = (id) => {
    setCommentId(id);
    setIsDelete(true);
    modalCtx.showModal();
  };

  const deleteAction = async () => {
    const deleteURL = URL + `/${commentId}`;
    await fetchRequest(deleteURL, "DELETE", headers, undefined);
    fetchLeadComments().then((data) => {
      setComments(data);
    });
  };

  const commentList = comments.map((item) => (
    <div className={styles.comment} key={item.id}>
      <p className={styles.value}>{item.value}</p>
      <div className={styles["comment-footer"]}>
        <p className={styles.date}>
          {moment(item.date).format("YYYY.MM.DD HH:mm")}
        </p>
        <button type="button" onClick={() => deleteHandler(item.id)}>
          <Hi2Icons.HiOutlineTrash className={styles.rejectedIcon} />
        </button>
      </div>
    </div>
  ));

  return (
    <>
      {modalCtx.isModal && isDelete && <Confirm onDelete={deleteAction} />}
      {commentList}
      <Form>
        <TextArea
          areaClass={styles["comment-box"]}
          label="New comment"
          id="value"
          name="value"
          rows={5}
          value={dataCtx.formData.value}
          onChange={handleInputChange}
        />
        <div className={styles["buttons"]}>
          <Button
            type="submit"
            className={styles["submit-button"]}
            onClick={submitHandler}
            // disabled={isDisabled}
          >
            Send
          </Button>
        </div>
      </Form>
    </>
  );
}

export default LeadComments;

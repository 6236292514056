import { createContext, useState } from "react";
import { fetchRequest } from "../util/fetchRequest";

const FormDataContext = createContext({
  formData: {},
  editFromData: () => {},
  requestData: {},
  editRequestData: () => {},
  fetchData: () => {},
});

export const FormDataContextProvider = (props) => {
  const [formData, setFormData] = useState({});
  const [requestData, setRequestData] = useState({});

  const formDataHandler = (data) => {
    setFormData(data);
  };

  const requestDataHandler = (data) => {
    setRequestData(data);
  };

  const fetchData = async () => {
    if (formData) {
      try {
        const data = await fetchRequest(
          requestData.url,
          requestData.method,
          requestData.headers,
          formData
        );
        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
  };

  return (
    <FormDataContext.Provider
      value={{
        formData: formData,
        editFromData: formDataHandler,
        requestData: requestData,
        editRequestData: requestDataHandler,
        fetchData: fetchData,
      }}
    >
      {props.children}
    </FormDataContext.Provider>
  );
};

export default FormDataContext;

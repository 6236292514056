import Card from "./UI/Card/Card";
import Button from "./UI/Button/Button";
import styles from "./Dashboard.module.css";
import { Img } from "react-image";
import { useContext } from "react";
import MenuContext from "./context/sidebar-context";
import { Link } from "react-router-dom";
import { getMyRoles } from "./util/auth";

function Dashboard() {
  const menuCtx = useContext(MenuContext);
  const myRoles = getMyRoles();

  const handleMenu = () => {
    menuCtx.showSidebar();
  };

  return (
    <Card className={styles.container}>
      <h1 className={styles.title}>AV Alliance Intranet</h1>
      <p className={styles.content}>
        The Intranet is your place to find all the information you need about
        your fellow AV Alliance members and partners, including contact details
        and locations, and to view, download, or even upload shared documents
        and media files - all under the same roof. The Contact section of the
        Intranet is linked with the AV Alliance App, giving you easy access to
        members and partners.
      </p>
      <p className={styles.content}>
        Now integrated as part of the Intranet, the CRM system is a platform
        specifically developed for you to help simplify project and lead
        management, tailoring it to your needs. It allows you to receive, track,
        and follow up on event requests that you receive through the AV
        Alliance, all in the same place.
      </p>
      <p className={styles.content}>
        <a href="/file/documents/download/E4A53125-EDA5-4E8B-B8A2-29B363250FE3">
          Click here
        </a>{" "}
        to learn all about the use of the AV Alliance Intranet.
      </p>
      <div className={styles.buttons}>
        <div className={styles["button-container"]}>
          <Button
            id="menu-button"
            className={styles.button}
            onClick={handleMenu}
          >
            Menu
          </Button>
        </div>
        <div className={styles["button-container"]}>
          {myRoles.includes("ava-manager") ? (
            <Link to="/av-alliance/intranet/documents">
              <Button className={styles.button}>Documents</Button>
            </Link>
          ) : (
            <Link to="/intranet/shared/documents">
              <Button className={styles.button}>Documents</Button>
            </Link>
          )}
        </div>
        <div className={styles["button-container"]}>
          <Link to="/intranet/shared/contacts">
            <Button className={styles.button}>Contacts</Button>
          </Link>
        </div>
        <div className={styles["button-container"]}>
          <Link to="/intranet/shared/locations">
            <Button className={styles.button}>Locations</Button>
          </Link>
        </div>
      </div>
      <h3 className={styles.subtitle}>
        Feel free to download our native mobile apps:
      </h3>
      <div className={styles["store-container"]}>
        <div className={styles["button-container"]}>
          <a
            href="https://play.google.com/store/apps/details?id=com.avalliance.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img src="/GooglePlay.png" alt="google play download" height={50} />
          </a>
        </div>
        <div className={styles["button-container"]}>
          <a
            href="https://apps.apple.com/hu/app/av-alliance/id1483110102?l=hu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img src="/AppStore.png" alt="google play download" height={50} />
          </a>
        </div>
      </div>
    </Card>
  );
}

export default Dashboard;

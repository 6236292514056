import { createContext, useState } from "react";

const MenuContext = createContext({
  sidebar: false,
  showSidebar: () => {},
});

export const MenuContextProvider = (props) => {
  const [isSidebar, setIsSidebar] = useState(false);

  const sidebarHandler = () => setIsSidebar(!isSidebar);

  return (
    <MenuContext.Provider
      value={{
        sidebar: isSidebar,
        showSidebar: sidebarHandler,
      }}
    >
      {props.children}
    </MenuContext.Provider>
  );
};

export default MenuContext;

import { useEffect, useState } from "react";
import styles from "./PhoneInputField.module.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./PhoneInputField.css";

function PhoneInputField(props) {
  const [value, setValue] = useState(props.startValue);

  useEffect(() => {
    props.onChange(value);
  }, [value]);

  return (
    <>
      <label className={styles.label}>
        <div>{props.label}</div>
        <div className={styles.note}>{props.note}</div>
      </label>
      <PhoneInput
        id={props.id}
        name={props.name}
        label={props.label}
        title={props.title}
        international
        countryCallingCodeEditable={true}
        value={value}
        onChange={setValue}
      />
    </>
  );
}

export default PhoneInputField;

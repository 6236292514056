import { useEffect, useContext, useState } from "react";
import styles from "./PartnerUpdatePage.module.css";
import Card from "../../../UI/Card/Card";
import { Form, useLoaderData, useNavigate, useParams } from "react-router-dom";
import Input from "../../../UI/Input/Input";
import Button from "../../../UI/Button/Button";
import FormDataContext from "../../../context/formData-context";
import { getAuthToken } from "../../../util/auth";
import SingleSelectNew from "../../../UI/Input/SingleSelectNew";
import MultiSelect from "../../../UI/Input/MultiSelect";
import { Img } from "react-image";
import ModalContext from "../../../context/modal-context";
import UploadLogo from "./UploadLogo";
import Confirm from "../../../UI/Confirm/Confirm";
import { fetchRequest } from "../../../util/fetchRequest";
import UserContext from "../../../context/user-context";

function PartnerUpdatePage(props) {
  const userCtx = useContext(UserContext);
  const data = useLoaderData();
  const method = props.method;
  const token = getAuthToken();
  const params = useParams();
  const headers = { Authorization: `Bearer ${token}` };

  const modalCtx = useContext(ModalContext);

  const navigation = useNavigate();

  const dataCtx = useContext(FormDataContext);

  const [modalType, setModalType] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dataCtx.editFromData({});
  }, []);

  const kindOptions = [
    { value: "member", label: "Member" },
    { value: "sponsor", label: "Sponsor" },
  ];

  let defaultKindOption = "";
  let defaultPrimaryUserId = "";
  let defaultCurrenciesValues = "";
  let defaultUsersValues = "";

  if (method === "PUT") {
    defaultKindOption = kindOptions.find(
      (option) => option.value === data.partnerData.kind
    );

    defaultPrimaryUserId = data.usersOptions.find(
      (option) => option.value === data.partnerData.primaryUserId
    );

    defaultCurrenciesValues = data.partnerData.currencies.map((value) => {
      return data.currenciesOptions.find((option) => option.value === value);
    });

    defaultUsersValues = data.partnerData.users.map((value) => {
      return data.usersOptions.find((option) => option.value === value);
    });
  }

  useEffect(() => {
    setIsLoading(true);
    let initialValues = {
      name: "",
      kind: "",
      currencies: [],
      primaryUserId: "",
      users: [],
    };
    let URLsuffix = "";

    if (method === "PUT") {
      initialValues = {
        name: data.partnerData.name,
        kind: data.partnerData.kind,
        currencies: data.partnerData.currencies,
        primaryUserId: data.partnerData.primaryUserId,
        users: data.partnerData.users,
      };
      URLsuffix = data.partnerData.id;
    }

    dataCtx.editFromData(initialValues);

    const requestData = {
      url: process.env.REACT_APP_API_URL + "ava/partners/" + URLsuffix,
      method: method,
      headers: headers,
    };

    dataCtx.editRequestData(requestData);

    dataCtx.editRequestData(requestData);
    setIsLoading(false);
  }, [data, method]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    dataCtx.editFromData({
      ...dataCtx.formData,
      [name]: value,
    });
  };

  const handleSingleSelectChange = (selectedOption, fieldName) => {
    const selectedValue = selectedOption.value;
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      [fieldName]: selectedValue,
    }));
  };

  const handleMultiSelectChange = (selectedOptions, fieldName) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    dataCtx.editFromData((prevFormValues) => ({
      ...prevFormValues,
      [fieldName]: selectedValues,
    }));
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    await dataCtx.fetchData();
    userCtx.getNewCompanies();
    navigation(-1);
  };

  const uploadHandler = () => {
    setModalType("upload");
    modalCtx.showModal();
  };

  const imageDeleteHandler = () => {
    setModalType("delete");
    modalCtx.showModal();
  };

  const uploadImageHandler = async (file, fileName) => {
    const URL =
      process.env.REACT_APP_API_URL +
      `ava/partners/${params.partnerId}/image?fileName=${fileName}`;
    fetch(URL, {
      method: "POST",
      body: file,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setImgSrc(
          process.env.REACT_APP_API_URL + `file/download?key=${data.imageKey}`
        );
      })
      .catch((err) => {
        console.error(err);
      });

    modalCtx.showModal();
  };

  const deleteAction = async () => {
    const URL =
      process.env.REACT_APP_API_URL +
      `ava/partners/${params.partnerId}/image?key=${data.partnerData.imageKey}`;
    await fetchRequest(URL, "DELETE", headers, undefined);
    setImgSrc("");
  };

  const title = method === "PUT" ? "Edit" : method === "POST" ? "Create" : "";

  useEffect(() => {
    if (data.partnerData && data.partnerData.imageKey) {
      setImgSrc(
        process.env.REACT_APP_API_URL +
          `file/download?key=${data.partnerData.imageKey}`
      );
    }
  }, [data]);

  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    if (
      dataCtx.formData.name &&
      dataCtx.formData.kind &&
      dataCtx.formData.primaryUserId
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [dataCtx.formData]);

  return (
    <Card className={styles.card}>
      {modalCtx.isModal && modalType === "upload" && (
        <UploadLogo upload={uploadImageHandler} />
      )}
      {modalCtx.isModal && modalType === "delete" && (
        <Confirm onDelete={deleteAction} />
      )}

      <h1 className={styles.title}>{title} partner</h1>
      {!isLoading && (
        <Form>
          {method === "PUT" && (
            <>
              <p>Image</p>
              <Img
                src={imgSrc}
                alt="Patner logo"
                height={150}
                loader={<img src="/spinner.gif" alt="loading..."></img>}
              />
              <div className={styles.buttons}>
                <Button id="imageButton" type="button" onClick={uploadHandler}>
                  Upload image
                </Button>
                <Button
                  className={styles["delete-button"]}
                  id="imageDeleteButton"
                  type="button"
                  onClick={imageDeleteHandler}
                >
                  Delete image
                </Button>
              </div>
            </>
          )}
          <Input
            label="Name"
            id="name"
            name="name"
            note="(Required)"
            value={dataCtx.formData.name || ""}
            onChange={handleInputChange}
          />
          <SingleSelectNew
            className={styles.select}
            id="kind"
            name="kind"
            label="Category"
            options={kindOptions}
            defaultValue={defaultKindOption}
            value={dataCtx.formData.kind}
            onSingleChange={handleSingleSelectChange}
          />
          <MultiSelect
            id="currencies"
            name="currencies"
            label="Currencies"
            options={data.currenciesOptions}
            defaultValue={defaultCurrenciesValues}
            value={dataCtx.formData.currencies}
            onMultiChange={handleMultiSelectChange}
          />
          <SingleSelectNew
            className={styles.select}
            id="primaryUserId"
            name="primaryUserId"
            label="Primary contact"
            menuPlacement="top"
            options={data.usersOptions}
            defaultValue={defaultPrimaryUserId}
            value={dataCtx.formData.primaryContact}
            onSingleChange={handleSingleSelectChange}
          />
          <MultiSelect
            id="users"
            name="users"
            label="Users"
            menuPlacement="top"
            options={data.usersOptions}
            defaultValue={defaultUsersValues}
            value={dataCtx.formData.users}
            onMultiChange={handleMultiSelectChange}
          />
          <div className={styles["submit-button"]}>
            <Button
              type="submit"
              onClick={submitHandler}
              disabled={!formIsValid}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Card>
  );
}

export default PartnerUpdatePage;

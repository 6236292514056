import { useState, useEffect, useContext } from "react";
import styles from "./UserDropDown.module.css";
import { IconContext } from "react-icons";
import { Form, Link } from "react-router-dom";
import * as Hi2Icons from "react-icons/hi2";
import { getEmail, getPosition, getName, getMyRoles } from "../../util/auth";
import { Img } from "react-image";
import UserContext from "../../context/user-context";

function Dropdown() {
  const userCtx = useContext(UserContext);

  const imageKey = userCtx.imageKey;
  const email = getEmail();
  const name = getName();
  const position = getPosition();
  const roles = getMyRoles();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest("#usermenu") &&
        !event.target.closest("#userbutton") &&
        !event.target.closest("#userphoto") &&
        isOpen === true
      ) {
        toggleDropdown();
      }
    };

    if (isOpen === true) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  const UserButton = () => {
    return (
      <button
        id="userbutton"
        className={styles.dropdown_toggle}
        onClick={toggleDropdown}
      >
        <Hi2Icons.HiOutlineUser className={styles.toggleicon} />
      </button>
    );
  };

  return (
    <IconContext.Provider value={{ className: styles.icon }}>
      <div className={styles.container}>
        <div className={styles.dropdown}>
          <Img
            className={styles["profile-icon"]}
            onClick={toggleDropdown}
            id="userphoto"
            src={
              process.env.REACT_APP_API_URL + `file/download?key=${imageKey}`
            }
            height={32}
            unloader={<UserButton />}
          />
          {isOpen && (
            <div
              id="usermenu"
              className={`${styles.dropdown_menu} ${
                isOpen ? styles.dropdown_menu_open : ""
              }`}
            >
              <div className={styles["profile-info"]}>
                <div className={styles["profile-photo-container"]}>
                  <Img
                    className={styles["profile-photo"]}
                    src={[
                      process.env.REACT_APP_API_URL +
                        `file/download?key=${imageKey}`,
                      "/dummy-profile.jpg",
                    ]}
                    height={60}
                  />
                </div>
                <div className={styles.details}>
                  {name && <p className={styles["name-detail"]}>{name}</p>}
                  <p className={styles["position-detail"]}>{position}</p>
                  <p className={styles["email-detail"]}>{email}</p>
                </div>
              </div>
              <div className={styles.roles}>
                <p className={styles["my-roles"]}>My roles:</p>
                <p className={styles["role-list"]}>{roles.join(", ")}</p>
              </div>
              <Link to="/user/profile" onClick={toggleDropdown}>
                <Hi2Icons.HiOutlineCog8Tooth className={styles.icon} />
                <p className={styles.menuitem}>Settings</p>
              </Link>
              <Form action="/logout" method="post">
                <button className={styles["logout-button"]} type="submit">
                  <Hi2Icons.HiOutlinePower className={styles.icon} />
                  Sign out
                </button>
              </Form>
            </div>
          )}
        </div>
      </div>
    </IconContext.Provider>
  );
}

export default Dropdown;

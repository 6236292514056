import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./SharedContactsPage.module.css";
import Table from "../../components/UI/Table/Table";
import Header from "../../components/UI/Table/Header";
import Pagination from "../../components/UI/Pagination/Pagination";
import Card from "../../components/UI/Card/Card";
import { Link } from "react-router-dom";
import SearchBar from "../../components/UI/SearchBar/SearchBar";
import ErrorPage from "../../components/ErrorPage";
import { useDataFetching } from "../../hooks/use-datafetching";
import * as Hi2Icons from "react-icons/hi2";
import Button from "../UI/Button/Button";
import ModalContext from "../context/modal-context";
import ContactDetailPage from "../AVA/Intranet/Contacts/ContactDetailPage";
import { getAuthToken } from "../util/auth";
import { fetchDataRequest } from "../util/fetchDataRequest";
import SingleSelectNew from "../UI/Input/SingleSelectNew";
import VCard from "vcard-creator";
import Tooltip from "../UI/Tooltip/Tooltip";

function SharedContactsPage() {
  const token = getAuthToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "content-disposition": `attachment; filename="contacts.csv"`,
  };

  const URLsuffix = "intranet/contacts";
  const modalCtx = useContext(ModalContext);
  const [contactId, setContactId] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [kind, setKind] = useState("member");

  //* Fetch with sort and search params

  const [currentPage, setCurrentPage] = useState(1);
  const [sortParam, setSortParam] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [kindParam, setKindParam] = useState(`&kind=member`);

  const { error, pageData, getTableData } = useDataFetching(
    currentPage,
    URLsuffix,
    sortParam,
    searchParam,
    kindParam
  );

  //* Search

  const searchRef = useRef(null);

  const handleSearch = (event) => {
    event.preventDefault();
    const searchValue = searchRef.current.value;
    setSearchParam(`&search=${searchValue}`);
  };

  const clearSearchHandler = () => {
    setSearchParam("");
  };

  useEffect(() => {
    setCurrentPage(1);
    getTableData();
  }, [searchParam]);

  //* Sorting

  const sortContacts = (accessor, order) => {
    setSortParam(`&sort=${accessor}&order=${order}`);
  };

  useEffect(() => {
    getTableData();
  }, [sortParam, kindParam]);

  //* Handlers

  const detailsHandler = (contactId, parnterId) => {
    setContactId(contactId);
    setPartnerId(parnterId);

    modalCtx.showModal();
  };

  const handleSingleSelectChange = (selectedOption) => {
    setKindParam(`&kind=${selectedOption.value}`);
    setCsvKind(`?kind=${selectedOption.value}`);
    setKind(selectedOption.value);
  };

  //* Download CSV

  const [csvKind, setCsvKind] = useState("?kind=member");

  const downloadCSV = () => {
    const requestURL =
      process.env.REACT_APP_API_URL + `intranet/contacts/csv` + csvKind;
    fetchDataRequest(requestURL, "GET", headers, undefined)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = `${kind}_contacts.csv`;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  //* vCard

  const vCardHandler = (
    name,
    partnerName,
    position,
    email,
    phone,
    linkedIn,
    web
  ) => {
    const myVCard = new VCard();

    myVCard.addName(name);
    if (partnerName) {
      myVCard.addCompany(partnerName);
    }
    if (position) {
      myVCard.addJobtitle(position);
    }
    if (email) {
      myVCard.addEmail(email);
    }
    if (phone) {
      myVCard.addPhoneNumber(phone, "PREF;WORK");
    }
    if (linkedIn) {
      myVCard.addSocial(linkedIn, "LinkedIn");
    }
    if (web) {
      myVCard.addURL(web);
    }

    const vCardContent = myVCard.toString();

    console.log(vCardContent);

    const blob = new Blob([vCardContent], { type: "text/vcard" });
    const fileName = name.replace(/\s+/g, "_");
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName}.vcf`;
    link.textContent = "Download vCard";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  //* Table header
  const columns = [
    {
      Header: (
        <Header sortHandler={sortContacts} title="Company" id="partner.name" />
      ),
      accessor: "partnerName",
      width: "33%",
      Cell: ({ value, row }) => (
        <Link
          className={styles.name}
          onClick={() =>
            detailsHandler(row.original.id, row.original.partnerId)
          }
        >
          {value}
        </Link>
      ),
    },
    {
      Header: <Header sortHandler={sortContacts} title="Name" id="name" />,
      accessor: "name",
      width: "33%",
      Cell: ({ value, row }) => (
        <Link
          className={styles.name}
          onClick={() =>
            detailsHandler(row.original.id, row.original.partnerId)
          }
        >
          {value}
        </Link>
      ),
    },
    {
      Header: (
        <Header sortHandler={sortContacts} title="Position" id="position" />
      ),
      accessor: "position",
      width: "33%",
      Cell: ({ value, row }) => (
        <Link
          className={styles.name}
          onClick={() =>
            detailsHandler(row.original.id, row.original.partnerId)
          }
        >
          {value}
        </Link>
      ),
    },

    {
      Header: "Info",
      accessor: "id",
      id: "info",
      width: "50px",
      Cell: ({ row }) => (
        <>
          <Tooltip text="Open details">
            <Hi2Icons.HiOutlineInformationCircle
              className={styles["details-icon"]}
              onClick={() =>
                detailsHandler(row.original.id, row.original.partnerId)
              }
            />
          </Tooltip>
          <Tooltip text="Download vCard">
            <Hi2Icons.HiOutlineIdentification
              className={styles["details-icon"]}
              onClick={() =>
                vCardHandler(
                  row.original.name,
                  row.original.partnerName,
                  row.original.position,
                  row.original.email,
                  row.original.phone,
                  row.original.linkedIn,
                  row.original.web
                )
              }
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const kindOptions = [
    { label: "Member", value: "member" },
    { label: "Sponsor", value: "sponsor" },
  ];

  const [title, setTitle] = useState({
    h1: `Member contacts`,
    p: "The contact list of the AV Alliance members",
  });

  useEffect(() => {
    if (kind === "member") {
      setTitle({
        h1: "Member contacts",
        p: "The contact list of the AV Alliance members",
      });
    }
    if (kind === "sponsor") {
      setTitle({
        h1: "Sponsor contacts",
        p: "The contact list of the AV Alliance sponsors",
      });
    }
  }, [kind]);

  return (
    <>
      {modalCtx.isModal && (
        <ContactDetailPage contactId={contactId} partnerId={partnerId} />
      )}
      <div className={styles.title}>
        <h1>{title.h1}</h1>
        <p>{title.p}</p>
      </div>
      <div className={styles.control}>
        <Button className={styles["csv-button"]} onClick={downloadCSV}>
          Download CSV
        </Button>
        <div className={styles["select-container"]}>
          <SingleSelectNew
            className={styles.partnerSelect}
            id="changeKind"
            name="changeKind"
            // label="Select partner kind"
            options={kindOptions}
            defaultValue={kindOptions[0]}
            // value={dataCtx.formData.channel}
            onSingleChange={handleSingleSelectChange}
          />
          <SearchBar
            className={styles.search}
            searchRef={searchRef}
            onSubmit={handleSearch}
            clearSearch={clearSearchHandler}
          />
        </div>
      </div>
      <Card className={styles.card}>
        {error ? (
          <ErrorPage
            error={error}
            title={error.title}
            message={error.message}
          />
        ) : (
          <>
            <Table
              columns={columns}
              data={pageData.rowData}
              isLoading={pageData.isLoading}
            />
            <Pagination
              totalRows={pageData.totalRecords}
              pageChangeHandler={setCurrentPage}
              rowsPerPage={20}
              currentPage={currentPage}
            />
          </>
        )}
      </Card>
    </>
  );
}

export default SharedContactsPage;

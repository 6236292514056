import { useEffect, useState } from "react";
import { formatRowData } from "../components/util/GETdata";
import { getSimpleData } from "../components/util/GETsimpledata";

export const useSimpleDataFetching = (URLsuffix) => {
  const [error, setError] = useState(null);
  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
  });

  const getSimpleTableData = async () => {
    try {
      const info = await getSimpleData(URLsuffix).catch((error) => {
        if (error.status === 400) {
          setError({
            title: "Bad request",
            message: "Something went wrong with the request.",
            status: 401,
          });
        } else if (error.status === 401) {
          setError({
            title: "Unauthorized",
            message:
              "Your identification token has expired, please log in and try again.",
            status: 401,
          });
        } else if (error.status === 404) {
          setError({
            title: "Not found",
            message: "The page you want to communicate with does not exist",
            status: 404,
          });
        } else if (error.status === 503) {
          setError({
            title: "Service unavailable!",
            message:
              "Sorry, the requested server is unavailable. Please contact your support and describe your issue.",
            status: 503,
          });
        } else {
          setError(error);
        }
      });
      const { items } = info;
      setPageData({
        isLoading: false,
        rowData: formatRowData(items),
      });
    } catch (error) {}
  };

  useEffect(() => {
    setPageData((prevState) => ({
      ...prevState,
      rowData: [],
      isLoading: true,
    }));
    getSimpleTableData();
  }, []);

  return { error, pageData, getSimpleTableData };
};

import { getAuthToken } from "./auth";
import { fetchRequest } from "./fetchRequest";

export const formatRowData = (rawData) =>
  rawData.map((info) => {
    const formattedData = {
      id: info.id,
      name: info.name,
      firstName: info.firstName,
      lastName: info.lastName,
      status: info.status,
      slogan: info.slogan,
      email: info.email,
      code: info.code,
      kind: info.kind,
      region: info.region,
      country: info.country,
      city: info.city,
      address: info.address,
      position: info.position,
      phone: info.phone,
      role: info.role,
      channel: info.channel,
      priority: info.priority,
      date: info.date,
      format: info.format,
      year: info.year,
      month: info.month,
      estimation: info.estimation,
      ext: info.ext,
      parentId: info.parentId,
      parentIds: info.parentIds,
      linkedIn: info.linkedIn,
      key: info.key,
      value: info.value,
      expiration: info.expiration,
      title: info.title,
    };

    if (info.partner) {
      formattedData.partnerName = info.partner.name;
      formattedData.partnerId = info.partner.id;
      formattedData.member = info.partner.name;
    }
    if (info.partners) {
      formattedData.partners = info.partners
        .map((partner) => partner.name)
        .join(", ");
    }

    return formattedData;
  });

export const getData = async (
  pageNo = 1,
  URLsuffix,
  sortParam,
  searchParam,
  kindParam
) => {
  const token = getAuthToken();
  const URL =
    process.env.REACT_APP_API_URL +
    URLsuffix +
    `?page=${pageNo}&size=20` +
    kindParam +
    sortParam +
    searchParam;
  const method = "GET";
  const headers = { Authorization: `Bearer ${token}` };
  try {
    const data = await fetchRequest(URL, method, headers, undefined);
    return data;
  } catch (error) {
    throw error;
  }
};

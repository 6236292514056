import RequestsPage from "../../../components/AVA/CRM/Requests/RequestsPage";
import { getAuthToken } from "../../../components/util/auth";
import { fetchRequest } from "../../../components/util/fetchRequest";

function Requests(props) {
  return <RequestsPage type={props.type} />;
}

export default Requests;

export async function AVAloader() {
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  const URL =
    process.env.REACT_APP_API_URL + `intranet/partners?page=1&size=200`;
  const response = await fetchRequest(URL, "GET", headers, undefined);
  const options = response.items.map((d) => ({
    value: d.id,
    label: d.name,
  }));
  return options;
}

export async function memberLoader({ params }) {
  const memberId = params.partnerId;
  const token = getAuthToken();
  const headers = { Authorization: `Bearer ${token}` };

  const URL = process.env.REACT_APP_API_URL + `crm/${memberId}/leads`;
  const response = await fetchRequest(URL, "GET", headers, undefined);
  const options = response.items.map((d) => ({
    value: d.id,
    label: d.name,
  }));
  return options;
}

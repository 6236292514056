import * as Hi2Icons from "react-icons/hi2";

export const AVASidebarData = [
  {
    title: "Intranet",
    path: "#",
    icon: <Hi2Icons.HiOutlineShare />,
    iconClosed: <Hi2Icons.HiOutlineChevronRight className="subnavArrow" />,
    iconOpened: <Hi2Icons.HiOutlineChevronDown className="subnavArrow" />,

    subNav: [
      {
        title: "Partners",
        path: "/av-alliance/intranet/partners",
        icon: <Hi2Icons.HiOutlineBriefcase />,
      },
      {
        title: "Services",
        path: "/av-alliance/intranet/services",
        icon: <Hi2Icons.HiOutlineCog8Tooth />,
      },
      {
        title: "Currencies",
        path: "/av-alliance/intranet/currencies",
        icon: <Hi2Icons.HiOutlineCurrencyEuro />,
      },
      {
        title: "Documents",
        path: "/av-alliance/intranet/documents",
        icon: <Hi2Icons.HiOutlineFolderOpen />,
      },
      {
        title: "Job applicants",
        path: "/av-alliance/intranet/job-applicants",
        icon: <Hi2Icons.HiOutlineIdentification />,
      },
    ],
  },
  {
    title: "CRM",
    path: "#",
    icon: <Hi2Icons.HiOutlineUserGroup />,
    iconClosed: <Hi2Icons.HiOutlineChevronRight className="subnavArrow" />,
    iconOpened: <Hi2Icons.HiOutlineChevronDown className="subnavArrow" />,

    subNav: [
      // {
      //   title: "Reports",
      //   path: "/crm/reports",
      //   icon: <Hi2Icons.HiOutlineDocumentArrowUp />,
      // },
      {
        title: "Requests",
        path: "/crm/requests",
        icon: <Hi2Icons.HiOutlineDocumentArrowDown />,
      },
      {
        title: "Leads",
        path: "/crm/leads",
        icon: <Hi2Icons.HiOutlineFunnel />,
      },
      {
        title: "Events",
        path: "/crm/events",
        icon: <Hi2Icons.HiOutlineSparkles />,
      },
    ],
  },
  {
    title: "User",
    path: "#",
    icon: <Hi2Icons.HiOutlineUser />,
    iconClosed: <Hi2Icons.HiOutlineChevronRight className="subnavArrow" />,
    iconOpened: <Hi2Icons.HiOutlineChevronDown className="subnavArrow" />,

    subNav: [
      {
        title: "Invitation",
        path: "/av-alliance/users",
        icon: <Hi2Icons.HiOutlineUserPlus />,
      },
      {
        title: "Accounts",
        path: "/av-alliance/accounts",
        icon: <Hi2Icons.HiOutlineUsers />,
      },
    ],
  },
  {
    title: "Push notifications",
    path: "/pushnotifications",
    icon: <Hi2Icons.HiOutlineBellAlert />,
  },
  // {
  //   title: "Calendar",
  //   path: "/calendar",
  //   icon: <Hi2Icons.HiOutlineCalendarDays />,
  // },
];
